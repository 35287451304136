import { MultiplayerNomination } from '@hf/shared-common';

import { EAppRouterUrl } from '@app/AppRouter';

export interface IGameList {
  name: string;
  page: EAppRouterUrl;
  disabled: boolean;
  soon: boolean;
}

export interface IMatchBetList {
  value: number;
  disabled: boolean;
  hidden: boolean;
}
export enum ELastCoefficient {
  very_low = 'very_low',
  low = 'low',
  medium_low = 'medium_low',
  medium_high = 'medium_high',
  high = 'high',
  very_high = 'very_high',
}
export interface ILastCoefficient {
  value: number;
  type: ELastCoefficient;
}

export interface IMatchLifeTableItem {
  userId: number;
  place: number;
  userName: string;
  score: number;
  placeIncreased: boolean;
}
export type IMatchLifeTable<T = IMatchLifeTableItem[]> = Record<MultiplayerNomination, T>;
export type IFinalLeaderBoardItem = {
  score: number;
  position: number;
  prize: string;
  userName: string;
  userId: number;
};
export type IFinalLeaderBoard<T = IFinalLeaderBoardItem[]> = Record<MultiplayerNomination, T>;
