import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PRIZE_POOL_PERCENTAGE, QUICK_MATCH_MAX_PLAYERS } from '@hf/shared-common';

import { CoinIcon } from '@shared/assets';
import { useCountdown } from '@shared/hooks/utils/useCountdown';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  EMatchButtons,
  EMatchModals,
  selectCountUsersWaitingRoom,
  selectDisabledMatchButton,
  selectLoadingMatchButton,
  selectMatchBet,
  selectViewMatchModal,
  selectWaitTimeRoom,
  setDisabledMatchButtons,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button } from '@shared/ui';
import { NBSP, sleep } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';

interface IProps {
  confirm: () => void;
  disconnect: () => void;
}
export const FastMatchWaitingRoomModal = ({ confirm, disconnect }: IProps) => {
  const dispatch = useAppDispatch();
  const { setTargetDate, timeLeft } = useCountdown(async () => {
    await sleep(2000);
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.disconnect, value: false }));
  });
  const { t } = useTranslation();
  const { getBalanceFormat } = useUtils();
  const isOpen = useAppSelector((state) => selectViewMatchModal(state, EMatchModals.waitingRoom));
  const waitTimeRoom = useAppSelector(selectWaitTimeRoom);
  const isLoadingJoinRoom = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.joinRoom));
  const isDisabledJoinRoom = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.joinRoom));
  const isDisabledDisconnect = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.disconnect));
  const bet = useAppSelector(selectMatchBet);

  const countUsers = useAppSelector(selectCountUsersWaitingRoom);

  const confirmHandler = () => {
    if (isLoadingJoinRoom || isDisabledJoinRoom) return;
    confirm();
  };
  const disconnectHandler = () => {
    if (isDisabledDisconnect || isDisabledDisconnect) return;
    disconnect();
  };
  useEffect(() => {
    isOpen && waitTimeRoom && setTargetDate(new Date().getTime() + waitTimeRoom);
  }, [isOpen, waitTimeRoom]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t('multiplayer:modal.matchWaiting.title')}
        isButtonClose={false}
        className="flex flex-col gap-[10px]"
      >
        <span className="flex justify-center font-bold">
          {t('global:prizePool')}
          {NBSP}
          {getBalanceFormat(bet * QUICK_MATCH_MAX_PLAYERS * PRIZE_POOL_PERCENTAGE)}
          {NBSP}
          <CoinIcon width={18} height={20} />
        </span>
        <span className="flex justify-center font-bold">
          {t('global:yourBet')}
          {NBSP}
          {getBalanceFormat(bet)}
          {NBSP}
          <CoinIcon width={18} height={20} />
        </span>

        <span className="text-center">
          {t('global:playersReady')} {countUsers}/{QUICK_MATCH_MAX_PLAYERS}
        </span>
        <Button
          size="lg"
          loading={isLoadingJoinRoom}
          disabled={isDisabledJoinRoom}
          className="!text-[13px]"
          onClick={confirmHandler}
        >
          {isDisabledJoinRoom
            ? `${t('global:waitingPlayers')} ${timeLeft ? `- ${timeLeft}` : ''} `
            : `${t('global:joinRoom')}  ${timeLeft ? `- ${timeLeft}` : ''}`}
        </Button>
        <Button size="sm" theme="red" disabled={isDisabledDisconnect} onClick={disconnectHandler}>
          {t('global:disconnect')}
        </Button>
      </Modal>
    </>
  );
};
