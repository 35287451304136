import { animated } from 'react-spring';

import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';

interface Props {
  className?: string;
  fishGone?: boolean;
}
export const ResultModalRays = ({ fishGone, className }: Props) => {
  const { useAnimRotate } = useAnimateSpring();
  const fillGradient = fishGone ? '#FFD4AB' : '#CCBFFF';
  const animate = useAnimRotate(0, 360, { duration: 10000, loop: true });

  return (
    <animated.svg
      style={animate}
      width="100%"
      height="515"
      viewBox="0 0 480 515"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ?? ''}`}
    >
      <g opacity="0.16">
        <path
          d="M433.095 96.6698L234.931 264.47L356.012 34.5475L433.095 96.6698Z"
          fill="url(#paint0_linear_185_32592)"
        />
        <path
          d="M92.3806 50.4893L237.088 265.225L175.495 13.5475L92.3806 50.4893Z"
          fill="url(#paint1_linear_185_32592)"
        />
        <path
          d="M376.311 478.461L232.162 263.527L294.809 514.786L376.311 478.461Z"
          fill="url(#paint2_linear_185_32592)"
        />
        <path
          d="M467.321 373.577L235.957 261.606L431.766 428.246L467.321 373.577Z"
          fill="url(#paint3_linear_185_32592)"
        />
        <path d="M103 474.858L238.725 254.728L183.618 507.549L103 474.858Z" fill="url(#paint4_linear_185_32592)" />
        <path
          d="M0.000231896 320.449L249.433 264.81L9.65674 353.306L0.000231896 320.449Z"
          fill="url(#paint5_linear_185_32592)"
        />
        <path
          d="M489.494 259.521L233.948 262.473L487.617 293.717L489.494 259.521Z"
          fill="url(#paint6_linear_185_32592)"
        />
        <path
          d="M283.52 -2.7303e-05L242.395 252.249L317.35 7.88293L283.52 -2.7303e-05Z"
          fill="url(#paint7_linear_185_32592)"
        />
        <path
          d="M9.00016 178.97L247.001 272.116L23.8071 147.547L9.00016 178.97Z"
          fill="url(#paint8_linear_185_32592)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_185_32592"
          x1="394.553"
          y1="65.6086"
          x2="234.541"
          y2="264.156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_185_32592"
          x1="133.938"
          y1="32.0184"
          x2="237.508"
          y2="265.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_185_32592"
          x1="335.56"
          y1="496.623"
          x2="231.751"
          y2="263.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_185_32592"
          x1="449.543"
          y1="400.911"
          x2="235.778"
          y2="261.882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_185_32592"
          x1="143.309"
          y1="491.204"
          x2="239.133"
          y2="254.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_185_32592"
          x1="4.82849"
          y1="336.877"
          x2="249.482"
          y2="264.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_185_32592"
          x1="488.556"
          y1="276.619"
          x2="233.939"
          y2="262.646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_185_32592"
          x1="300.435"
          y1="3.94145"
          x2="242.566"
          y2="252.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_185_32592"
          x1="16.4036"
          y1="163.259"
          x2="247.076"
          y2="271.957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradient} stopOpacity="0" />
          <stop offset="0.595" stopColor={fillGradient} />
          <stop offset="1" stopColor={fillGradient} stopOpacity="0" />
        </linearGradient>
      </defs>
    </animated.svg>
  );
};
