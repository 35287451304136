import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { MultiplayerNomination } from '@hf/shared-common';

import { GamesConfig } from '@pages/games/configs/Games.config';
import { WinnersMatchLeaderBoardDetail } from '@pages/games/multiplayer/widgets/WinnersMatchLeaderBoard/WinnersMatchLeaderBoardDetail';
import { TimeIcon } from '@shared/assets';
import { IFinalLeaderBoardItem } from '@shared/types/games.type';
import { NBSP } from '@shared/utils/helpers.utils';

interface Props {
  posts: IFinalLeaderBoardItem[];
  nomination: MultiplayerNomination;
  className?: string;
  headerText?: string;
  showHeaderText?: boolean;
}

export const WinnersMatchLeaderBoard = ({ posts, className, headerText, nomination, showHeaderText = true }: Props) => {
  const { t } = useTranslation();
  const MemoizedDetail = memo(WinnersMatchLeaderBoardDetail);
  const { getIconNominationWinnersLeaderBoard } = GamesConfig();
  const MemoizedItemContent = memo(
    ({ index, item }: { index: number; item: IFinalLeaderBoardItem }) => (
      <div className="h-[45px] flex items-center">
        <MemoizedDetail
          position={item.position}
          userName={item.userName}
          score={item.score}
          prize={item.prize}
          scoreIcon={getIconNominationWinnersLeaderBoard(nomination)}
        />
      </div>
    ),
    (prevProps, nextProps) => {
      return prevProps.item === nextProps.item;
    },
  );

  return (
    <div className={`h-full w-full relative pb-[14px] ${className ?? ''}`}>
      {showHeaderText && (
        <div className="flex items-center justify-end px-[15px] h-[18px] text-[10px]">
          {headerText ? (
            headerText
          ) : (
            <>
              {t('leaderboard:updated.time')}
              {NBSP}
              <TimeIcon className="w-[12px] h-[12px]" />
            </>
          )}
        </div>
      )}

      {posts.length === 0 && (
        <div className="absolute full-absolute flex justify-center items-center">{t('global:noData')}</div>
      )}
      <Virtuoso
        className="z-1"
        data={posts}
        increaseViewportBy={45 * 10}
        itemContent={(index, item) => <MemoizedItemContent index={index} item={item} />}
        overscan={200}
        style={{
          WebkitOverflowScrolling: 'touch',
          height: '100%',
          width: '100%'
        }}
        defaultItemHeight={45}
      />
    </div>
  );
};
