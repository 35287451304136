import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPanel } from 'src/layouts/GameLayout/widgets/GameHeader/ui/InfoPanel';
import { Logo } from 'src/layouts/GameLayout/widgets/GameHeader/ui/Logo';

import { CoinIcon, LeaderboardPageIcon } from '@shared/assets';
import SettingsIcon from '@shared/assets/svg/icons/common/SettingsIcon.svg?react';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  ECommonModals,
  selectDisabledSettings,
  selectViewCommonModal,
  setViewCommonModals,
} from '@shared/store/slice/CommonSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { NBSP } from '@shared/utils/helpers.utils';

interface Props {
  className?: string;
  win: boolean;
  fishGone: boolean;
  place: number;
  rewardAmount: number;
}

export const GameHeader = ({ className, win, fishGone, place, rewardAmount }: Props) => {
  const userProfile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const isOpenSettings = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.settings));
  const disabledSettings = useAppSelector(selectDisabledSettings);

  const { samplePlay } = useAudio();
  const { getUserName, vibration, getBalanceFormat } = useUtils();
  const { t } = useTranslation();
  const getThemeInfoPanel = () => {
    if (win) {
      return 'win';
    }
    if (fishGone) {
      return 'fishGone';
    }
    return 'default';
  };
  const openSettings = useCallback(() => {
    if (disabledSettings) return;

    vibration('impactOccurred', 'medium');
    samplePlay(ESamples.buttonClick);
    dispatch(setViewCommonModals({ modal: ECommonModals.settings, value: !isOpenSettings }));
  }, [isOpenSettings, disabledSettings]);

  return (
    <>
      <span id="animateBalance" className="text-primary-purpleLight font-bold text-[40px] absolute opacity-0 z-50">
        {rewardAmount}
      </span>
      <div className={`w-full flex items-center gap-x-[20px] text-[12px] ${className ?? ''}`}>
        <Logo className="absolute w-[84px] h-[76px] z-[1]" />
        <div className="flex flex-1 flex-col gap-y-[8px] items-start ml-[76px]">
          <InfoPanel theme={getThemeInfoPanel()} className="justify-start w-full">
            <span className="text-white font-bold">@{getUserName}</span>
          </InfoPanel>
          <InfoPanel theme={getThemeInfoPanel()} className="justify-start w-5/6" position="left">
            <div className="text-white font-bold flex items-center">
              <LeaderboardPageIcon className="w-[14px] h-auto" /> {NBSP} {t('fishing:header.place')} {place}
            </div>
          </InfoPanel>
        </div>
        <div className="flex flex-1 flex-col gap-y-[8px] items-end  place-self-start">
          <InfoPanel
            theme={getThemeInfoPanel()}
            position="right"
            className="justify-end w-full pla self-start"
            icon={<CoinIcon className="w-full h-full" />}
          >
            <span
              id="targetAnimateBalance"
              className={`${fishGone || win ? 'text-white' : 'text-primary-purpleLight'} font-bold`}
            >
              {getBalanceFormat(userProfile?.balance ?? 0)}
            </span>
          </InfoPanel>
          <SettingsIcon
            className={`relative ${disabledSettings ? 'opacity-30 pointer-events-none' : ''}`}
            width={25}
            height={25}
            onClick={openSettings}
          />
        </div>
      </div>
    </>
  );
};
