import gsap from 'gsap';
import { Application, Container, ICanvas } from 'pixi.js';
import { ScreenId } from 'src/pixi/ProducerCore';
import UiBackground from 'src/pixi/ui/UiBackground';

export default class BackgroundScreen extends Container {
  private readonly app: Application<ICanvas>;
  private readonly _id: ScreenId = ScreenId.Background;
  private _background: UiBackground | null = null;
  private _isDestroyed: boolean = false;

  constructor(app: Application<ICanvas>) {
    super();
    this.app = app;
    this.name = 'Background';

    try {
      this.initialize();
    } catch (error) {
      console.error('Failed to initialize background screen:', error);
    }
  }

  private initialize(): void {
    if (this._isDestroyed) return;

    try {
      this._background = new UiBackground();

      if (this._background?.view) {
        this.addChild(this._background.view);
      }

      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize background:', error);
    }
  }

  show(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this, { alpha: 1, duration: 0.2 });
      return tl;
    } catch (error) {
      console.error('Failed to show background:', error);
      return gsap.timeline();
    }
  }

  hidden(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this, { alpha: 0, duration: 0.2 });
      return tl;
    } catch (error) {
      console.error('Failed to hide background:', error);
      return gsap.timeline();
    }
  }

  adaptive(): void {
    if (this._isDestroyed) return;

    try {
      // Add any adaptive logic here if needed in the future
    } catch (error) {
      console.error('Failed to adapt background:', error);
    }
  }

  destroy(): void {
    if (this._isDestroyed) return;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      gsap.killTweensOf(this);

      // Destroy background
      if (this._background) {
        this._background.destroy();
        this._background = null;
      }

      // Call parent destroy
      super.destroy({ children: true });
    } catch (error) {
      console.error('Failed to destroy background:', error);
    }
  }

  // Getters
  get id(): ScreenId {
    return this._id;
  }

  get background(): UiBackground | null {
    return this._background;
  }
}
