import { useTranslation } from 'react-i18next';

import { IReferralEarningItem } from '@hf/shared-common';

import { referralAPI } from '@shared/api/referral';
import { CoinIcon, UsersIcon } from '@shared/assets';
import { Wrapper } from '@shared/ui';

interface Props {
  className?: string;
}
export const FriendList = ({ className }: Props) => {
  const { t } = useTranslation();
  const { data: refInfo } = referralAPI.useReferralInfoQuery();

  return (
    <Wrapper theme="light" className={`h-full text-[14px] flex flex-col gap-2  text-center ${className ?? ''}`}>
      <div className="p-[8px]">
        <h3 className="text-blue-light font-bold text-[13px] text-left">{t('friends:list.title')}</h3>
      </div>
      <div className=" overflow-hidden overflow-y-auto">
        <div className="flex flex-col gap-y-[7px]">
          {refInfo?.list.map(({ gameUsername, amount, subRefCount }: IReferralEarningItem, i: number) => (
            <Wrapper key={`friend_list_${gameUsername.toLowerCase()}`}>
              <div className="flex items-center gap-[10px]">
                <div className="w-10 h-10 bg-primary-purpleDark rounded-full text-xl flex items-center justify-center font-bold">
                  {gameUsername[0].toUpperCase()}
                </div>
                <div className="flex flex-grow items-center justify-between">
                  <div>
                    <div className="font-bold text-[13px]">@{gameUsername}</div>
                    <div className="flex items-center">
                      <UsersIcon className="w-4 h-4 mr-1" />
                      <div className="text-[13px]">+{subRefCount}</div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="font-bold text-[14px] mr-1 ">{Number(amount).toFixed(2)}</div>
                    <CoinIcon className="w-5" />
                  </div>
                </div>
              </div>
            </Wrapper>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
