import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';

type SwitchSize = 'sm' | 'lg';
type SwitchTheme = 'purple';

interface Props {
  className?: string;
  theme?: SwitchTheme;
  size?: SwitchSize;
  disabled?: boolean;
  sound?: {
    samples: ESamples;
    volume?: number;
    disabled?: boolean;
  };
  checked: boolean;
  checkedDiv: React.ReactNode;
  unCheckedDiv: React.ReactNode;
  onChecked: (nextChecked: boolean) => void;
  onClick?: () => void;
}

export const Switch = ({
  className,
  theme = 'purple',
  size,
  disabled,
  sound = { samples: ESamples.buttonClick },
  checked,
  checkedDiv,
  unCheckedDiv,
  onChecked,
  onClick,
}: Props) => {
  const { samplePlay } = useAudio();
  const { vibration } = useUtils();

  const click = () => {
    if (disabled) return;
    vibration('selectionChanged');
    sound && !sound?.disabled && sound?.samples && samplePlay(sound.samples, sound?.volume ?? undefined);
    onChecked(!checked);
    onClick && onClick();
  };

  const getThemeStyle = (): { themeStyle: string; textStyle: string; checkedStyle: string; unCheckedStyle: string } => {
    switch (theme) {
      case 'purple':
        return {
          themeStyle: `bg-black/40`,
          textStyle: `text-white`,
          checkedStyle: 'bg-primary-purpleDark ',
          unCheckedStyle: 'bg-primary-grayBlue',
        };
      default:
        return {
          themeStyle: '',
          textStyle: '',
          checkedStyle: '',
          unCheckedStyle: '',
        };
    }
  };

  const getSizeStyle = (): { sizeStyle: string } => {
    switch (size) {
      case 'lg':
        return { sizeStyle: 'h-[34px] text-[15px]' };
      case 'sm':
        return { sizeStyle: 'h-[28px] text-[12px]' };
      default:
        return { sizeStyle: 'h-[22px] text-[12px]' };
    }
  };

  const { themeStyle, textStyle, checkedStyle, unCheckedStyle } = getThemeStyle();
  const { sizeStyle } = getSizeStyle();

  return (
    <>
      <div
        className={`w-full rounded-10 flex items-center  ${disabled ? 'pointer-events-none opacity-30' : ''}  ${className ?? ''} ${sizeStyle} ${themeStyle} ${textStyle}`}
        onClick={click}
      >
        <div
          className={`w-8/12 h-full rounded-10  flex  justify-center items-center transition-all font-bold ${checked ? `${checkedStyle} translate-x-1/2` : unCheckedStyle}`}
        >
          {checked ? checkedDiv : unCheckedDiv}
        </div>
      </div>
    </>
  );
};
