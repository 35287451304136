import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { ILeaderboardUsersItem } from '@hf/shared-common';

import { LeaderBoardListDetail } from '@pages/leaderBoard/widgets/LeaderBoardList/LeaderBoardListDetail';
import { TimeIcon } from '@shared/assets';
import { Loader } from '@shared/ui/Loader/Loader';
import { NBSP } from '@shared/utils/helpers.utils';

interface Props {
  posts: ILeaderboardUsersItem[];
  hasMore: boolean;
  isLoading: boolean;
  className?: string;
  headerText?: string;
  showHeaderText?: boolean;
  loadMore: () => void;
}

export const LeaderBoardList = ({
  posts,
  hasMore,
  isLoading,
  className,
  headerText,
  showHeaderText = true,
  loadMore,
}: Props) => {
  const { t } = useTranslation();
  const MemoizedLeaderBoardListDetail = memo(LeaderBoardListDetail);

  const MemoizedItemContent = memo(
    ({ index, item }: { index: number; item: ILeaderboardUsersItem }) => (
      <div className="h-[35px] flex items-center">
        <MemoizedLeaderBoardListDetail
          position={item.position}
          userName={item.gameUsername}
          balance={item.balance}
          prize={[1, 2, 3].includes(item.row) ? item.row : undefined}
        />
      </div>
    ),
    (prevProps, nextProps) => {
      return prevProps.item === nextProps.item;
    },
  );

  return (
    <div className={`h-full w-full relative pb-[14px] ${className ?? ''}`}>
      {showHeaderText && (
        <div className="flex items-center justify-end px-[15px] h-[18px] text-[10px]">
          {headerText ? (
            headerText
          ) : (
            <>
              {t('leaderboard:updated.time')}
              {NBSP}
              <TimeIcon className="w-[12px] h-[12px]" />
            </>
          )}
        </div>
      )}
      {posts.length === 0 && isLoading && <Loader full={true} />}
      {posts.length === 0 && !isLoading && (
        <div className="absolute full-absolute flex justify-center items-center">{t('global:noData')}</div>
      )}
      <Virtuoso
        className="z-1"
        data={posts}
        endReached={loadMore}
        increaseViewportBy={35 * 10}
        itemContent={(index, item) => <MemoizedItemContent index={index} item={item} />}
        overscan={200}
        style={{
          WebkitOverflowScrolling: 'touch',
          height: '100%',
          width: '100%'
        }}
        defaultItemHeight={35}
        components={{
          Footer: () =>
            isLoading && hasMore ? (
              <div className="h-[35px] relative w-full">
                <Loader full={true} />
              </div>
            ) : null,
        }}
      />
    </div>
  );
};
