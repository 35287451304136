import { useTranslation } from 'react-i18next';

import { useCountdownToUTC } from '@shared/hooks/utils/useCountdownToUTC';
import { selectFishingInfo } from '@shared/store/slice/FishingSlice';
import { useAppSelector } from '@shared/store/store';
import { Wrapper } from '@shared/ui';

interface Props {
  className?: string;
  isStop: boolean;
}
export const TriesSummary = ({ isStop, className }: Props) => {
  const { t } = useTranslation();
  const fishingInfo = useAppSelector(selectFishingInfo);
  const time = useCountdownToUTC(isStop);

  return (
    <Wrapper theme="darkBlue" className={`flex flex-col gap-y-[4px] ${className ?? ''}`}>
      <Wrapper theme="darkBlue" paddingSize="sm" className="flex items-center justify-between px-3">
        <span className="font-bold">{t('fishing:triesSummary.freeTries')}</span>
        <span id="animateBaits">{fishingInfo?.baits}</span>
      </Wrapper>
      <Wrapper theme="darkBlue" paddingSize="sm" className="flex items-center justify-between px-3">
        <span className="font-bold">{t('fishing:triesSummary.nextTime')}</span>
        <span>{time}</span>
      </Wrapper>
      <Wrapper theme="darkBlue" paddingSize="sm" className="flex items-center justify-between px-3">
        <span className="font-bold">{t('fishing:triesSummary.bonus')}</span>
        <span id="animateBonus">{fishingInfo?.bonusBaits}</span>
      </Wrapper>
    </Wrapper>
  );
};
