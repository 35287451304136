import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  EVibrationFunction,
  EVibrationImpactOccurred,
  EVibrationNotificationOccurred,
} from '@shared/types/global.types';
import { Loader } from '@shared/ui/Loader/Loader';

type ButtonTheme = 'purple' | 'gray' | 'red' | 'green';
type ButtonSize = 'sm' | 'lg';
type ButtonStyle = { themeStyle?: string; themeStyleAnim?: string; textStyle?: string };

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonTheme;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;
  shine?: boolean;
  sound?: {
    samples?: ESamples;
    volume?: number;
    disabled?: boolean;
  };
  vibrationOpt?:
    | { func: EVibrationFunction; style: EVibrationImpactOccurred | EVibrationNotificationOccurred }
    | undefined;
  onClick?: () => void;
}

export const Button = ({
  className,
  theme,
  size,
  children,
  loading,
  shine,
  disabled,
  sound = { samples: ESamples.buttonClick },
  vibrationOpt = { func: 'impactOccurred', style: 'medium' },
  onClick,
  ...props
}: Props) => {
  const { samplePlay } = useAudio();
  const { vibration } = useUtils();

  const getSizeStyle = (): { sizeStyle: string } => {
    switch (size) {
      case 'lg':
        return { sizeStyle: 'p-[.625rem] text-xl w-full' };
      case 'sm':
        return { sizeStyle: 'p-[.25rem] text-[12px]' };
      default:
        return { sizeStyle: 'p-[.375rem] text-[12px]' };
    }
  };

  const getThemeStyle = (): ButtonStyle => {
    switch (theme) {
      case 'gray':
        return {
          themeStyle: `bg-primary-grayBlue disabled:bg-sand-light/50 hover:bg-sand-dark`,
          textStyle: 'text-sand',
        };
      case 'red':
        return {
          themeStyle: `bg-secondary-redLight disabled:bg-opacity-50 hover:bg-sand-dark`,
          textStyle: 'text-sand',
        };
      case 'green':
        return {
          themeStyle: `bg-primary-darkGreen disabled:bg-opacity-50 hover:bg-sand-dark`,
          textStyle: 'text-sand',
        };
      default:
        return {
          themeStyle: `bg-primary-purpleDark disabled:bg-opacity-50 hover:bg-secondary-purpleDarkHover ${shine ? 'animate-[shineButton_2s_linear_infinite] bg-shineClaimButton bg-no-repeat ' : ''}`,
          textStyle: 'text-blue-light',
        };
    }
  };

  const { sizeStyle } = getSizeStyle();
  const { textStyle, themeStyle } = getThemeStyle();

  const click = () => {
    if (disabled) return;

    vibrationOpt && vibration(vibrationOpt.func, vibrationOpt.style);
    sound && !sound?.disabled && sound?.samples && samplePlay(sound.samples, sound?.volume ?? undefined);

    onClick && onClick();
  };

  return (
    <button
      className={`rounded-10 font-bold font-inter transition-colors flex justify-center items-center gap-x-2  ${className ?? ''} ${sizeStyle} ${textStyle} ${themeStyle}`}
      onClick={click}
      {...props}
      disabled={disabled}
    >
      {loading ? <Loader size={size} /> : <div>{children}</div>}
    </button>
  );
};
