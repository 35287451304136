import { MAX_CATCHES_PER_PLAYER } from '@hf/shared-common';

import {
  setDisabledFooter,
  setDisabledSettings,
  setGameButtonParams,
  setGameButtonResults,
  setShowFooter,
  setShowGameButton,
  setShowGameHeader,
} from '@shared/store/slice/CommonSlice';
import {
  EMatchButtons,
  EMatchModals,
  resetFinalLeaderBoard,
  resetLastCoefficients,
  resetLifeTable,
  setCountUsersGameRoom,
  setCountUsersWaitingRoom,
  setCurrentRound,
  setDisabledMatchButtons,
  setIsShowHeaderInfoTournament,
  setIsShowInfoMatch,
  setIsShowLobbyButtons,
  setMatchBet,
  setMatchId,
  setRemainingBaits,
  setViewMatchModals,
  setWaitTimeNextRound,
  setWaitTimeRoom,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch } from '@shared/store/store';
import { EGameButtonMode } from '@shared/types/global.types';
import { EKeyStorage } from '@shared/types/storage.types';
import { removeLocalStorage } from '@shared/utils/localStorage.storage';

export const useMatchActions = () => {
  const dispatch = useAppDispatch();

  const openFastMatchPage = () => {
    dispatch(setIsShowInfoMatch(true));
    dispatch(setShowGameHeader(true));
    dispatch(setIsShowLobbyButtons(true));
  };

  const closeFastMatchPage = () => {
    dispatch(setIsShowInfoMatch(false));
    dispatch(setShowGameHeader(false));
    dispatch(setShowGameButton(false));
    dispatch(setIsShowLobbyButtons(false));
    dispatch(setIsShowHeaderInfoTournament(false));
    dispatch(setShowFooter(true));
  };

  const closeWaitingRoomElements = () => {
    dispatch(setShowGameHeader(false));
    dispatch(setViewMatchModals({ modal: EMatchModals.waitingRoom, value: false }));
    dispatch(setShowFooter(false));
    dispatch(setIsShowInfoMatch(false));
    dispatch(setIsShowLobbyButtons(false));
  };

  const showFastMatchElements = () => {
    dispatch(setShowGameButton(true));
    dispatch(setIsShowHeaderInfoTournament(true));
  };

  const closeFastMatchElements = () => {
    dispatch(setShowGameButton(false));
    dispatch(setIsShowHeaderInfoTournament(false));
  };

  const resetWaitingRoom = () => {
    dispatch(setViewMatchModals({ modal: EMatchModals.waitingRoom, value: false }));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.disconnect, value: false }));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.joinRoom, value: false }));
    dispatch(setDisabledFooter(false));
    dispatch(setDisabledSettings(false));
    dispatch(setWaitTimeRoom(0));
    dispatch(setCountUsersWaitingRoom(0));
    dispatch(setMatchBet(0));
  };

  const resetGameRoom = (data: { textButton: string }) => {
    const { textButton } = data;
    dispatch(setMatchId(0));
    dispatch(setCountUsersGameRoom(0));
    dispatch(setWaitTimeNextRound(0));
    dispatch(setGameButtonResults({ show: false, catch: '', coins: '', fishes: '' }));
    dispatch(setGameButtonParams({ mode: EGameButtonMode.default, text: textButton, disabled: false, pulse: false }));
    dispatch(setCurrentRound(0));
    dispatch(setViewMatchModals({ modal: EMatchModals.confirmationRound, value: false }));
    dispatch(resetLastCoefficients());
    dispatch(setRemainingBaits(MAX_CATCHES_PER_PLAYER));
  };

  const disabledButtonsWaitingRoom = (value: boolean) => {
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.findLobby, value }));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.returnHome, value }));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.choosingBet, value }));
  };

  const clearLifeData = () => {
    removeLocalStorage(EKeyStorage.weightMatchLifePlace);
    removeLocalStorage(EKeyStorage.quantityMatchLifePlace);
    removeLocalStorage(EKeyStorage.sumWeightMatchLifePlace);
    dispatch(resetLifeTable());
    dispatch(resetFinalLeaderBoard());
  };

  return {
    openFastMatchPage,
    closeFastMatchPage,
    resetWaitingRoom,
    disabledButtonsWaitingRoom,
    showFastMatchElements,
    resetGameRoom,
    closeWaitingRoomElements,
    closeFastMatchElements,
    clearLifeData,
  };
};
