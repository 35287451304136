import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpringRef } from 'react-spring';

import { LeaderboardLeague } from '@hf/shared-common';

import { LeaderBoardConfig } from '@pages/leaderBoard/configs/LeaderBoard.config';
import { LeaderBoardHeaderPersonages } from '@pages/leaderBoard/widgets/LeaderBoardHeader/LeaderBoardHeaderPersonages';
import { CoinIcon, LeaderboardPageIcon } from '@shared/assets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { ECommonModals, selectViewCommonModal, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button, TextSlider, Wrapper } from '@shared/ui';
import { getNumberOfSlidesFromEnum, sleep } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';

export const LeaderBoardHistoryModal = () => {
  const { t } = useTranslation();
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const { useAnimOpacity } = useAnimateSpring();
  const dispatch = useAppDispatch();

  const { leagueSlides, leagueRadialGradients } = LeaderBoardConfig();
  const isOpen = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.leaderBoardHistory));
  const profile = useAppSelector(selectUserProfile);

  const handleContinue = () => {
    dispatch(setViewCommonModals({ modal: ECommonModals.leaderBoardHistory, value: false }));
  };

  const fadeAnimationRef = useSpringRef();
  const fadeAnimation = useAnimOpacity(0, 1, { animRef: fadeAnimationRef, duration: 300 });

  const animateStart = async () => {
    await sleep(200);
    fadeAnimationRef.start();
    await sleep(200);
    setIsOpenDetails(true);
  };

  useEffect(() => {
    isOpen && animateStart();
  }, [isOpen]);

  if (!profile?.leaderboardHistory) return null;

  const { league, winCoins, place } = profile.leaderboardHistory;

  return (
    <Modal isOpen={isOpen} isFullScreen={true} classNameOverlay="bg-primary-darkBlue">
      <animated.div style={fadeAnimation} className="w-full h-full flex flex-col justify-center">
        <div className={`fixed z-0 full-absolute  ${leagueRadialGradients[league]} opacity-30`}></div>
        <div className="w-full h-full flex flex-col gap-[10px] p-[20px] relative z-1 items-center">
          <LeaderBoardHeaderPersonages league={league} />
          <TextSlider
            options={leagueSlides}
            className="justify-center items-center"
            currentSlide={league}
            countSlides={getNumberOfSlidesFromEnum(LeaderboardLeague)}
            showControls={false}
          />

          <div className="flex-1 font-bold text-[25px] leading-[27px] max-w-[300px] flex  text-white text-center items-center">
            {t('common_modals:leaderBoard.history.title')}
          </div>
          <Wrapper className="flex flex-col justify-center items-center gap-[10px] ">
            <Wrapper
              className={`flex justify-between items-center px-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
              paddingSize="sm"
              theme="darkBlue"
            >
              <span className="font-bold text-[18px] leading-[20px]">
                {t('common_modals:leaderBoard.history.receivedCoins')}
              </span>
              <div className="w-1/2 flex justify-end gap-x-[10px]">
                <span className="text-[18px] font-bold text-primary-purpleLight">{winCoins}</span>
                <CoinIcon className="w-[20px] h-auto" />
              </div>
            </Wrapper>

            <Wrapper
              className={`flex justify-between items-center px-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
              paddingSize="sm"
              theme="darkBlue"
            >
              <span className="font-bold text-[18px] leading-[20px]">
                {t('common_modals:leaderBoard.history.place')}
              </span>
              <div className="w-1/2 flex justify-end gap-x-[10px]">
                <span className="text-[18px] font-bold text-white">{place}</span>
                <LeaderboardPageIcon className="w-[18px] h-auto" />
              </div>
            </Wrapper>
          </Wrapper>

          <Button size="lg" onClick={handleContinue}>
            {t('global:continue')}
          </Button>
        </div>
      </animated.div>
    </Modal>
  );
};
