/**
 * Записать запись в LocalStorage
 * */
export const setLocalStorage = (name: string, data: any = null, json = false) => {
  if (json) {
    data = JSON.stringify(data);
  }
  return localStorage.setItem(name, data);
};

/**
 *  Получить запись из LocalStorage
 * */
export const getLocalStorage = <T = string | null | number>(name: string, json = false): T => {
  let item = localStorage.getItem(name);
  if (item !== null && json) item = JSON.parse(item);

  return (item ?? null) as T;
};
/**
 * Удалить запись из LocalStorage по ключу
 * */
export const removeLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};
