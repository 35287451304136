import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { AmplitudeEventClient, IUserTonWallet } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { IApiError } from '@shared/api/api';
import { userApi } from '@shared/api/user';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { getLocalStorage, setLocalStorage } from '@shared/utils/localStorage.storage';
import { useTonAddress, useTonConnectModal, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';

interface IUseWallet {
  autoSendConnectServer?: boolean;
  onStatusChangeEnabled?: boolean;
  onReward?: (wallet: IUserTonWallet) => void;
}
interface IWallet {
  rawAddress: string;
  tonAddress: string;
  appName: string;
  publicKey: string;
  chain: string;
}
export const useWallet = ({ autoSendConnectServer = false, onStatusChangeEnabled = true, onReward }: IUseWallet) => {
  const user = window.Telegram?.WebApp?.initDataUnsafe?.user;
  const tgUserId = user?.id ?? 0;

  const { open: openTonWalletModal } = useTonConnectModal();
  const userProfile = useAppSelector(selectUserProfile);
  const [tonWalletConnect] = userApi.useTonWalletConnectMutation();
  const [TonConnectUIProvider] = useTonConnectUI();
  const [completedTask, setCompletedTask] = useState(true);
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const wallet = useTonWallet();

  const connectedWallet = () => {
    const currentWallet: Omit<IUserTonWallet, 'isActive'> = {
      rawAddress: rawAddress,
      tonAddress: userFriendlyAddress,
      appName: wallet?.device.appName || '',
      publicKey: wallet?.account.publicKey || '',
      chain: wallet?.account.chain || '',
    };

    tonWalletConnect(currentWallet)
      .unwrap()
      .then((data) => {
        setCompletedTask(TonConnectUIProvider.connected);
        amplitude.track(AmplitudeEventClient.WalletConnectSuccess, { wallet_name: data.appName });

        onReward && onReward(data);
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error connecting wallet: ${data?.message}`);
        if (data) toast.error(data.message);
        amplitude.track(AmplitudeEventClient.WalletConnectFailed, { error_type: data.message });
      });
  };

  const checkConnectCurrentUser = () => {
    const localTgUserId = getLocalStorage(EKeyStorage.currentTgUserId);
    const isInvalidUser = (localTgUserId ? +localTgUserId : 0) !== tgUserId;

    if (localTgUserId === null || isInvalidUser) {
      setLocalStorage(EKeyStorage.currentTgUserId, tgUserId);
      TonConnectUIProvider.connected && isInvalidUser && TonConnectUIProvider.disconnect();
    }
  };

  useEffect(() => {
    const completeTask = !!userProfile?.tonWallet;
    setCompletedTask(completeTask);

    if (autoSendConnectServer && wallet && !completeTask) connectedWallet();
  }, [wallet]);

  useEffect(() => {
    onStatusChangeEnabled &&
      TonConnectUIProvider.onStatusChange(
        () => { },
        (error) => {
          amplitude.track(AmplitudeEventClient.WalletConnectFailed, { error_type: error.message });
        },
      );
  }, []);

  return { completedTask, TonConnectUIProvider, openTonWalletModal, connectedWallet, checkConnectCurrentUser };
};
