import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { AmplitudeEventClient, ILeaderboardMe, ILeaderboardUsers, LeaderboardLeague } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { LeaderBoardConfig } from '@pages/leaderBoard/configs/LeaderBoard.config';
import { LeaderBoardBtn } from '@pages/leaderBoard/widgets/LeaderBoardBtn';
import { LeaderBoardHeader } from '@pages/leaderBoard/widgets/LeaderBoardHeader/LeaderBoardHeader';
import { LeaderBoardList } from '@pages/leaderBoard/widgets/LeaderBoardList/LeaderBoardList';
import { LeaderBoardListDetail } from '@pages/leaderBoard/widgets/LeaderBoardList/LeaderBoardListDetail';
import { IApiError } from '@shared/api/api';
import { leaderBoardApi } from '@shared/api/leaderBoard';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useLeaderBoard } from '@shared/hooks/useLeaderBoard';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  selectCurrentHasMore,
  selectCurrentLastOffset,
  selectCurrentLeaderBoard,
  selectCurrentLeague,
  selectCurrentList,
  selectCurrentOffset,
  selectCurrentType,
  selectInitStart,
  selectLeaderboardMe,
  setCurrentHasMore,
  setCurrentLastOffset,
  setCurrentLeague,
  setCurrentOffset,
  setLeaderBoardList,
  setLeaderboardInitStart,
  setLeaderboardMe,
} from '@shared/store/slice/LeaderBoardSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event, EGa4EventTiming } from '@shared/types/ga4.types';
import { Loader } from '@shared/ui/Loader/Loader';

export const LeaderBoardPage = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { ga4Event, ga4StartTiming, ga4EndTiming } = useGa4();
  const { initCacheTime, isInitCacheTime } = useLeaderBoard();
  const { getUserName } = useUtils();
  const { leagueRadialGradients } = LeaderBoardConfig();

  const leaderboardMe = useAppSelector(selectLeaderboardMe);
  const currentType = useAppSelector(selectCurrentType);
  const currentLeague = useAppSelector(selectCurrentLeague);
  const currentOffset = useAppSelector(selectCurrentOffset);
  const currentHasMore = useAppSelector(selectCurrentHasMore);
  const currentLastOffset = useAppSelector(selectCurrentLastOffset);
  const currentList = useAppSelector(selectCurrentList);
  const currentLeaderBoard = useAppSelector(selectCurrentLeaderBoard);
  const initStart = useRef(useAppSelector(selectInitStart));

  const [getLeaderboardUsers] = leaderBoardApi.useLazyLeaderboardUsersQuery();
  const [getLeaderboardMe] = leaderBoardApi.useLazyLeaderboardMeQuery();

  useEffect(() => {
    ga4Event(EGa4Event.LeaderboardOpened, EGa4Category.Fishing);
    ga4StartTiming(EGa4Event.LeaderboardOpened);
    amplitude.track(AmplitudeEventClient.LeaderboardOpened);

    leaderboardMe && dispatch(setLeaderboardInitStart(false));
    leaderboardMe === undefined && loadMe();

    return () => {
      const time = ga4EndTiming(EGa4Event.LeaderboardOpened);
      time && ga4Event(EGa4EventTiming.ViewLeaderboard, EGa4Category.Fishing, '', { view_time: time });
    };
  }, []);

  useEffect(() => {
    currentLeaderBoard && dispatch(setLeaderboardMe({ userData: currentLeaderBoard.userData }));
  }, [currentType]);

  useEffect(() => {
    if (initStart.current) {
      dispatch(setLeaderboardInitStart(false));
      initStart.current = false;
      return;
    }
    loadUsers();
  }, [currentType, currentLeague, currentOffset]);

  const loadMe = () => {
    setIsLoading(true);
    getLeaderboardMe({})
      .unwrap()
      .then(({ userData }: ILeaderboardMe) => {
        if (userData === null) {
          setIsLoading(false);
          loadUsers();
          return;
        }

        const { league } = userData;

        dispatch(setCurrentLeague(league));
        dispatch(setLeaderboardMe({ userData }));

        league === LeaderboardLeague.BRONZE && loadUsers();
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error loading leaderboard me: ${data?.message}`);
        if (data) toast.error(data.message);
      });
  };

  const loadUsers = () => {
    if (currentOffset === currentLastOffset) return;
    setIsLoading(true);
    getLeaderboardUsers({ type: currentType, league: currentLeague, offset: currentOffset })
      .unwrap()
      .then((data: ILeaderboardUsers) => {
        if (data.leaderboard === null) return;

        !isInitCacheTime && initCacheTime();
        dispatch(setCurrentHasMore(data.leaderboard.length >= 40));
        dispatch(setLeaderBoardList(data));
        dispatch(setCurrentLastOffset(currentOffset));
        dispatch(setLeaderboardMe({ userData: data.userData }));
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error loading leaderboard users: ${data?.message}`);
        if (data) toast.error(data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const loadMore = useCallback(() => {
    if (!currentHasMore) return;

    const newOffSet = currentOffset + 40;
    const notLimit = newOffSet <= 1000;
    notLimit && setIsLoading(true);
    dispatch(setCurrentOffset(notLimit ? newOffSet : currentOffset));
  }, [currentOffset, currentHasMore]);

  return (
    <>
      <div className="flex h-full w-full flex-col gap-y-[10px] relative">
        <LeaderBoardHeader leaderBoardType={currentType} leaderboardLeague={currentLeague} />
        <div className={`fixed z-0 full-absolute  ${leagueRadialGradients[currentLeague]} opacity-30`}></div>

        <LeaderBoardList
          className="z-1"
          posts={currentList ?? []}
          hasMore={currentHasMore}
          isLoading={isLoading}
          loadMore={loadMore}
        ></LeaderBoardList>

        {leaderboardMe?.userData?.league === currentLeague && (
          <div className="w-full h-[30px] z-1 flex justify-center items-center">
            {isLoading && !leaderboardMe?.userData ? (
              <Loader size="sm" />
            ) : (
              <LeaderBoardListDetail
                isPurpure={true}
                position={leaderboardMe?.userData?.position || 0}
                userName={getUserName}
                balance={leaderboardMe?.userData?.balance || '0'}
              />
            )}
          </div>
        )}

        <LeaderBoardBtn className="mb-1 z-1" />
      </div>
    </>
  );
};
