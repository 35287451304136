import { useDispatch, useSelector, useStore } from 'react-redux';

import { combineReducers, configureStore, createSelector } from '@reduxjs/toolkit';
import { serverAPI } from '@shared/api/api';
import { commonSlice } from '@shared/store/slice/CommonSlice';
import { dailyRewardSlice } from '@shared/store/slice/DailyRewardSlice';
import { farmSlice } from '@shared/store/slice/FarmSlice';
import { fishingSlice } from '@shared/store/slice/FishingSlice';
import { leaderBoardSlice } from '@shared/store/slice/LeaderBoardSlice';
import { matchSlice } from '@shared/store/slice/MatchSlice';
import { taskSlice } from '@shared/store/slice/TaskSlice';
import { userSlice } from '@shared/store/slice/UserSlice';

const rootReducer = combineReducers({
  [commonSlice.name]: commonSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [farmSlice.name]: farmSlice.reducer,
  [fishingSlice.name]: fishingSlice.reducer,
  [leaderBoardSlice.name]: leaderBoardSlice.reducer,
  [dailyRewardSlice.name]: dailyRewardSlice.reducer,
  [taskSlice.name]: taskSlice.reducer,
  [matchSlice.name]: matchSlice.reducer,
  [serverAPI.reducerPath]: serverAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(serverAPI.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector = useSelector.withTypes<AppState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppStore = useStore.withTypes<typeof store>();
export const createAppSelector = createSelector.withTypes<AppState>();
