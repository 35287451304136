import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { initTelegramWebApp } from '../utils/initTelegramWebApp';

interface WebAppContextType {
  webApp: WebApp | null;
  isReady: boolean;
  user: WebAppUser | null;
}

const WebAppContext = createContext<WebAppContextType>({
  webApp: null,
  isReady: false,
  user: null,
});

export const WebAppProvider = ({ children }: { children: React.ReactNode }) => {
  const [webApp, setWebApp] = useState<WebApp | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initWebApp = async () => {
      try {
        const app = await initTelegramWebApp();
        setWebApp(app);
        setIsReady(true);
      } catch (error) {
        console.error('Failed to initialize Telegram WebApp:', error);
        toast.error('Failed to initialize Telegram WebApp');
        // TODO: Если сделать загрузку всего приложения после того, как webApp станет доступен, то напрямую перенаправлять на страницу ошибки, т.к. errorElement и toast ещё недоступны
      }
    };

    initWebApp();
  }, []);

  if (!webApp) {
    // TODO: идея как сделать загрузку всего приложения после гарантии того, что webApp станет доступен
    return <></>;
  }

  const value = {
    webApp,
    isReady,
    user: webApp?.initDataUnsafe?.user || null,
  };

  return <WebAppContext.Provider value={value}>{children}</WebAppContext.Provider>;
};

export const useWebApp = () => {
  const context = useContext(WebAppContext);
  if (context === undefined) {
    throw new Error('useWebApp must be used within a WebAppProvider');
  }
  return context;
};
