import { ReactNode, useCallback, useEffect } from 'react';

import { CloseIcon } from '@shared/assets';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { Portal } from '@shared/ui';

interface Props {
  children?: ReactNode;
  title?: string;
  isOpen?: boolean; // Статус открытия
  className?: string;
  classNameOverlay?: string;
  isFullScreen?: boolean;
  isOverlay?: boolean;
  isButtonClose?: boolean;
  isScroll?: boolean;
  heightAuto?: boolean;
  onClose?: () => void;
}

export const Modal = ({
  children,
  title,
  isOpen,
  className,
  classNameOverlay,
  isFullScreen,
  isButtonClose = true,
  isOverlay,
  isScroll,
  heightAuto,
  onClose,
}: Props) => {
  const modalClose = 'opacity-0 pointer-events-none';
  const modalOpen = 'opacity-1 pointer-events-auto';
  const fullScreenOverlay = `bg-black ${classNameOverlay ?? ''} ${isOverlay ? 'bg-opacity-80' : ''}`;
  const defaultOverlay = 'flex items-end justify-center';
  const { samplePlay } = useAudio();
  const { vibration } = useUtils();

  const modalContainerStyle = `${isOpen ? modalOpen : modalClose} fixed ${heightAuto ? 'h-auto bottom-0 left-0 right-0' : 'full-absolute'} z-10`;
  const modalContainerOverlayStyle = `${isFullScreen ? fullScreenOverlay : defaultOverlay} w-full h-full`;

  const closeHandler = useCallback(
    (samples: boolean) => {
      samples && vibration('impactOccurred', 'medium');
      samples && samplePlay(ESamples.buttonClick);

      if (onClose) {
        onClose();
      }
    },
    [onClose],
  );

  const onContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    isOpen && samplePlay(ESamples.popupMessage);
  }, [isOpen]);

  const baseModal = (
    <div
      className={`w-full bg-primary-darkBlue rounded-t-10 shadow-[0_-15px_19px_0_rgba(91,76,152,1)] flex flex-col gap-y-[10px] p-[5px_20px_20px] border-t-2 border-secondary-purpleLight ${isOpen ? 'modal-enter' : ''}`}
      onClick={onContentClick}
    >
      <div className="py-[10px] w-full relative flex justify-center items-center">
        {title && (
          <div className="font-bold text-[25px] leading-[27px] max-w-[300px]  text-white text-center">{title}</div>
        )}
        {isButtonClose && (
          <CloseIcon className="absolute top-4 right-0 cursor-pointer" onClick={() => closeHandler(true)} />
        )}
      </div>
      <div className={`flex flex-col gap-y-[10px] ${isScroll ? 'max-h-[500px] overflow-hidden overflow-y-auto' : ''}`}>
        {children}
      </div>
    </div>
  );

  return (
    <Portal>
      <div className={`${modalContainerStyle} ${className ?? ''}`}>
        <div className={`${modalContainerOverlayStyle}`} onClick={() => closeHandler(false)}>
          {isFullScreen ? children : baseModal}
        </div>
      </div>
    </Portal>
  );
};
