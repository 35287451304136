interface Props {
  title: string;
  fishGone?: boolean;
  className?: string;
}
export const ResultModalTitle = ({ title, fishGone, className }: Props) => {
  const fillBlocks = fishGone ? '#FF9A9A' : '#CCBFFF';
  const fillGradientText = fishGone ? '#FFB2B2' : '#CCBFFF';
  const fillBorder = '#CCBFFF';
  const fillGradientBackground = {
    color1: fishGone ? '#984C4C' : '#5B4C98',
    color2: fishGone ? '#9D3C3C' : '#5B4C98',
    color3: fishGone ? '#984C4C' : '#5B4C98',
  };
  return (
    <svg
      width="100%"
      height="78"
      viewBox="0 0 480 78"
      fill="none"
      className={`${className ?? ''}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_185_32682)">
        <mask id="path-1-inside-1_185_32682" fill="white">
          <path d="M0 0H480V78H0V0Z" />
        </mask>
        <path d="M0 0H480V78H0V0Z" fill={`url(#gradientBackground-${fishGone})`} />
        <g opacity="0.1" clipPath="url(#clip1_185_32682)">
          <rect
            opacity="0.1"
            x="-15.0645"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 -15.0645 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.1"
            x="22.2559"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 22.2559 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.2"
            x="59.5767"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 59.5767 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.2"
            x="96.897"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 96.897 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.5"
            x="134.218"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 134.218 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.5"
            x="171.538"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 171.538 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            x="208.858"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 208.858 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            x="246.179"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 246.179 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            x="283.5"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 283.5 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            x="320.82"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 320.82 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.5"
            x="358.141"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 358.141 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.5"
            x="395.461"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 395.461 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.2"
            x="432.782"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 432.782 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.2"
            x="470.102"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 470.102 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.1"
            x="507.423"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 507.423 -24.8898)"
            fill={fillBlocks}
          />
          <rect
            opacity="0.1"
            x="544.743"
            y="-24.8898"
            width="20"
            height="136"
            transform="rotate(30 544.743 -24.8898)"
            fill={fillBlocks}
          />
        </g>
        <text
          x="50%"
          y="54%"
          fill="url(#gradientText)"
          className="font-bold text-[40px] uppercase"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {title}
        </text>
      </g>
      <path
        d="M0 1H480V-1H0V1ZM480 77H0V79H480V77Z"
        fill="url(#gradientBorder)"
        mask="url(#path-1-inside-1_185_32682)"
      />
      <defs>
        <filter
          id="filter0_d_185_32682"
          x="116.555"
          y="3.51135"
          width="252.714"
          height="71.9005"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.8" />
          <feGaussianBlur stdDeviation="10.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.298039 0 0 0 0 0.298039 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_185_32682" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_185_32682" result="shape" />
        </filter>
        <linearGradient
          id={`gradientBackground-${fishGone}`}
          x1="0"
          y1="39"
          x2="480"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillGradientBackground.color1} stopOpacity="0" />
          <stop offset="0.500782" stopColor={fillGradientBackground.color2} />
          <stop offset="1" stopColor={fillGradientBackground.color3} stopOpacity="0" />
        </linearGradient>
        <linearGradient id="gradientText" x1="240" y1="15" x2="240" y2="63" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor={fillGradientText} />
        </linearGradient>
        <linearGradient id="gradientBorder" x1="0" y1="39" x2="480" y2="39" gradientUnits="userSpaceOnUse">
          <stop stopColor={fillBorder} stopOpacity="0" />
          <stop offset="0.5" stopColor={fillBorder} />
          <stop offset="1" stopColor={fillBorder} stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_185_32682">
          <path d="M0 0H480V78H0V0Z" fill="white" />
        </clipPath>
        <clipPath id="clip1_185_32682">
          <rect width="645.128" height="66" fill="white" transform="translate(-83.0645 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};
