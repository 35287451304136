import { gsap } from 'gsap';
import { Container, Text, TextStyle } from 'pixi.js';

const defaultConfig: TextStyle = new TextStyle({
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: 50,
  fill: 0xff7c7c,
  dropShadowAlpha: 0.6,
  dropShadowAngle: 0.4,
});

export default class UiTextComponent {
  private _view: Container | null = null;
  private _textContainer: Text | null = null;
  private _isDestroyed: boolean = false;
  private readonly _text: string;
  private readonly _config: TextStyle;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'Text';
      this._view.alpha = 0;
    }
    return this._view;
  }

  constructor(text: string, config?: TextStyle) {
    this._text = text;
    this._config = config ?? defaultConfig;
    this.initialize();
  }

  private initialize(): void {
    try {
      this.initText();
    } catch (error) {
      console.error('Failed to initialize text component:', error);
    }
  }

  async create(): Promise<boolean> {
    if (this._isDestroyed) return false;

    try {
      this.initText();
      this.view.alpha = 0;
      return true;
    } catch (error) {
      console.error('Failed to create text component:', error);
      return false;
    }
  }

  private initText(): void {
    if (this._isDestroyed) return;

    try {
      // Clean up existing text container if it exists
      if (this._textContainer) {
        this._textContainer.destroy();
        this._textContainer = null;
      }

      this._textContainer = new Text(this._text, this._config);

      if (this._textContainer) {
        const viewWidth = this.view.width;
        const viewHeight = this.view.height;

        this._textContainer.x = viewWidth / 2;
        this._textContainer.y = viewHeight;
        this._textContainer.anchor.set(0.5);

        this.view.addChild(this._textContainer);
      }
    } catch (error) {
      console.error('Failed to initialize text:', error);
    }
  }

  show(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this.view, { alpha: 1, duration: 0.5 });
      return tl;
    } catch (error) {
      console.error('Failed to show text:', error);
      return gsap.timeline();
    }
  }

  hide(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this.view, { alpha: 0, duration: 0.5 });
      return tl;
    } catch (error) {
      console.error('Failed to hide text:', error);
      return gsap.timeline();
    }
  }

  play(duration = 0.2): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this.view, { alpha: 1, duration });
      return tl;
    } catch (error) {
      console.error('Failed to play text animation:', error);
      return gsap.timeline();
    }
  }

  stop(duration = 0.2): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this.view, { alpha: 0, duration });
      return tl;
    } catch (error) {
      console.error('Failed to stop text animation:', error);
      return gsap.timeline();
    }
  }

  destroy(): boolean {
    if (this._isDestroyed) return true;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      if (this._view) {
        gsap.killTweensOf(this._view);
      }

      // Clean up text container
      if (this._textContainer) {
        this._textContainer.destroy();
        this._textContainer = null;
      }

      // Clean up view
      if (this._view) {
        this._view.destroy({ children: true });
        this._view = null;
      }

      return true;
    } catch (error) {
      console.error('Failed to destroy text component:', error);
      return false;
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._view) return;

    try {
      const viewWidth = this._view.width;
      const viewHeight = this._view.height;

      if (viewWidth && viewHeight) {
        this._view.pivot.set(viewWidth / 2, viewHeight / 2);
      }
    } catch (error) {
      console.error('Failed to adapt text component:', error);
    }
  }
}
