import { useTranslation } from 'react-i18next';

import { ProgressBar } from '@shared/ui';

interface Props {
  className?: string;
  progress: number;
}

export const LoaderProgressBar = ({ progress, className }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`w-full max-h-[7rem] self-center ${className ?? ''}`}>
      <div className="h-full bg-primary-darkBlue/70 p-[0.2rem_1rem] flex flex-col justify-center items-center gap-y-1 font-bold rounded-10 ">
        <span>{t('loading:progress.title')}</span>
        <ProgressBar progress={progress} />
        <span>{progress}%</span>
      </div>
    </div>
  );
};
