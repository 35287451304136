import { gsap } from 'gsap';
import { Container, Sprite, Texture } from 'pixi.js';
import { counterColorsHex } from 'src/pixi/configs/counter.config';

import { drawEllipseRadialGradient, replaceHexColorsToRGBA } from '@shared/utils/helpers.utils';

interface IComponentBackgroundBlurConfig {
  alpha: number; // Подложка для счетчика
  width: number; // Ширина
  height: number; // Высота
  radiusX: number; // Радиус
  radiusY: number;
  defaultScale: { x: number; y: number };
  defaultShow: number;
}

const defaultConfig: IComponentBackgroundBlurConfig = {
  alpha: 0,
  width: 700,
  height: 650,
  radiusX: 350,
  radiusY: 300,
  defaultScale: {
    x: 0,
    y: 0,
  },
  defaultShow: 0,
};

export default class UiCounterBlur {
  private _view: Container | null = null;
  private _backgroundBlurSprite: Sprite | null = null;
  private readonly _colorRgb: string[];
  private readonly _config: IComponentBackgroundBlurConfig;
  private _gradients: Texture[] = [];
  private _isDestroyed: boolean = false;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'BlurCounter';
    }
    return this._view;
  }

  constructor() {
    this._config = { ...defaultConfig };
    this._colorRgb = replaceHexColorsToRGBA(counterColorsHex);
    this.initialize();
  }

  private initialize(): void {
    try {
      this.initBlur();
      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize counter blur:', error);
    }
  }

  private initBlur(): void {
    if (this._isDestroyed) return;

    try {
      const { width, height, radiusX, radiusY } = this._config;

      // Create gradients
      for (const color of this._colorRgb) {
        const gradient = drawEllipseRadialGradient(width, height, width / 2, height / 2, radiusX, radiusY, color);
        if (gradient) {
          this._gradients.push(gradient);
        }
      }

      // Create and set up sprite
      if (this._gradients.length > 0) {
        this._backgroundBlurSprite = new Sprite(this._gradients[0]);
        this.view.alpha = this._config.defaultShow;
        this.view.addChild(this._backgroundBlurSprite);
      }
    } catch (error) {
      console.error('Failed to initialize blur:', error);
    }
  }

  /**
   * Изменяет текстуру в зависимости от переданного индекса
   * @param index
   */
  async changeBlur(index: number): Promise<void> {
    if (!this._backgroundBlurSprite || this._isDestroyed) return;

    try {
      const texture = this._gradients[index];
      if (texture) {
        this._backgroundBlurSprite.texture = texture;
      }
    } catch (error) {
      console.error('Failed to change blur:', error);
    }
  }

  play() {
    if (this._isDestroyed) return gsap.timeline();

    try {
      if (this._backgroundBlurSprite && this._gradients.length > 0) {
        this._backgroundBlurSprite.texture = this._gradients[0];
      }

      if (this._view) {
        this._view.scale.set(this._config.defaultScale.x, this._config.defaultScale.y);
      }

      const tl = gsap.timeline();

      tl.to(this.view, { alpha: 0.6, duration: 0.3 }, 0).to(this.view.scale, { x: 1, y: 1, duration: 2 }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to play animation:', error);
      return gsap.timeline();
    }
  }

  stop(duration = 0.4) {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      tl.to(this.view.scale, { x: 0, y: 0, duration }, 0).to(this.view, { alpha: 0, duration }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to stop animation:', error);
      return gsap.timeline();
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._backgroundBlurSprite) return;

    try {
      this._backgroundBlurSprite.pivot.set(this._backgroundBlurSprite.width / 2, this._backgroundBlurSprite.height / 2);
      this._backgroundBlurSprite.scale.set(0.5);
    } catch (error) {
      console.error('Failed to adapt counter blur:', error);
    }
  }

  destroy(): boolean {
    if (this._isDestroyed) return true;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      if (this._view) {
        gsap.killTweensOf(this._view);
        gsap.killTweensOf(this._view.scale);
      }

      // Clean up sprite
      if (this._backgroundBlurSprite) {
        gsap.killTweensOf(this._backgroundBlurSprite);
        this._backgroundBlurSprite.destroy({ texture: true });
        this._backgroundBlurSprite = null;
      }

      // Clean up gradients
      this._gradients.forEach((gradient) => {
        if (gradient) {
          gradient.destroy(true);
        }
      });
      this._gradients = [];

      // Clean up view
      if (this._view) {
        this._view.destroy({ children: true });
        this._view = null;
      }

      return true;
    } catch (error) {
      console.error('Failed to destroy counter blur:', error);
      return false;
    }
  }
}
