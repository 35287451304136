import { IMAGE } from '@shared/assets';
import { Image } from '@shared/ui';

interface Props {
  className?: string;
}
export const Logo = ({ className }: Props) => {
  return (
    <div className={` rounded-[35px_8px] bg-white flex justify-center items-center ${className ?? ''}`}>
      <div className="w-[calc(100%-10px)] h-[calc(100%-10px)] rounded-[32px_7px]  rounded-black/15 flex justify-center items-center  overflow-hidden">
        {/* <GlobalAvatarIllustration className="w-full h-full" /> */}
        <Image src={IMAGE.AVATARS.GRANDFATHER_FROG} className="w-full h-full" />
      </div>
    </div>
  );
};
