import { useTranslation } from 'react-i18next';

import { ExitIcon } from '@shared/assets';
import { Button } from '@shared/ui';
import { NBSP } from '@shared/utils/helpers.utils';

interface IProps {
  disabled?: boolean;
  onClick: () => void;
}
export const MatchLeaveTournament = ({ disabled, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <Button size="sm" theme="red" className="w-full" disabled={disabled} onClick={onClick}>
      <div className="flex items-center gap-x-[5px]">
        {t('multiplayer:leaveTournament')}
        {NBSP}
        <ExitIcon />
      </div>
    </Button>
  );
};
