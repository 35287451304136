import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import LoadingScreen from 'src/pixi/screens/LoadingScreen';
import MatchScreen from 'src/pixi/screens/MatchScreen';

import {
  AmplitudeEventClient,
  ClientSocketEventsMP,
  Currency,
  MAX_CATCHES_PER_PLAYER,
  PRIZE_POOL_PERCENTAGE,
  QUICK_MATCH_MAX_PLAYERS,
  ROUND_READY_TIME,
  ServerSocketEventMPPayloads,
  ServerSocketEventsMP,
  TOTAL_ROUNDS,
  Tick,
} from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { useMatchActions } from '@shared/hooks/match/useMatchActions';
import { useMatchUtils } from '@shared/hooks/match/useMatchUtils';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  ECommonModals,
  setDisabledFooter,
  setDisabledSettings,
  setGameButtonParams,
  setGameButtonResults,
  setShowFooter,
  setShowGameHeader,
  setViewCommonModals,
} from '@shared/store/slice/CommonSlice';
import {
  EMatchButtons,
  EMatchModals,
  restoreLastCoefficients,
  selectMatchBet,
  selectMatchId,
  setCountUsersGameRoom,
  setCountUsersWaitingRoom,
  setCurrentRound,
  setDisabledMatchButtons,
  setFinalLeaderBoard,
  setLastCoefficient,
  setLifeTable,
  setLoadingMatchButtons,
  setMatchId,
  setRemainingBaits,
  setViewMatchModals,
  setWaitTimeNextRound,
  setWaitTimeRoom,
} from '@shared/store/slice/MatchSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { IFinalLeaderBoard, IFinalLeaderBoardItem, ILastCoefficient } from '@shared/types/games.type';
import { EGameButtonMode } from '@shared/types/global.types';
import { EKeyStorage } from '@shared/types/storage.types';
import { sleep } from '@shared/utils/helpers.utils';

let matchId = 0;
let endGame = false;
let currentCatch = 0;
let currentTick: Tick = { hash: '', score: 0, timestamp: 0 };
let usedRestore = false;
let socketRoom: Socket | null = null;
let usesRound = false; //TODO: Экспортировать все события из хука в рамках этого файла что бы не было разных областей видимости
let usedBet = 0;
let prizePool = 0;
export const useMatchCore = () => {
  const delayWaitingRoom = 20000;
  const dataCheckString = localStorage.getItem(EKeyStorage.dataCheckString);

  const { setItem, getItem, removeItem } = useStorage('local');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { samplePlay } = useAudio();
  const sn = useRef<MatchScreen | null>(null);
  const loadingSn = useRef<LoadingScreen | null>(null);

  const userProfile = useAppSelector(selectUserProfile);
  const isJoinWaitingRoom = useRef(false);
  const isJoinGameRoom = useRef(false);
  matchId = useAppSelector(selectMatchId);
  const bet = useAppSelector(selectMatchBet);

  const langGameButton = {
    waiting: t('global:waiting'),
    skipping: t('global:skipping'),
    fish: t('global:fish'),
    fishGone: t('global:fishGone'),
    finished: t('global:finished'),
  };
  const { updateProfileCoins, vibration } = useUtils();
  const {
    resetSocketRoom,
    getNextRound,
    checkUseBaits,
    usedBaits,
    formatMultiplier,
    getInterval,
    getTypeCoefficient,
    getLifeTableData,
    calcFinalScores,
  } = useMatchUtils();
  const {
    resetWaitingRoom,
    resetGameRoom,
    clearLifeData,
    disabledButtonsWaitingRoom,
    showFastMatchElements,
    closeWaitingRoomElements,
    openFastMatchPage,
    closeFastMatchElements,
  } = useMatchActions();

  // Подключаем сцены и запускаем страницу быстрой игры
  const initGame = async () => {
    if (ProducerCore.getApp === null) return;

    ProducerCore.addScreen(new MatchScreen(ProducerCore.getApp));
    sn.current = ProducerCore.getScreen<MatchScreen>(ScreenId.Match);
    loadingSn.current = ProducerCore.getScreen<LoadingScreen>(ScreenId.Loading);
    const isLoading = loadingSn.current?.isShow;

    isLoading && loadingSn.current?.hidden();

    isLoading && (await sleep(700));

    const test = new URLSearchParams(window.location.search).get('test');

    if (test === '1') {
      dispatch(setShowGameHeader(false));
      showFastMatchElements();
      dispatch(setShowFooter(false));
      sn.current?.run();
    } else {
      await sleep(400);
      openFastMatchPage();
      await restoreMatch();
    }
  };

  const restoreMatch = async () => {
    const matchIdRestore = await getItem<number>(EKeyStorage.restoreMatchId);
    if (matchIdRestore) {
      dispatch(setMatchId(matchIdRestore));
      matchId = matchIdRestore;
      joinMatch({ matchId: matchIdRestore });
      usedRestore = true;
    }
  };

  /**************************************************************************
   * ? ПОИСК ИГРЫ
   *************************************************************************/

  // Поиск доступной комнаты, подписка на события ожидания
  const findLobby = async (bet: number, currency: Currency) => {
    dispatch(setLoadingMatchButtons({ button: EMatchButtons.choosingBet, value: true }));
    await sleep(300);

    clearLifeData();
    socketRoom?.disconnect();
    amplitude.track(AmplitudeEventClient.LobbySearch, { lobby_level: bet });

    socketRoom = io(`${import.meta.env.VITE_APP_MULTIPLAYER_WS_URL}/waiting-room`, {
      reconnection: true,
      auth: {
        dataCheckString,
        userId: import.meta.env.VITE_APP_USER_ID,
        amount: bet,
        currency,
      },
      transports: ['websocket'],
    });
    dispatch(setLoadingMatchButtons({ button: EMatchButtons.choosingBet, value: false }));

    subEventsWaitingRoom();
  };

  // Подписка на события ожидания комнаты
  const subEventsWaitingRoom = () => {
    if (!socketRoom) return;

    socketRoom.on('error', onErrorHandler);
    socketRoom.on('disconnect', onDisconnectWaitingRoomHandler);
    socketRoom.on(ServerSocketEventsMP.MatchCanceled, onDisconnectWaitingRoomHandler);
    socketRoom.on(ServerSocketEventsMP.UserJoinWaitingRoom, onUserJoinWaitingRoomHandler);
    socketRoom.on(ServerSocketEventsMP.UserLeaveWaitingRoom, onUserLeaveWaitingRoomHandler);
    socketRoom.on(ServerSocketEventsMP.MatchNeedAccept, onMatchNeedAcceptHandler);
    socketRoom.on(ServerSocketEventsMP.MatchAccepted, onMatchAcceptedHandler);
    socketRoom.on(ServerSocketEventsMP.MatchCanceled, onDisconnectWaitingRoomHandler);
    socketRoom.on(ServerSocketEventsMP.MatchNeedConnect, joinMatch);
  };

  // Обработка отключения от комнаты ожидания
  const onDisconnectWaitingRoomHandler = async () => {
    usedBet !== 0 && matchId === 0 && updateProfileCoins(usedBet, 'i');
    usedBet = 0;

    resetWaitingRoom();
    isJoinWaitingRoom.current = false;

    resetSocketRoom(socketRoom);

    await sleep(2000);
    dispatch(setWaitTimeRoom(0));
    disabledButtonsWaitingRoom(false);
  };

  // Обработка входа в комнату ожидания
  const onUserJoinWaitingRoomHandler = (data: { userCount: number }) => {
    const { userCount } = data;
    dispatch(setCountUsersWaitingRoom(userCount));
    if (!isJoinWaitingRoom.current) {
      usedBet = bet;

      isJoinWaitingRoom.current = true;

      dispatch(setViewMatchModals({ modal: EMatchModals.choosingBet, value: false }));
      dispatch(setViewMatchModals({ modal: EMatchModals.waitingRoom, value: true }));
      dispatch(setDisabledFooter(true));

      disabledButtonsWaitingRoom(true);

      dispatch(setDisabledMatchButtons({ button: EMatchButtons.joinRoom, value: true }));
      updateProfileCoins(bet, 'd');
    }
  };

  // Обработка выхода из комнаты ожидания
  const onUserLeaveWaitingRoomHandler = (data: { userCount: number }) => {
    const { userCount } = data;
    dispatch(setCountUsersWaitingRoom(userCount));
  };

  // Обработка события о необходимости подтверждения матча
  const onMatchNeedAcceptHandler = () => {
    samplePlay(ESamples.popupMessage);
    vibration('notificationOccurred', 'warning');

    dispatch(setViewCommonModals({ modal: ECommonModals.settings, value: false }));
    dispatch(setDisabledSettings(true));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.disconnect, value: true }));
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.joinRoom, value: false }));
    dispatch(setWaitTimeRoom(delayWaitingRoom));
    prizePool = usedBet * QUICK_MATCH_MAX_PLAYERS * PRIZE_POOL_PERCENTAGE;
    amplitude.track(AmplitudeEventClient.LobbyPrestarted, {
      lobby_balance: prizePool,
    });
  };

  // Обработка события о принятии матча
  const onMatchAcceptedHandler = () => {};

  /**************************************************************************
   * ? Игра в матче
   *************************************************************************/

  // Обработка события о необходимости подключения к матчу
  const joinMatch = async (data: { matchId: number }) => {
    const { matchId: newMatchId } = data;
    setItem(EKeyStorage.restoreMatchId, newMatchId);

    dispatch(setMatchId(newMatchId));
    matchId = newMatchId;
    dispatch(setWaitTimeRoom(0));

    socketRoom?.disconnect();

    socketRoom = io(`${import.meta.env.VITE_APP_MULTIPLAYER_WS_URL}/game-room`, {
      reconnection: true,
      auth: {
        dataCheckString,
        userId: import.meta.env.VITE_APP_USER_ID,
        matchId: matchId,
      },
      transports: ['websocket'],
    });
    subEventsGameRoom();
  };

  // Подписка на события игровой комнаты
  const subEventsGameRoom = () => {
    if (!socketRoom) return;

    socketRoom.on('disconnect', onDisconnectGameRoomHandler);
    socketRoom.on('error', onErrorHandler);
    socketRoom.on(ServerSocketEventsMP.UserJoinGameRoom, onUserJoinGameRoomHandler);
    socketRoom.on(ServerSocketEventsMP.UserLeaveGameRoom, onUserLeaveGameRoomHandler);
    socketRoom.on(ServerSocketEventsMP.RestoreGameState, onRestoreGameHandler);

    socketRoom.on(ServerSocketEventsMP.RoundReady, onRoundReadyHandler);
    socketRoom.on(ServerSocketEventsMP.RoundStart, onRoundStartHandler);
    socketRoom.on(ServerSocketEventsMP.Tick, onTickHandler);
    socketRoom.on(ServerSocketEventsMP.UserCatchSucceed, onUserCatchSucceedHandler);
    socketRoom.on(ServerSocketEventsMP.UserCatchFailed, onUserCatchFailedHandler);
    socketRoom.on(ServerSocketEventsMP.RoundEnd, onRoundEndHandler);

    socketRoom.on(ServerSocketEventsMP.GameEnd, onGameEndHandler);
  };

  // Отключение от игровой комнаты
  const onDisconnectGameRoomHandler = () => {
    usesRound = false;
    usedRestore = false;
    currentCatch = 0;
    prizePool = 0;
    currentTick = { hash: '', score: 0, timestamp: 0 };
    isJoinGameRoom.current = false;
    closeFastMatchElements();
    resetGameRoom({ textButton: langGameButton.waiting });
    removeItem(EKeyStorage.restoreMatchId);
    resetSocketRoom(socketRoom);
    dispatch(setWaitTimeRoom(0));
    sn.current?.stop(false);

    amplitude.track(AmplitudeEventClient.UserDisconnected);

    if (endGame) {
      navigate(EAppRouterUrl.winnersMatch);
      endGame = false;
    }
  };

  // Вход в комнату матча
  const onUserJoinGameRoomHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.UserJoinGameRoom]) => {
    const { userCount } = data;
    dispatch(setCountUsersGameRoom(userCount));
    if (!isJoinGameRoom.current) {
      closeWaitingRoomElements();
      amplitude.track(AmplitudeEventClient.LobbyStarted, { lobby_balance: prizePool });
      prizePool = 0;

      !usedRestore && loadingSn.current?.show();

      isJoinGameRoom.current = true;

      dispatch(
        setGameButtonParams({
          mode: EGameButtonMode.default,
          text: langGameButton.waiting,
          disabled: true,
          pulse: false,
        }),
      );
      dispatch(setCurrentRound(TOTAL_ROUNDS));
      setTimeout(() => {
        showFastMatchElements();
        loadingSn.current?.hidden();
      }, 1400);
    }
  };

  const onUserLeaveGameRoomHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.UserLeaveGameRoom]) => {
    const { userCount } = data;
    dispatch(setCountUsersGameRoom(userCount));
  };

  const onRestoreGameHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.RestoreGameState]) => {
    const { currentRound, playerStats, userCount, leaderboard, historyLastTick, canUseCatch } = data;
    const { rounds } = playerStats;

    const { list, user } = getLifeTableData(leaderboard);
    dispatch(setLifeTable({ list, me: user }));

    dispatch(setCurrentRound(TOTAL_ROUNDS - currentRound));
    dispatch(setCountUsersGameRoom(userCount));
    dispatch(setRemainingBaits(MAX_CATCHES_PER_PLAYER - rounds));
    usesRound = canUseCatch;

    dispatch(
      setGameButtonParams({
        mode: canUseCatch ? EGameButtonMode.fish : EGameButtonMode.default,
        text: canUseCatch ? langGameButton.fish : langGameButton.waiting,
        disabled: !canUseCatch,
        pulse: canUseCatch,
      }),
    );

    const reversedHistory = historyLastTick
      ?.reduce((acc: ILastCoefficient[], tick: number) => {
        acc.push({ value: tick, type: getTypeCoefficient(tick) });
        return acc;
      }, [])
      .reverse();

    dispatch(restoreLastCoefficients(reversedHistory));
  };

  // Пред подготовка перед раундом
  const onRoundReadyHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.RoundReady]) => {
    const { roundNumber } = data;
    const { baits, disabled } = checkUseBaits();

    !disabled && baits !== 0 && dispatch(setViewMatchModals({ modal: EMatchModals.confirmationRound, value: true }));
    dispatch(
      setGameButtonParams({
        mode: EGameButtonMode.default,
        text: langGameButton.waiting,
        disabled: true,
        pulse: false,
      }),
    );

    dispatch(setCurrentRound(getNextRound(roundNumber, TOTAL_ROUNDS)));
    dispatch(setWaitTimeNextRound(ROUND_READY_TIME * 1000));
  };

  // Тики
  const onTickHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.Tick]) => {
    const { score, hash, timestamp } = data;
    currentTick = { score, hash, timestamp };
    sn.current?.updateCounter(formatMultiplier(`${score}`), getInterval(score));
  };

  // Успешный улов
  const onUserCatchSucceedHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.UserCatchSucceed]) => {
    const { userId, score, leaderboard } = data;
    const { list, user } = getLifeTableData(leaderboard);
    dispatch(setLifeTable({ list, me: user }));
    const currentUserId = userProfile!.userId;
    if (userId === currentUserId) {
      amplitude.track(AmplitudeEventClient.SuccessfulHook, { score, currentCatch });
      vibration('notificationOccurred', 'success');
      sn.current?.counter?.animateScale();
      dispatch(
        setGameButtonParams({ mode: EGameButtonMode.fish, text: langGameButton.fish, disabled: true, pulse: false }),
      );
      dispatch(setGameButtonResults({ show: true, catch: `${score}`, coins: `${currentCatch}`, fishes: `${score}` }));
    }
  };

  // Неудачный улов
  const onUserCatchFailedHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.UserCatchFailed]) => {
    const { userId } = data;
    const currentUserId = userProfile!.userId;

    if (userId === currentUserId) {
      vibration('notificationOccurred', 'error');
      dispatch(
        setGameButtonParams({
          mode: EGameButtonMode.fishGone,
          text: langGameButton.fishGone,
          disabled: false,
          pulse: false,
        }),
      );
    }
  };

  // Начало раунда
  const onRoundStartHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.RoundStart]) => {
    const { roundNumber } = data;
    const { disabled } = checkUseBaits();
    sn.current?.run();
    const modeButton = disabled || !usesRound ? EGameButtonMode.default : EGameButtonMode.fish;
    const langButton = disabled || !usesRound ? langGameButton.skipping : langGameButton.fish;

    dispatch(setGameButtonResults({ show: false, catch: '', coins: '', fishes: '' }));
    dispatch(
      setGameButtonParams({
        mode: modeButton,
        text: langButton,
        disabled: disabled || !usesRound,
        pulse: !disabled && usesRound,
      }),
    );

    dispatch(setViewMatchModals({ modal: EMatchModals.confirmationRound, value: false }));
    dispatch(setCurrentRound(getNextRound(roundNumber, TOTAL_ROUNDS)));
    dispatch(setWaitTimeNextRound(0));
  };

  // Окончание раунда
  const onRoundEndHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.RoundEnd]) => {
    const { roundNumber, lastTick } = data;
    const fishGone = usesRound && currentCatch === 0;
    sn.current?.stop(fishGone);
    fishGone && vibration('notificationOccurred', 'error');

    dispatch(setGameButtonResults({ show: false, catch: '', coins: '', fishes: '' }));
    dispatch(
      setGameButtonParams({
        mode: EGameButtonMode.default,
        text: langGameButton.waiting,
        disabled: true,
        pulse: false,
      }),
    );
    dispatch(setCurrentRound(getNextRound(roundNumber, TOTAL_ROUNDS)));
    dispatch(setWaitTimeNextRound(0));
    dispatch(setLastCoefficient({ value: lastTick, type: getTypeCoefficient(lastTick) }));
    usesRound = false;
    currentCatch = 0;
  };

  // Окончание игры
  const onGameEndHandler = (data: ServerSocketEventMPPayloads[ServerSocketEventsMP.GameEnd]) => {
    const { finalScores } = data;
    const { sortedFinalLeaderBoard, userScore, userNewBalance } = calcFinalScores(finalScores);

    amplitude.track(AmplitudeEventClient.LobbyResult, { total_winnings: userNewBalance, ...userScore });

    dispatch(
      setFinalLeaderBoard({
        list: sortedFinalLeaderBoard,
        user: userScore as IFinalLeaderBoard<IFinalLeaderBoardItem>,
        win: String(userNewBalance),
      }),
    );
    dispatch(
      setGameButtonParams({
        mode: EGameButtonMode.default,
        text: langGameButton.finished,
        disabled: true,
        pulse: false,
      }),
    );
    dispatch(setWaitTimeNextRound(0));
    updateProfileCoins(userNewBalance, 'i');
    usesRound = false;
    endGame = true;
  };

  const confirmNextRound = (value: boolean) => {
    usesRound = false;

    if (value) {
      const { baits, disabled } = checkUseBaits();
      if (!disabled && baits !== 0) {
        usedBaits();
        socketRoom?.emit(ClientSocketEventsMP.UserRoundReady);
        amplitude.track(AmplitudeEventClient.UserConfirmedRound);
        usesRound = true;
      }
    } else {
      amplitude.track(AmplitudeEventClient.UserSkipRound);
    }
    dispatch(setViewMatchModals({ modal: EMatchModals.confirmationRound, value: false }));
  };

  // Подтверждение входа в комнату
  const confirmJoinRoom = () => {
    socketRoom?.emit(ClientSocketEventsMP.UserAccept);
    dispatch(setDisabledMatchButtons({ button: EMatchButtons.joinRoom, value: true }));
  };

  // Обработка нажатия кнопок в игре
  const processingGameButton = (mode: EGameButtonMode) => {
    if (currentTick.hash === '') return;
    if (mode === EGameButtonMode.fish) {
      const { score, hash } = currentTick;
      socketRoom?.emit(ClientSocketEventsMP.UserCatch, { hash, score });
      currentCatch = score;
      dispatch(
        setGameButtonParams({ mode: EGameButtonMode.fish, text: langGameButton.fish, disabled: true, pulse: false }),
      );
    }
  };

  // Выход из турнира
  const leaveTournament = () => {
    socketRoom?.emit(ClientSocketEventsMP.UserLeave);
    amplitude.track(AmplitudeEventClient.UserClosedLobby);

    clearLifeData();
    onDisconnectGameRoomHandler();
    navigate(EAppRouterUrl.fishing);
  };

  // Уничтожение сцены
  const destroyScene = () => {
    if (!sn.current) return;

    sn.current.destroy();
    sn.current = null;
    ProducerCore.removeScreen(ScreenId.Match);
  };

  // Обработка ошибок
  const onErrorHandler = (data: { message: string }) => {
    const { message } = data;
    if (message === 'Internal server error') {
      removeItem(EKeyStorage.restoreMatchId);
      usedRestore = false;
    }
    amplitude.track(AmplitudeEventClient.LobbyError, { error_type: message });
    console.error(`Error in match: ${message}`);
    if (message) toast.error(message);
  };

  return {
    initGame,
    findLobby,
    confirmJoinRoom,
    confirmNextRound,
    processingGameButton,
    leaveTournament,
    destroyScene,
    onDisconnectWaitingRoomHandler,
  };
};
