export async function initTelegramWebApp(maxAttempts = 3, delayMs = 1000): Promise<WebApp> {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const tryInit = () => {
      attempts++;

      if (window.Telegram?.WebApp) {
        resolve(window.Telegram.WebApp);
        return;
      }

      if (attempts >= maxAttempts) {
        reject(new Error('Failed to initialize Telegram WebApp after multiple attempts'));
        return;
      }

      console.log(`Attempt ${attempts}: Waiting for Telegram WebApp to initialize...`);
      setTimeout(tryInit, delayMs);
    };

    tryInit();
  });
}
