import { Assets } from 'pixi.js';
import { useState } from 'react';

import '@pixi/sound';
import { AssetBundles } from '@shared/configs/assets.config';
import { loadingPercent, setLoadingAssets, setLoadingPercent, setLoadingError } from '@shared/store/slice/CommonSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { sleep } from '@shared/utils/helpers.utils';
import { isArray } from '@shared/utils/is.funcs';

const namesAsset: string[] = [];
const keys: string[] = [];

// Добавляем тип для ошибок загрузки
interface LoadError {
  url: string;
  error: Error;
}

export const useAssets = (assets: AssetBundles | AssetBundles[] = [], loading = true) => {
  const percent = useAppSelector(loadingPercent);
  const [isLoadAssets, setLoadAssets] = useState(false);
  const [errors, setErrors] = useState<LoadError[]>([]);
  const dispatch = useAppDispatch();

  const setBundle = (name: string, assets: { [key: string]: string }) => {
    Assets.addBundle(name, assets);
    namesAsset.push(name);
  };

  const loadAssets = async (key: string, callback?: () => void) => {
    if (!assets) return;
    if (!keys.includes(key)) {
      keys.push(key);
    } else {
      setLoadAssets(true);
      return;
    }

    console.log('🚀 ~ loadAssets ~ assets');
    dispatch(setLoadingPercent(0));
    loading && dispatch(setLoadingAssets(true));
    setErrors([]); // Сбрасываем ошибки перед новой загрузкой

    try {
      if (isArray(assets)) {
        assets.forEach((asset) => {
          !asset.enabled && setBundle(asset.name, asset.urls);
        });
      } else {
        setBundle(assets.name, assets.urls);
      }

      await Assets.loadBundle(namesAsset, (percent) => {
        dispatch(setLoadingPercent(Math.ceil(percent * 100)));
      }).catch((error) => {
        // Обрабатываем ошибки загрузки отдельных ассетов
        const newError: LoadError = {
          url: error.url || 'unknown',
          error: error
        };
        setErrors(prev => [...prev, newError]);
        dispatch(setLoadingError(true));
        console.error('Asset loading failed:', error);
      });

      if (errors.length === 0) {
        if (loading) {
          await sleep(300);
          dispatch(setLoadingAssets(false));
        }
        setLoadAssets(true);
        callback && callback();
      }
    } catch (error) {
      // Обрабатываем общие ошибки загрузки
      console.error('Failed to load assets:', error);
      dispatch(setLoadingError(true));
      dispatch(setLoadingAssets(false));

      // Пытаемся загрузить повторно через 2 секунды
      setTimeout(() => {
        loadAssets(key, callback);
      }, 2000);
    }
  };

  return {
    percent,
    isLoadAssets,
    loadAssets,
    errors, // Возвращаем ошибки для обработки в компоненте
    hasErrors: errors.length > 0
  };
};
