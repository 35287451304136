import gsap from 'gsap';
import { Container, Sprite, Texture } from 'pixi.js';

const config = {
  hidden: {
    offsetY: 50,
    duration: 0.5,
    ease: 'bounce.out',
  },
  shown: {
    toDown: {
      duration: 0.5,
      ease: 'bounce.out',
    },
    swing: {
      repeat: 1,
      angle: 10,
      duration: 0.2,
      ease: 'sine.inOut',
    },
  },
  shake: {
    intensity: 2,
    duration: 0.02,
    countShake: 6,
    ease: 'sine.inOut',
  },
};

export default class UiFishHook {
  private _view: Container | null = null;
  widthButton: number = 0;
  targetY: number = 0;
  private isDestroyed: boolean = false;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
    }
    return this._view;
  }

  // Установка начальной позиции крючка по Y
  set setTargetY(value: number) {
    this.targetY = value;
    if (this._view) {
      this._view.y = this.targetY;
    }
  }

  constructor(widthButton: number) {
    this.widthButton = widthButton;
    this.createFishHook();
  }

  // Создание крючка
  createFishHook() {
    this._view = new Container();
    this._view.name = 'FishHook';

    const texture = new Sprite(Texture.from('coreFishHook.png'));
    texture.scale.set(this.widthButton / texture.width / 2);
    texture.anchor.set(0.5, 0);

    this._view.addChild(texture);
    this._view.alpha = 0;
  }

  /**
   * Отображает крючок с эффектом выпадания и раскачивания влево вправо
   */
  showFishHook() {
    if (!this._view || this.isDestroyed) return gsap.timeline();

    this._view.alpha = 0;
    this._view.y = this.targetY - this._view.height;

    const tl = gsap.timeline();
    tl.to(this._view, {
      y: this.targetY,
      alpha: 1,
      duration: config.shown.toDown.duration,
      ease: config.shown.toDown.ease,
    });
    tl.add(this.swing().swing, '>-85%');
    return tl;
  }

  /**
   * Скрывает крючок после начала тряски
   */
  hideFishHook() {
    if (!this._view || this.isDestroyed) return gsap.timeline();

    const targetY = this.targetY - this._view.height - config.hidden.offsetY;

    return this.shake().to(
      this._view,
      {
        y: targetY,
        alpha: 0,
        duration: config.hidden.duration,
        ease: config.hidden.ease,
      },
      '>-75%',
    );
  }

  swing(repeat?: number) {
    if (!this._view || this.isDestroyed) return gsap.timeline();

    const tlSwing = gsap.timeline({
      repeat: repeat || config.shown.swing.repeat,
      yoyo: true,
      onComplete: () => {
        if (!this.isDestroyed) {
          this.swingStop();
        }
      },
    });

    tlSwing
      .to(this._view, {
        angle: config.shown.swing.angle,
        duration: config.shown.swing.duration,
        ease: config.shown.swing.ease,
      })
      .to(this._view, {
        angle: -config.shown.swing.angle,
        duration: config.shown.swing.duration,
        ease: config.shown.swing.ease,
      });
    return tlSwing;
  }

  swingStop() {
    if (!this._view || this.isDestroyed) return gsap.timeline();

    const tlSwingComplete = gsap.timeline();
    tlSwingComplete.to(this._view, {
      angle: 0,
      duration: config.shown.toDown.duration,
      ease: config.shown.swing.ease,
    });
    return tlSwingComplete;
  }

  /**
   * Эффект тряски крючка
   */
  shake() {
    if (!this._view || this.isDestroyed) return gsap.timeline();

    const tl = gsap.timeline();
    for (let i = 0; i < config.shake.countShake; i++) {
      tl.to(this._view, {
        x: `+=${config.shake.intensity}`,
        duration: config.shake.duration,
        ease: config.shake.ease,
      })
        .to(this._view, {
          x: `-=${config.shake.intensity * 2}`,
          duration: config.shake.duration,
          ease: config.shake.ease,
        })
        .to(this._view, {
          x: `+=${config.shake.intensity}`,
          duration: config.shake.duration,
          ease: config.shake.ease,
        });
    }
    return tl;
  }

  destroy() {
    this.isDestroyed = true;
    if (this._view) {
      gsap.killTweensOf(this._view);
      setTimeout(() => {
        if (this._view) {
          this._view.destroy({ children: true });
          this._view = null;
        }
      }, 2000);
    }
  }
}
