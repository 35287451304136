import { useEffect, useState } from 'react';

import { LeaderboardLeague, LeaderboardType } from '@hf/shared-common';

import { LeaderBoardConfig } from '@pages/leaderBoard/configs/LeaderBoard.config';
import { LeaderBoardHeaderPersonages } from '@pages/leaderBoard/widgets/LeaderBoardHeader/LeaderBoardHeaderPersonages';
import { setCurrentLeague, setCurrentType } from '@shared/store/slice/LeaderBoardSlice';
import { useAppDispatch } from '@shared/store/store';
import { TextSlider } from '@shared/ui';
import { TTabsItemOption, Tabs } from '@shared/ui/Tabs/Tabs';
import { getNumberOfSlidesFromEnum } from '@shared/utils/helpers.utils';

interface Props {
  leaderBoardType: LeaderboardType;
  leaderboardLeague: LeaderboardLeague;
}

export const LeaderBoardHeader = ({ leaderBoardType, leaderboardLeague }: Props) => {
  const dispatch = useAppDispatch();
  const { leagueSlides, leagueTabs } = LeaderBoardConfig();
  const [disabledTabs, setDisabledTabs] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TTabsItemOption['value']>(leaderBoardType);
  const [selectedSlide, setSelectedSlide] = useState<LeaderboardLeague>(leaderboardLeague);

  useEffect(() => {
    setSelectedTab(leaderBoardType);
    setSelectedSlide(leaderboardLeague);
  }, [leaderBoardType, leaderboardLeague]);

  const onChangeTab = (key: number, value: TTabsItemOption['value']) => {
    setSelectedTab(key);
    dispatch(setCurrentType(value as LeaderboardType));
  };
  const onChangeSlide = (value: LeaderboardLeague) => {
    setSelectedSlide(value);
    dispatch(setCurrentLeague(value));
  };
  return (
    <>
      <div className="flex flex-col gap-y-[10px]">
        <LeaderBoardHeaderPersonages
          league={leagueSlides[selectedSlide].value}
          onLoadingImage={(loading) => {
            setDisabledTabs(loading);
          }}
        />
        <TextSlider
          currentSlide={selectedSlide}
          options={leagueSlides}
          countSlides={getNumberOfSlidesFromEnum(LeaderboardLeague)}
          onChange={onChangeSlide}
          disabled={disabledTabs}
          className="z-10"
        />
        <Tabs
          options={leagueTabs}
          size="sm"
          disabled={disabledTabs}
          selected={selectedTab}
          className="z-10"
          onChange={onChangeTab}
        />
      </div>
    </>
  );
};
