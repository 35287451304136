import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router-dom';

import { IUserTonWallet } from '@hf/shared-common';

import { EAppRouterUrl } from '@app/AppRouter';
import { fishingAPI } from '@shared/api/fishing';
import { userApi } from '@shared/api/user';
import { EI18Lang } from '@shared/configs/i18n/i18n';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { selectFishingInfo, setFishingInfo } from '@shared/store/slice/FishingSlice';
import { selectUserProfile, setUserProfile } from '@shared/store/slice/UserSlice';
import { store, useAppDispatch, useAppSelector } from '@shared/store/store';
import {
  EVibrationFunction,
  EVibrationImpactOccurred,
  EVibrationNotificationOccurred,
} from '@shared/types/global.types';
import { EKeyStorage } from '@shared/types/storage.types';
import { getValue, hasKeyByValue } from '@shared/utils/helpers.utils';

import { useUserInteraction } from '../../providers/UserInteractionProvider';
import { useWebApp } from '../../providers/WebAppProvider';

export let returnDailyRewards: EAppRouterUrl | undefined;

export const useUtils = () => {
  const { webApp, user: tgUser, isReady } = useWebApp();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasInteracted } = useUserInteraction();

  const { t } = useTranslation();
  const { samplePlay } = useAudio();
  const { getItem } = useStorage();

  const userProfile = useAppSelector(selectUserProfile);
  const fishingInfo = useAppSelector(selectFishingInfo);

  const toClick = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    to: To,
    disabled: boolean,
    samples: ESamples | undefined,
    vibrationOpt:
      | { func: EVibrationFunction; style: EVibrationImpactOccurred | EVibrationNotificationOccurred }
      | undefined = { func: 'impactOccurred', style: 'medium' },
    action?: () => void,
  ) => {
    if (disabled) {
      event && event.preventDefault();
      return;
    }

    if (hasInteracted) {
      // Проверка взаимодействие пользователя перед воспроизведением звука и вибрации
      vibrationOpt && vibration(vibrationOpt.func, vibrationOpt.style);
      samples && samplePlay(samples);
    }

    action && action();
    navigate(to);
  };

  const setReturnDailyRewards = (page: EAppRouterUrl) => {
    returnDailyRewards = page;
  };

  const operationForUpdate = (value: number, amount: number, operation: 'd' | 'i' = 'i'): number => {
    return operation === 'i' ? value + amount : value - amount;
  };

  const getUserName = useMemo(() => {
    return getValue(userProfile?.tgUsername, getValue(userProfile?.gameUsername, t('global:unknownUser')));
  }, [userProfile?.tgUsername, userProfile?.gameUsername]);

  const updateProfileCoins = (coins: number, option: 'd' | 'i' = 'i') => {
    const userProfile = store.getState().userSlice.userProfile;
    const newBalance = `${(userProfile && operationForUpdate(+userProfile.balance, coins ?? 0, option)) || 0}`;

    dispatch(
      userApi.util.updateQueryData('userProfile', {}, (data) => {
        data.balance = newBalance;
      }),
    );
    userProfile && dispatch(setUserProfile({ ...userProfile, balance: newBalance }));
  };

  const updateUserWallet = (wallet: IUserTonWallet) => {
    const userProfile = store.getState().userSlice.userProfile;

    dispatch(
      userApi.util.updateQueryData('userProfile', {}, (data) => {
        data.tonWallet = { ...wallet, isActive: true };
      }),
    );
    userProfile && dispatch(setUserProfile({ ...userProfile, tonWallet: wallet }));
  };

  const updateFishingBaits = (baits: number | null, bonusBaits: number | null, option: 'd' | 'i' = 'i') => {
    const newBaits = (fishingInfo && operationForUpdate(fishingInfo.baits, baits ?? 0, option)) || 0;
    const newBonusBaits = (fishingInfo && operationForUpdate(fishingInfo.bonusBaits, bonusBaits ?? 0, option)) || 0;

    dispatch(
      fishingAPI.util.updateQueryData('fishingInfo', undefined, (data) => {
        data.bonusBaits = newBaits;
        data.bonusBaits = newBonusBaits;
      }),
    );

    fishingInfo && dispatch(setFishingInfo({ ...fishingInfo, baits: newBaits, bonusBaits: newBonusBaits }));
  };

  const updateFishingWin = (coinsWin: number, baitsWin: number = 1, coinsBonusWin: number) => {
    const newCoinsWin = `${(fishingInfo && +fishingInfo.coinsWin + (coinsWin ?? 0)) || 0}`;
    const newBaitsWin = (fishingInfo && fishingInfo.baitsWin + (baitsWin ?? 0)) || 0;
    const newCoinsBonusWin = `${(fishingInfo && +fishingInfo.coinsBonusWin + (coinsBonusWin ?? 0)) || 0}`;

    dispatch(
      fishingAPI.util.updateQueryData('fishingInfo', undefined, (data) => {
        data.coinsWin = newCoinsWin;
        data.baitsWin = newBaitsWin;
        data.coinsBonusWin = newCoinsBonusWin;
      }),
    );

    fishingInfo &&
      dispatch(
        setFishingInfo({
          ...fishingInfo,
          coinsWin: newCoinsWin,
          baitsWin: newBaitsWin,
          coinsBonusWin: newCoinsBonusWin,
        }),
      );
  };

  const getLocale = async () => {
    let currentLang: EI18Lang = EI18Lang.en;
    await getItem<EI18Lang>(EKeyStorage.currentLang).then((value) => {
      if (value !== null) {
        currentLang = value;
      } else {
        const tgLang: EI18Lang | null =
          tgUser?.language_code && hasKeyByValue(EI18Lang, tgUser?.language_code)
            ? (tgUser?.language_code as EI18Lang)
            : null;
        tgLang && (currentLang = tgLang);
      }
    });
    return currentLang;
  };

  const vibration = (func: EVibrationFunction, style?: EVibrationImpactOccurred | EVibrationNotificationOccurred) => {
    if (!hasInteracted || !isReady) return; // Проверка на взаимодействие пользователя

    getItem(EKeyStorage.enabledVibration).then((value) => {
      if (value === null || !value || !webApp?.isVersionAtLeast('6.1')) return;

      switch (func) {
        case 'impactOccurred':
          style && webApp?.HapticFeedback.impactOccurred(style as EVibrationImpactOccurred);
          break;
        case 'notificationOccurred':
          style && webApp?.HapticFeedback.notificationOccurred(style as EVibrationNotificationOccurred);
          break;
        case 'selectionChanged':
          webApp?.HapticFeedback.selectionChanged();
          break;
      }
    });
  };
  const getLightweightUserName = (userName: string, length = 12, mask = '**') => {
    return `${userName.length > length ? `${userName.substring(0, length)}${mask}` : userName}`;
  };

  const getBalanceFormat = (value: string | number) => {
    return parseFloat(String(value)).toLocaleString('ru-RU');
  };

  return {
    toClick,
    setReturnDailyRewards,
    updateProfileCoins,
    updateFishingBaits,
    updateFishingWin,
    updateUserWallet,
    getLocale,
    vibration,
    getLightweightUserName,
    getBalanceFormat,
    getUserName,
    tgUser,
  };
};
