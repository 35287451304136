import ReactGA from 'react-ga4';

import { EAppRouterUrl } from '@app/AppRouter';
import {
  EGa4Category,
  EGa4Event,
  EGa4EventOptions,
  EGa4EventTiming,
  EGa4ParamsType,
  EGa4TimingList,
} from '@shared/types/ga4.types';
import { getBoolean, getValue } from '@shared/utils/helpers.utils';

let userTgId = 0;
const timingList: EGa4TimingList = {};

export const useGa4 = () => {
  const enabled = getBoolean(import.meta.env.VITE_APP_GA_4_ENABLED, false);
  const gaId = getValue(import.meta.env.VITE_APP_GA_4_ID, '');

  const initGa4 = ({ userId }: { userId: number }) => {
    if (!enabled) return;
    userTgId = userId;

    ReactGA.initialize(gaId, { gaOptions: { userId } });
  };

  const ga4Event = (
    event: EGa4Event | EGa4EventTiming,
    category: EGa4Category,
    label: string = '',
    params: EGa4ParamsType = {},
  ) => {
    if (!enabled) return;
    const options: EGa4EventOptions = { category, user_tg_id: userTgId, ...params };
    label !== '' && (options['label'] = label);

    ReactGA.event(event, options);
  };

  const ga4PageView = (page = EAppRouterUrl) => {
    if (!enabled) return;

    ReactGA.send({ hitType: 'pageview', page: page });
  };

  const ga4StartTiming = (event: EGa4Event) => {
    if (!enabled) return;

    timingList[event] = { start: 0, end: 0 };
    timingList[event].start = Date.now();
  };

  const ga4EndTiming = (event: EGa4Event) => {
    if (!enabled) return;

    if (!timingList[event]) return;

    timingList[event].end = Date.now();
    const time = getSecondsBetweenDates(event);
    if (!time) return null;

    const interval = getIntervalPageTime(time);

    delete timingList[event];
    return interval;
  };

  // Рассчитывает интервал времени
  const getIntervalPageTime = (seconds: number) => {
    const intervals = ['1_15', '16_30', '31_45', '46_70', '71_90', '91'];

    const intervalNumber = Math.floor((seconds - 1) / 15);

    return intervals[intervalNumber];
  };

  // Считает сколько прошло секунд между датами
  const getSecondsBetweenDates = (event: EGa4Event) => {
    const currentEvent = timingList[event];
    if (!currentEvent) return null;
    const { start, end } = currentEvent;

    const diff = end - start;
    const diffInMilliseconds = Math.abs(diff);
    const diffInSeconds = diffInMilliseconds / 1000;
    const roundedSeconds = diffInSeconds.toFixed(0);

    return Number(roundedSeconds);
  };

  return { initGa4, ga4Event, ga4PageView, ga4StartTiming, ga4EndTiming };
};
