import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { AmplitudeEventClient, ITaskAdInfo, TASK_AD_WATCH_LIMIT_MS } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { IApiError } from '@shared/api/api';
import { taskAPI } from '@shared/api/task';
import { useAudio } from '@shared/hooks/integration/useAudio';
import { useCountdown } from '@shared/hooks/utils/useCountdown';
import { AdController, AdsGramInitParams, ShowPromiseResult } from '@shared/types/adsGram.types';
import { ETaskCustomType } from '@shared/types/taskList.types';
import { getBoolean } from '@shared/utils/helpers.utils';

export interface useAdsGramParams {
  blockId: string;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
}

export const adsGramGapTime = TASK_AD_WATCH_LIMIT_MS;
export const useAdsGram = ({ blockId, onReward, onError }: useAdsGramParams) => {
  const enabledAdsGram = getBoolean(import.meta.env.VITE_APP_ADS_GRAM_ENABLED, false);
  const enabledDebug = getBoolean(import.meta.env.VITE_APP_ADS_GRAM_DEBUG, false);

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const [watchedAt, setWatchedAt] = useState<Date | null>(null);

  const [getAdsInfo] = taskAPI.useLazyAdsInfoQuery();
  const { currentSoundPause, currentSoundResume } = useAudio();
  const { setTargetDate, timeLeft } = useCountdown(() => {
    setDisabled(false);
  });

  const AdControllerRef = useRef<AdController | undefined>(undefined);
  const config: AdsGramInitParams = { blockId, debug: enabledDebug, debugBannerType: 'FullscreenMedia' };

  useEffect(() => {
    const now = new Date();

    if (!enabledAdsGram || !blockId) {
      setDisabled(true);
      return;
    }
    if (watchedAt && now.getTime() - new Date(watchedAt).getTime() < adsGramGapTime) {
      setDisabled(true);
      setTargetDate(new Date(watchedAt).getTime() + adsGramGapTime);
      return;
    }
    if (!AdControllerRef.current) {
      AdControllerRef.current = window.Adsgram?.init(config);
    }
    setDisabled(false);
  }, [blockId, watchedAt]);

  const checkAds = () => {
    setDisabled(true);
    getAdsInfo()
      .unwrap()
      .then((data: ITaskAdInfo) => {
        const { watchedAt } = data;
        setWatchedAt(watchedAt);
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error checking ads info: ${data?.message}`);
        if (data) toast.error(data.message);
      })
      .finally(() => {
        setDisabled(false);
        setLoading(false);
      });
  };

  const watchAds = useCallback(async () => {
    if (disabled) return;

    amplitude.track(AmplitudeEventClient.AdsStarted, { ads_provider: ETaskCustomType.ADS_GRAM });

    if (AdControllerRef.current) {
      currentSoundPause();
      AdControllerRef.current
        .show()
        .then(() => {
          onReward();
        })
        .catch((result: ShowPromiseResult) => {
          onError?.(result);
        })
        .finally(() => {
          currentSoundResume();
        });
    } else {
      currentSoundResume();
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Hm, something went wrong',
      });
    }
  }, [onError, onReward, disabled]);

  return { disabled, loading, watchedAt, enabledAdsGram, timeLeft, watchAds, checkAds };
};
