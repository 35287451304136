interface Props {
  className?: string;
  fishGone?: boolean;
}

export const ResultModalRadial = ({ fishGone, className }: Props) => {
  const FishGoneContent = (
    <svg
      width="100%"
      height="474"
      viewBox="0 0 474 474"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ?? ''}`}
    >
      <circle opacity="0.3" cx="237" cy="237" r="237" fill="url(#paint0_radial_185_32586)" />
      <circle cx="237" cy="237" r="142" fill="url(#paint1_radial_185_32586)" />
      <circle cx="237" cy="237" r="92" fill="url(#paint2_radial_185_32586)" />
      <circle cx="237" cy="237" r="98" fill="url(#paint3_radial_185_32586)" />
      <path
        d="M333.091 216.753C328.778 214.23 323.263 213.772 318.345 212.199C313.426 210.625 308.567 206.928 309.392 202.574C310.286 197.886 316.775 195.871 321.048 192.757C327.089 188.344 328.729 179.918 323.79 174.631C319.706 170.252 312.641 168.828 306.093 167.945C279.393 164.34 252.087 165.972 218.72 170.976C197.803 174.115 175.912 177.612 159.398 188.819C151.726 194.023 145.178 202.416 148.596 210.176C151.548 216.878 160.451 220 168.152 223.073C175.853 226.145 184.17 231.274 183.792 238.409C183.474 244.354 177.125 248.75 170.904 251.623C162.766 255.378 153.813 258.009 146.45 262.755C139.087 267.501 133.393 275.236 135.41 282.838C136.653 287.5 140.647 291.405 145.397 294.053C150.147 296.701 155.632 298.241 161.097 299.606C199.969 309.315 241.465 311.546 281.5 306.076C291.506 304.71 301.701 302.77 310.058 297.966C314.459 295.435 318.414 291.888 319.458 287.5C320.879 281.522 316.536 278.591 298.69 273.929C294.308 272.788 289.727 270.565 288.614 266.835C287.571 263.346 290.055 259.674 293.523 257.551C296.981 255.436 301.264 254.529 305.407 253.613C312.472 252.056 319.577 250.291 325.817 247.102C332.057 243.913 337.413 239.109 339.321 233.197C341.239 227.286 339.073 220.275 333.091 216.77V216.753ZM176.29 291.372C160.461 291.372 147.633 286.543 147.633 280.59C147.633 274.636 160.461 269.807 176.29 269.807C192.119 269.807 204.947 274.636 204.947 280.59C204.947 286.543 192.119 291.372 176.29 291.372ZM289.3 196.421C273.471 196.421 260.642 191.592 260.642 185.638C260.642 179.685 273.471 174.856 289.3 174.856C305.129 174.856 317.957 179.685 317.957 185.638C317.957 191.592 305.129 196.421 289.3 196.421Z"
        fill="url(#paint4_linear_185_32586)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_185_32586"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(237)"
        >
          <stop offset="0.465" stopColor="#9D3C3C" />
          <stop offset="1" stopColor="#9D3C3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_185_32586"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(142)"
        >
          <stop offset="0.45" stopColor="#9D3C3C" stopOpacity="0.55" />
          <stop offset="0.765" stopColor="#9D3C3C" stopOpacity="0.15" />
          <stop offset="0.915" stopColor="#9D3C3C" stopOpacity="0.15" />
          <stop offset="1" stopColor="#9D3C3C" stopOpacity="0.27" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_185_32586"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(92)"
        >
          <stop stopColor="#9D3C3C" />
          <stop offset="0.885" stopColor="#9D3C3C" stopOpacity="0.15" />
          <stop offset="1" stopColor="#9D3C3C" stopOpacity="0.3" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_185_32586"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(98)"
        >
          <stop stopColor="#FFD4AB" />
          <stop offset="0.88" stopColor="#9D3C3C" stopOpacity="0.15" />
          <stop offset="1" stopColor="#9D3C3C" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_185_32586"
          x1="237.768"
          y1="161.135"
          x2="237.412"
          y2="333.303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
  const YourCatchContent = (
    <svg width="100%" height="474" viewBox="0 0 474 474" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="237" cy="237" r="237" fill="url(#paint0_radial_185_32427)" />
      <circle cx="237" cy="237" r="142" fill="url(#paint1_radial_185_32427)" />
      <circle cx="237" cy="237" r="92" fill="url(#paint2_radial_185_32427)" />
      <defs>
        <radialGradient
          id="paint0_radial_185_32427"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(237)"
        >
          <stop offset="0.465" stopColor="#8F74FF" />
          <stop offset="1" stopColor="#8F74FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_185_32427"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(142)"
        >
          <stop offset="0.45" stopColor="#8F74FF" stopOpacity="0.55" />
          <stop offset="0.765" stopColor="#8F74FF" stopOpacity="0.15" />
          <stop offset="0.915" stopColor="#8F74FF" stopOpacity="0.15" />
          <stop offset="1" stopColor="#8F74FF" stopOpacity="0.27" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_185_32427"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(237 237) rotate(90) scale(92)"
        >
          <stop stopColor="#8F74FF" />
          <stop offset="0.885" stopColor="#8F74FF" stopOpacity="0.15" />
          <stop offset="1" stopColor="#8F74FF" stopOpacity="0.3" />
        </radialGradient>
      </defs>
    </svg>
  );

  return fishGone ? FishGoneContent : YourCatchContent;
};
