import { useCallback, useEffect, useRef, useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@shared/assets';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { ITextSliderOptions } from '@shared/types/ui.type';

interface Props {
  className?: string;
  options: ITextSliderOptions;
  currentSlide: number;
  disabled?: boolean;
  showControls?: boolean;
  countSlides: number;
  classNameTitle?: string;
  onChange?: (slide: number) => void;
}
export const TextSlider = ({
  className,
  options,
  currentSlide,
  disabled,
  countSlides,
  showControls = true,
  classNameTitle,
  onChange,
}: Props) => {
  const { vibration } = useUtils();
  const { samplePlay } = useAudio();
  const [title, setTitle] = useState<string | React.ReactNode>('');
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [currentClassName, setCurrentClassName] = useState('');
  const [currentTitleClassName, setCurrentTitleClassName] = useState('');
  const countSlide = useRef(countSlides);

  const getCurrentOption = (currentSlide: number) => {
    const current = options[currentSlide];
    setTitle(current.label);
    setCurrentClassName(current.className);
    setCurrentTitleClassName(current.titleClassName);
  };

  const checkButtons = useCallback(() => {
    setDisabledPrev(currentSlide === 0);
    setDisabledNext(currentSlide === countSlide.current);
  }, [currentSlide]);

  const getDisabled = (value: boolean) => (value ? 'pointer-events-none opacity-30' : '');
  const getStyleButton =
    'w-[30px] h-[30px] rounded-full bg-white/20 flex justify-center items-center cursor-pointer transition-colors';

  const prevSlide = useCallback(() => {
    if (disabled) return;

    const prev = currentSlide - 1;
    if (prev >= 0) {
      vibration('selectionChanged');
      samplePlay(ESamples.buttonClick);
      onChange && onChange(prev);
    }
  }, [currentSlide, disabled]);

  const nextSlide = useCallback(() => {
    if (disabled) return;

    const next = currentSlide + 1;

    if (next <= countSlide.current) {
      vibration('selectionChanged');
      samplePlay(ESamples.buttonClick);
      onChange && onChange(next);
    }
  }, [currentSlide, disabled]);

  useEffect(() => {
    if (!options) return;

    getCurrentOption(currentSlide);
    checkButtons();
  }, [currentSlide, options]);

  return (
    <>
      <div
        className={` w-full h-[40px] px-[10px] rounded-20 flex justify-between items-center transition-colors ${disabled ? 'pointer-events-none opacity-30' : ''} ${currentClassName}  ${className ?? ''}`}
      >
        {showControls && (
          <div className={` ${getStyleButton} ${getDisabled(disabledPrev)}`} onClick={prevSlide}>
            <ArrowLeftIcon />
          </div>
        )}
        <div
          className={`text-[18px] font-bold  uppercase transition-colors mx-auto ${currentTitleClassName} ${classNameTitle ?? ''}`}
        >
          {title}
        </div>
        {showControls && (
          <div className={`${getStyleButton}  ${getDisabled(disabledNext)}`} onClick={nextSlide}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
    </>
  );
};
