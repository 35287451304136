import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { EAppRouterUrl } from '@app/AppRouter';
import { FarmingPageIcon, FishingPageIcon, FriendsPageIcon, LeaderboardPageIcon, TasksPageIcon } from '@shared/assets';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useLeaderBoard } from '@shared/hooks/useLeaderBoard';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { selectDisabledFooter, setShowGameHeader } from '@shared/store/slice/CommonSlice';
import { setIsShowTriesSummary } from '@shared/store/slice/FishingSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { SoonLabel } from '@shared/ui/SoonLabel/SoonLabel';

interface Props {
  className?: string;
}
interface ILinks {
  name: string;
  route: EAppRouterUrl;
  icon: React.ReactNode;
  disabled: boolean;
  soon: boolean;
  blinking?: boolean;
  prepareAction?: () => void;
}
export const Footer = ({ className }: Props) => {
  const [, forceUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const disabledFooter = useAppSelector(selectDisabledFooter);
  useEffect(() => {
    forceUpdate(true);
  }, []);

  const location = useLocation();
  const { t } = useTranslation();
  const { checkCache: checkLeaderboardCache } = useLeaderBoard();
  const isDailyBonusPage = location.pathname === EAppRouterUrl.dailyRewards;
  const links: ILinks[] = [
    {
      name: t('footer:menu.fishing'),
      route: EAppRouterUrl.fishing,
      icon: <FishingPageIcon />,
      disabled: disabledFooter,
      soon: false,
      prepareAction: () => {
        dispatch(setShowGameHeader(false));
      },
    },
    {
      name: t('footer:menu.farming'),
      route: EAppRouterUrl.farming,
      icon: <FarmingPageIcon />,
      disabled: disabledFooter,
      soon: false,
      blinking: isDailyBonusPage,
      prepareAction: () => {
        dispatch(setShowGameHeader(false));
        dispatch(setIsShowTriesSummary(false));
      },
    },

    {
      name: t('footer:menu.tasks'),
      route: EAppRouterUrl.tasks,
      icon: <TasksPageIcon />,
      disabled: disabledFooter,
      soon: false,
    },
    {
      name: t('footer:menu.leaderboard'),
      route: EAppRouterUrl.leaderboard,
      icon: <LeaderboardPageIcon />,
      disabled: disabledFooter,
      soon: false,
      prepareAction: () => checkLeaderboardCache(),
    },
    {
      name: t('footer:menu.friends'),
      route: EAppRouterUrl.friends,
      icon: <FriendsPageIcon />,
      disabled: disabledFooter,
      soon: false,
    },
  ];
  const { toClick } = useUtils();

  return (
    <div
      className={`w-full bg-secondary-darkBlue/50 rounded-20 flex justify-between py-[10px] px-[30px] mx-6 border border-white/10  transition-opacity ${disabledFooter ? 'opacity-70' : ''}  ${className ?? ''}`}
    >
      {links.map(({ name, route, icon, disabled, soon, blinking, prepareAction }) => (
        <div className="relative flex justify-center items-center" key={`navLink_${name.toLowerCase()}`}>
          {soon && <SoonLabel isBlackout={false} />}
          <NavLink
            className={({ isActive }) =>
              `flex flex-col items-center gap-[3px] relative ${blinking ? 'blinking' : ''} ${isActive ? 'text-nav-active pointer-events-none' : 'text-nav opacity-30'} ${disabled ? 'pointer-events-none opacity-30' : ''}`
            }
            to={route}
            onClick={(event) =>
              toClick(
                event,
                route,
                disabled,
                ESamples.buttonClick,
                { func: 'impactOccurred', style: 'rigid' },
                prepareAction,
              )
            }
          >
            {icon}
            <p className="text-[10px]">{name}</p>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Footer;
