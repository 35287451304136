import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { BaitsIcon, CoinIcon } from '@shared/assets';
import { selectRewardsModal } from '@shared/store/slice/TaskSlice';
import { useAppSelector } from '@shared/store/store';
import { ETaskCustomType } from '@shared/types/taskList.types';
import { Button, Wrapper } from '@shared/ui';

import { Modal } from './Modal';

interface Props {
  className?: string;
  onClose?: (type: ETaskCustomType | string) => void;
}
export const ModalRewards = ({ className, onClose }: Props) => {
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const currentRewardsModal = useAppSelector(selectRewardsModal);
  const { isOpen, title, description, btnText, coins, baits, taskType } = currentRewardsModal;

  const handleClose = () => {
    if (!taskType) return;

    onClose && onClose(taskType);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpenDetails(true);
      }, 200);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className={className ?? ''} title={title} onClose={handleClose}>
      <div className="w-full flex flex-col gap-[10px]">
        <Wrapper className="flex justify-center items-center gap-[10px] flex-wrap">
          {coins && coins > 0 && (
            <Wrapper
              className={`!w-[48%] flex justify-center items-center gap-x-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
              paddingSize="sm"
              theme="darkBlue"
            >
              <CoinIcon className="w-[24px] h-auto" />
              <span className="text-[18px] font-bold text-primary-purpleLight">{coins}</span>
            </Wrapper>
          )}
          {baits && baits > 0 && (
            <Wrapper
              className={`!w-[48%] flex justify-center items-center gap-x-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
              paddingSize="sm"
              theme="darkBlue"
            >
              <BaitsIcon className="w-[24px] h-auto" />
              <span className="text-[18px] font-bold text-white">
                +{baits} {t('global:baits')}
              </span>
            </Wrapper>
          )}
          <div className="w-full text-[14px] text-center">{description}</div>
        </Wrapper>
        <Button size="lg" onClick={handleClose}>
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};
