import { IClaimLoseBonusFishing, IFishingInfo, IStartFishing, IWinFishing } from '@hf/shared-common';

import { serverAPI } from './api';

export const fishingAPI = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    fishingInfo: build.query<IFishingInfo, void>({
      query: () => ({
        url: `/fishing`,
      }),
    }),
    fishingStart: build.mutation<IStartFishing, void>({
      query: () => ({
        url: `/fishing/start`,
        method: 'POST',
        body: {},
      }),
    }),
    fishingWin: build.mutation<IWinFishing, { gameId: string }>({
      query: (body) => ({
        url: `/fishing/win`,
        method: 'POST',
        body,
      }),
    }),
    fishingLoseBonus: build.mutation<IClaimLoseBonusFishing, void>({
      query: () => ({
        url: `/fishing/lose-bonus`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
});
