import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { LeaderboardLeague, LeaderboardType } from '@hf/shared-common';

import { BronzePrizeIcon, GoldPrizeIcon, IMAGE, SilverPrizeIcon } from '@shared/assets';
import { ELeaderBoardPrizePlace } from '@shared/types/leaderBoard.type';
import { ITextSliderOptions } from '@shared/types/ui.type';
import { TTabsItemOption } from '@shared/ui/Tabs/Tabs';

export const LeaderBoardConfig = () => {
  const { t } = useTranslation();

  const leagueTabs: TTabsItemOption[] = [
    {
      key: 0,
      value: LeaderboardType.GLOBAL,
      label: t('leaderboard:tabs.global.title'),
    },
    {
      key: 1,
      value: LeaderboardType.WEEKLY,
      label: t('leaderboard:tabs.week.title'),
      disabled: false,
    },
  ];

  const leagueSlides: ITextSliderOptions = {
    [LeaderboardLeague.BRONZE]: {
      label: t('leaderboard:league.bronze'),
      className: 'bg-primary-orangeLight/20',
      titleClassName: 'text-secondary-orangeLight',
      value: LeaderboardLeague.BRONZE,
    },
    [LeaderboardLeague.SILVER]: {
      label: t('leaderboard:league.silver'),
      className: 'bg-primary-blueExtraLight/20',
      titleClassName: 'text-secondary-blueExtraLight',
      value: LeaderboardLeague.SILVER,
    },
    [LeaderboardLeague.GOLD]: {
      label: t('leaderboard:league.golden'),
      className: 'bg-primary-gold/20',
      titleClassName: 'text-secondary-gold',
      value: LeaderboardLeague.GOLD,
    },
    [LeaderboardLeague.PLATINUM]: {
      label: t('leaderboard:league.platinum'),
      className: 'bg-primary-blueLight/20',
      titleClassName: 'text-secondary-blueLight',
      value: LeaderboardLeague.PLATINUM,
    },
    [LeaderboardLeague.DIAMOND]: {
      label: t('leaderboard:league.brilliant'),
      className: 'bg-primary-blueExtraDark/20',
      titleClassName: 'text-primary-purpleLight',
      value: LeaderboardLeague.DIAMOND,
    },
  };

  const leaguePersonages: Record<LeaderboardLeague, { image: any; leftRadial: string; rightRadial?: string }> = {
    [LeaderboardLeague.BRONZE]: {
      image: IMAGE.PEPE.PepeBronzeIllustration,
      leftRadial: 'bg-radialBronzeCircle',
      rightRadial: 'bg-radialBronzeCircle',
    },
    [LeaderboardLeague.SILVER]: {
      image: IMAGE.PEPE.PepeSilverIllustration,
      leftRadial: 'bg-radialSilverCircle',
      rightRadial: 'bg-radialBronzeCircle',
    },
    [LeaderboardLeague.GOLD]: {
      image: IMAGE.PEPE.PepeGoldIllustration,
      leftRadial: 'bg-radialGoldCircle',
      rightRadial: 'bg-radialSilverCircle',
    },
    [LeaderboardLeague.PLATINUM]: {
      image: IMAGE.PEPE.PepePlatinumIllustration,
      leftRadial: 'bg-radialPlatinumCircle',
      rightRadial: 'bg-radialGoldCircle',
    },
    [LeaderboardLeague.DIAMOND]: {
      image: IMAGE.PEPE.PepeDiamondIllustration,
      leftRadial: 'bg-radialDiamondCircle',
      rightRadial: 'bg-radialPlatinumCircle',
    },
  };

  const leagueRadialGradients: Record<LeaderboardLeague, string> = {
    [LeaderboardLeague.BRONZE]: 'bg-radialBronzeCircle',
    [LeaderboardLeague.SILVER]: 'bg-radialSilverCircle',
    [LeaderboardLeague.GOLD]: 'bg-radialGoldCircle',
    [LeaderboardLeague.PLATINUM]: 'bg-radialPlatinumCircle',
    [LeaderboardLeague.DIAMOND]: 'bg-radialDiamondCircle',
  };

  const prizeLeaderBoard: Record<ELeaderBoardPrizePlace, ReactNode> = {
    [ELeaderBoardPrizePlace.GOLD]: <GoldPrizeIcon />,
    [ELeaderBoardPrizePlace.SILVER]: <SilverPrizeIcon />,
    [ELeaderBoardPrizePlace.BRONZE]: <BronzePrizeIcon />,
  };
  return { leagueTabs, leagueSlides, leaguePersonages, leagueRadialGradients, prizeLeaderBoard };
};
