import { DailyRewardsModal } from './dailyRewards/DailyRewardsModal';
import { GreatStartModal } from './greatStart/GreatStart';
import { LeaderBoardHistoryModal } from './leaderBoardHistory/LeaderBoardHistoryModal';
import { OnboardingMpModal } from './onboarding/OnboardingMultiplayerModal';
import { OnboardingTapModal } from './onboarding/OnboardingTapModal';
import { PartnerCatModal } from './partners/PartnerCatModal';
import { SettingsModal } from './settings/SettingsModal';
import { WelcomeRegularModal } from './welcome/WelcomeRegularModal';

export const ModalsContainer = () => {
  return (
    <>
      <DailyRewardsModal />
      <LeaderBoardHistoryModal />
      <OnboardingTapModal />
      <OnboardingMpModal />
      <PartnerCatModal />
      {/* <WelcomeModal /> */}
      <WelcomeRegularModal />
      <GreatStartModal />
      <SettingsModal />
    </>
  );
};
