import { useEffect, useRef } from 'react';

import { EKeyStorage } from '@shared/types/storage.types';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@shared/utils/localStorage.storage';
import { getTgCloudStorage, removeTgCloudStorage, setTgCloudStorage } from '@shared/utils/tgCloudStorage.storage';
import { useWebApp } from '../../providers/WebAppProvider';

type TMode = 'local' | 'cloud' | 'auto';
export interface IStorageData<T = any> {
  version: number;
  data: T;
}

// https://www.unixtimestamp.com/ - дата последней версии записи в хранилище
// Если стоит проверка на версию, то не с совпадением версии очищается запись
const vTimestamp = import.meta.env.VITE_APP_VERSION_STORAGE;
export const useStorage = (mode: TMode = 'auto') => {
  const minVersion = '6.9';
  const selectMode = useRef<TMode | null>(mode);
  const { webApp } = useWebApp();

  useEffect(() => {
    if (webApp?.isVersionAtLeast(minVersion)) {
      mode === 'auto' && (selectMode.current = 'cloud');
    } else {
      selectMode.current = 'local';
    }
  }, []);

  const setItem = async (key: EKeyStorage, data: any, addV = false) => {
    const newData = addV ? addVersion(data) : data;
    if (selectMode.current === 'cloud') {
      return await setTgCloudStorage(key, newData, true);
    } else if (selectMode.current === 'local') {
      return setLocalStorage(key, newData, true);
    }
  };

  const getItem = async <T = string,>(key: EKeyStorage, checkV = false): Promise<T | null> => {
    if (checkV) {
      const isRemove = await checkVersionAndRemove(key);
      if (isRemove) return null;
    }

    if (selectMode.current === 'cloud') {
      return (await getTgCloudStorage(key, true)) as T;
    } else if (selectMode.current === 'local') {
      return getLocalStorage(key, true) as T;
    }
    return null;
  };

  const removeItem = async (key: EKeyStorage) => {
    if (selectMode.current === 'cloud') {
      return await removeTgCloudStorage(key);
    } else if (selectMode.current === 'local') {
      return removeLocalStorage(key);
    }
  };

  const checkVersionAndRemove = async (key: EKeyStorage) => {
    return await getItem(key)
      .then((res: unknown) => {
        if (res === null) return true;
        if (!(res as IStorageData)?.version) {
          removeItem(key);
          return true;
        }

        const { version } = res as IStorageData;

        if (version !== vTimestamp) {
          removeItem(key);

          return true;
        }
      })
      .finally(() => {
        return false;
      });
  };

  const addVersion = (data: any): IStorageData => {
    return { version: vTimestamp, data };
  };
  return { setItem, getItem, removeItem };
};
