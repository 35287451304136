import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EAppRouterUrl } from '@app/AppRouter';
import { FriendsPageIcon } from '@shared/assets';
import { Button, Wrapper } from '@shared/ui';

export const TaskCardReferral = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper paddingSize="sm">
      <li className="w-full flex items-center justify-center px-[5px] py-[3px] gap-[10px] bg-blue-dark rounded-[10px]">
        <div className="flex-1 flex gap-[6px]">
          <div className="flex items-center text-blue-light w-[1.25em] h-auto justify-center">
            <FriendsPageIcon className="w-5 h-5" />
          </div>
          <div className="flex-1 flex flex-col">
            <p className="font-bold text-[13px]">{t('task:list.referral.text')}</p>
            <p className="text-[11px] flex items-center gap-x-1">{t('task:list.referral.description')}</p>
          </div>
        </div>
        <Button className="w-24" size="sm" onClick={() => navigate(EAppRouterUrl.friends)}>
          {t('global:getBonus')}
        </Button>
      </li>
    </Wrapper>
  );
};
