import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { EGameButtonMode } from '@shared/types/global.types';

export enum ECommonModals {
  dailyRewards = 'dailyRewards',
  leaderBoardHistory = 'leaderBoardHistory',
  welcome = 'welcome',
  welcomeRegular = 'welcomeRegular',
  greatStart = 'greatStart',
  settings = 'settings',
  partnerCat = 'partnerCat',
  onboardingTap = 'onboardingTap',
  onboardingMp = 'onboardingMp',
}

interface IGameButtonParams {
  mode: EGameButtonMode;
  text: string;
  disabled: boolean;
  loading: boolean;
  pulse: boolean;
}
interface IGameButtonResults {
  show: boolean;
  catch: string;
  coins: string;
  fishes: string;
}
interface CommonState {
  loadingAssets: boolean;
  loadingPercent: number;
  modals: Record<ECommonModals, boolean>;
  enableBaseMask: boolean;
  showGameHeader: boolean;
  showFooter: boolean;
  disabledFooter: boolean;
  showGameButton: boolean;
  gameButtonParams: IGameButtonParams;
  gameButtonResults: IGameButtonResults;
  disabledSettings: boolean;
  startApp: boolean;
  isErrorLayout: boolean;
  isInitialized: boolean;
  isLoadingLoaderButton: boolean;
  loadingError: boolean;
  welcomeRegularShown: boolean;
}

const initState: CommonState = {
  loadingAssets: false,
  loadingPercent: 0,
  modals: {
    [ECommonModals.dailyRewards]: false,
    [ECommonModals.leaderBoardHistory]: false,
    [ECommonModals.welcome]: false,
    [ECommonModals.welcomeRegular]: false,
    [ECommonModals.partnerCat]: false,
    [ECommonModals.onboardingTap]: false,
    [ECommonModals.onboardingMp]: false,
    [ECommonModals.greatStart]: false,
    [ECommonModals.settings]: false,
  },
  enableBaseMask: false,
  showGameHeader: false,
  showFooter: true,
  disabledFooter: false,
  showGameButton: false,
  gameButtonParams: {
    mode: EGameButtonMode.fish,
    text: '',
    disabled: false,
    loading: false,
    pulse: false,
  },
  gameButtonResults: {
    show: false,
    catch: '',
    coins: '',
    fishes: '',
  },
  disabledSettings: false,
  startApp: true,
  isErrorLayout: false,
  isInitialized: false,
  isLoadingLoaderButton: false,
  loadingError: false,
  welcomeRegularShown: false,
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initState,
  selectors: {
    selectViewCommonModal: createSelector(
      (state: CommonState) => state.modals,
      (_: CommonState, modal: ECommonModals) => modal,
      (modals, modal) => modals[modal],
    ),
    loadingAssets: (state: CommonState) => state.loadingAssets,
    loadingPercent: (state: CommonState) => state.loadingPercent,
    selectDisabledFooter: (state: CommonState) => state.disabledFooter,
    selectEnableBaseMask: (state: CommonState) => state.enableBaseMask,
    selectShowGameHeader: (state: CommonState) => state.showGameHeader,
    selectGameButtonParams: (state: CommonState) => state.gameButtonParams,
    selectShowGameButton: (state: CommonState) => state.showGameButton,
    selectShowFooter: (state: CommonState) => state.showFooter,
    selectDisabledSettings: (state: CommonState) => state.disabledSettings,
    selectGameButtonResults: (state: CommonState) => state.gameButtonResults,
    selectStartApp: (state: CommonState) => state.startApp,
    selectIsInitialized: (state: CommonState) => state.isInitialized,
    selectIsErrorLayout: (state: CommonState) => state.isErrorLayout,
    selectLoadingLoaderButton: (state: CommonState) => state.isLoadingLoaderButton,
    selectLoadingError: (state: CommonState) => state.loadingError,
    selectWelcomeRegularShown: (state: CommonState) => state.welcomeRegularShown,
  },
  reducers: {
    setViewCommonModals(state, action: PayloadAction<{ modal: ECommonModals; value: boolean }>) {
      const { modal, value } = action.payload;
      state.modals[modal] = value;
    },
    setLoadingAssets(state, action: PayloadAction<boolean>) {
      state.loadingAssets = action.payload;
    },
    setLoadingPercent(state, action: PayloadAction<number>) {
      state.loadingPercent = action.payload;
    },
    setDisabledFooter(state, action: PayloadAction<boolean>) {
      state.disabledFooter = action.payload;
    },
    setEnableBaseMask(state, action: PayloadAction<boolean>) {
      state.enableBaseMask = action.payload;
    },
    setShowGameHeader(state, action: PayloadAction<boolean>) {
      state.showGameHeader = action.payload;
    },
    setGameButtonParams(state, action: PayloadAction<Partial<IGameButtonParams>>) {
      state.gameButtonParams = {
        ...state.gameButtonParams,
        ...action.payload,
      };
    },
    setGameButtonResults(state, action: PayloadAction<Partial<IGameButtonResults>>) {
      state.gameButtonResults = {
        ...state.gameButtonResults,
        ...action.payload,
      };
    },
    setShowGameButton(state, action: PayloadAction<boolean>) {
      state.showGameButton = action.payload;
    },
    setShowFooter(state, action: PayloadAction<boolean>) {
      state.showFooter = action.payload;
    },
    setDisabledSettings(state, action: PayloadAction<boolean>) {
      state.disabledSettings = action.payload;
    },
    setStartApp(state, action: PayloadAction<boolean>) {
      state.startApp = action.payload;
    },
    setIsErrorLayout(state, action: PayloadAction<boolean>) {
      state.isErrorLayout = action.payload;
    },
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
    setLoadingLoaderButton(state, action: PayloadAction<boolean>) {
      state.isLoadingLoaderButton = action.payload;
    },
    setLoadingError(state, action: PayloadAction<boolean>) {
      state.loadingError = action.payload;
    },
    setWelcomeRegularShown(state, action: PayloadAction<boolean>) {
      state.welcomeRegularShown = action.payload;
    },
  },
});

export const {
  setLoadingAssets,
  setLoadingPercent,
  setDisabledFooter,
  setShowGameHeader,
  setViewCommonModals,
  setEnableBaseMask,
  setGameButtonParams,
  setShowGameButton,
  setShowFooter,
  setDisabledSettings,
  setGameButtonResults,
  setStartApp,
  setIsErrorLayout,
  setIsInitialized,
  setLoadingLoaderButton,
  setLoadingError,
  setWelcomeRegularShown,
} = commonSlice.actions;

export const {
  loadingAssets,
  loadingPercent,
  selectDisabledFooter,
  selectShowGameHeader,
  selectViewCommonModal,
  selectEnableBaseMask,
  selectGameButtonParams,
  selectShowGameButton,
  selectShowFooter,
  selectDisabledSettings,
  selectGameButtonResults,
  selectStartApp,
  selectIsErrorLayout,
  selectIsInitialized,
  selectLoadingLoaderButton,
  selectLoadingError,
  selectWelcomeRegularShown,
} = commonSlice.selectors;
