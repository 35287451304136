import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import GameScreen from 'src/pixi/screens/GameScreen';
import LoadingScreen from 'src/pixi/screens/LoadingScreen';
import { EButtonText, IButtonTextLang } from 'src/pixi/ui/UiButton.text';
import { EFishermanActions } from 'src/pixi/ui/UiFisherman';

import {
  AmplitudeEventClient,
  FISHING_COUNTER_TIME,
  FISHING_COUNT_TO_TAP,
  IFishingInfo,
  IStartFishing,
  IWinFishing,
  NumericRange,
} from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { IApiError } from '@shared/api/api';
import { fishingAPI } from '@shared/api/fishing';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { setDisabledFooter, setDisabledSettings, setShowGameHeader } from '@shared/store/slice/CommonSlice';
import {
  EFishingModals,
  EFishingResultModals,
  selectCurrentGame,
  selectFishingInfo,
  selectIsCloseResultModals,
  setCurrentGame,
  setFishingInfo,
  setFishingWin,
  setIsCloseResultModals,
  setIsShowTriesSummary,
  setViewFishingModals,
  setViewFishingResultModals,
} from '@shared/store/slice/FishingSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { EKeyStorage } from '@shared/types/storage.types';
import { getRandomMinMax, getRelativePosition, getValue, sleep } from '@shared/utils/helpers.utils';

export enum EStageGame {
  disabled = 'disabled',
  ready = 'ready',
  wait = 'wait',
  start = 'start',
  stop = 'stop',
}
const animateElements = {
  baits: 'animateBaits',
  bonusBaits: 'animateBonus',
  balance: 'animateBalance',
  targetBalance: 'targetAnimateBalance',
  referBalance: 'referAnimateBalance',
};
export const useCoreAnimation = () => {
  const dispatch = useAppDispatch();
  const fishingInfo = useAppSelector(selectFishingInfo);
  const currentGame = useAppSelector(selectCurrentGame);
  const isCloseResultModals = useAppSelector(selectIsCloseResultModals);
  const { ga4Event } = useGa4();
  const { t } = useTranslation();
  const { samplePlay } = useAudio();
  const { updateProfileCoins, updateFishingBaits, updateFishingWin, vibration } = useUtils();

  const [getFishingInfo] = fishingAPI.useLazyFishingInfoQuery();
  const [fishingStart] = fishingAPI.useFishingStartMutation();
  const [fishingWin] = fishingAPI.useFishingWinMutation();

  const [statusGame, setStatusGame] = useState<EStageGame>(EStageGame.ready);
  const [isInitScene, setInitScene] = useState(false);
  const [scene, setScene] = useState<GameScreen | null>(null);
  const [loadingScene, setLoadingScene] = useState<LoadingScreen | null>(null);

  const destroyGameScene = useRef<GameScreen | null>(null);
  const destroyLoadingScene = useRef<LoadingScreen | null>(null);
  const startWait = useRef(false);

  const tlHooking = useRef<gsap.core.Timeline>(gsap.timeline());
  const countClick = useRef(0);
  const progress = useRef(0);
  const counter = useRef<number>(0);
  const win = useRef<boolean>(false);
  const fishGone = useRef<boolean>(false);
  const randomProgressHooking = useRef<number[]>([]);
  const gameLinkTimeOut = useRef<ReturnType<typeof setTimeout> | null>(null);
  const countToTap = useRef<number>(
    +getValue<number>(import.meta.env.VITE_APP_FISHING_COUNT_TO_TAP, FISHING_COUNT_TO_TAP),
  );
  const gamesPlayed = useRef<number>(0);

  const getTimeOut = (counter: number) => FISHING_COUNTER_TIME[counter as NumericRange<10, 110>];
  const animateScaleElement = (element: string) => {
    const el = document.getElementById(element);
    if (!el) return;
    gsap.to(el, {
      duration: 0.2,
      scale: 1.3,
      ease: 'power1.inOut',
      onComplete: () => {
        gsap.to(el, { scale: 1 });
      },
    });
  };

  const animateFlyBalance = async (rewardBalance: number) => {
    await sleep(400);
    const balance = document.getElementById(animateElements.balance);
    const target = document.getElementById(animateElements.targetBalance);
    const refer = document.getElementById(animateElements.referBalance);

    if (!balance || !target || !refer) return;
    const { x: referX, y: referY } = getRelativePosition(refer, balance);
    const { x: targetX, y: targetY } = getRelativePosition(target, balance);

    gsap
      .timeline()
      .set(balance, { x: referX, y: referY, alpha: 1 })
      .to(balance, {
        scale: 1.4,
        duration: 0.2,
        ease: 'power1.inOut',
        yoyo: true,
        repeat: 1,
      })
      .to(balance, {
        x: targetX,
        y: targetY,
        duration: 1,
        fontSize: '15px',
        lineHeight: '15px',
        color: '#ffffff',
        ease: 'power1.inOut',
      })
      .addLabel('end', '>')
      .to(balance, { duration: 0.2, alpha: 0, ease: 'power1.inOut' }, 'end')
      .to(target, { duration: 0.2, scale: 1.3, yoyo: true, repeat: 1, ease: 'power1.inOut' }, 'end')
      .add(() => {
        updateProfileCoins(rewardBalance);
      }, 'end')
      .set(balance, { clearProps: 'all' });
  };
  const initGame = async () => {
    if (isInitScene || scene || ProducerCore.getApp === null) return;

    dispatch(setDisabledFooter(true));
    const langButtonText: IButtonTextLang = {
      fish: t('fishing:button.fish'),
      wait: t('fishing:button.wait'),
      tap: t('fishing:button.tap'),
      win: t('fishing:button.win'),
      fishGone: t('fishing:button.fishGone'),
      loading: t('fishing:button.loading'),
    };

    ProducerCore.addScreen(new GameScreen(ProducerCore.getApp, { langButtonText }));

    const sn = ProducerCore.getScreen<GameScreen>(ScreenId.Game);
    const loadingSn = ProducerCore.getScreen<LoadingScreen>(ScreenId.Loading);
    setScene(sn);
    setLoadingScene(loadingSn);

    !loadingSn?.isShow && loadingSn?.show();

    destroyGameScene.current = sn;
    destroyLoadingScene.current = loadingSn;
    setInitScene(true);
  };

  const destroyScene = () => {
    if (!destroyGameScene.current) return;
    destroyGameScene.current.destroy();
    destroyGameScene.current = null;
    ProducerCore.removeScreen(ScreenId.Game);
  };

  const readyGame = (info?: IFishingInfo | undefined) => {
    if (!scene) return;
    const data = info || fishingInfo;
    dispatch(setDisabledFooter(false));
    dispatch(setDisabledSettings(false));
    dispatch(setIsShowTriesSummary(true));
    dispatch(setShowGameHeader(true));

    win.current = false;
    fishGone.current = false;
    countClick.current = 0;
    progress.current = 0;

    scene.setStopGame = true;
    scene.setStageGame = EStageGame.ready;

    data?.baits === 0 && data?.bonusBaits === 0 && scene.disable();
    scene.play();

    if (data?.baits === 0) {
      if (!data?.isClaimLoseBonus && data?.baitsWin === 0) {
        dispatch(setViewFishingModals({ modal: EFishingModals.comeTomorrow, value: true }));
      }

      if (
        +data?.coinsWin &&
        data?.baitsWin !== 0 &&
        data?.bonusBaits === 0 &&
        localStorage.getItem(EKeyStorage.viewModalCatchToDay) !== 'true'
      ) {
        dispatch(setViewFishingModals({ modal: EFishingModals.catchToDay, value: true }));
        amplitude.track(AmplitudeEventClient.DailyCatchOpened);
        localStorage.setItem(EKeyStorage.viewModalCatchToDay, 'true');
      }
    } else {
      data?.coinsWin === '0' && localStorage.removeItem(EKeyStorage.viewModalCatchToDay);
      scene.unlock();
    }
  };

  const waitGame = () => {
    if (!scene || startWait.current) return;
    const tl = gsap.timeline();
    startWait.current = true;

    dispatch(setDisabledFooter(true));
    dispatch(setDisabledSettings(true));

    scene.fisherman?.nextSpeed(true);
    scene.buttonText?.changeText(EButtonText.loading);
    scene.fisherman?.switchAnimation(EFishermanActions.cast, () => {
      scene.setFloatLocationShow = true;
    });

    fishingStart()
      .unwrap()
      .then(async (data: IStartFishing) => {
        dispatch(setCurrentGame(data));
        ga4Event(EGa4Event.FishingStarted, EGa4Category.Fishing);
        amplitude.track(AmplitudeEventClient.FishingStarted);

        const isSubtractBait = fishingInfo && fishingInfo!.baits !== 0;
        const isSubtractBonusBait = fishingInfo && fishingInfo!.baits === 0 && fishingInfo!.bonusBaits !== 0;

        counter.current = data.counter;

        if (isSubtractBait) {
          updateFishingBaits(1, null, 'd');
          animateScaleElement(animateElements.baits);
        } else if (isSubtractBonusBait) {
          updateFishingBaits(null, 1, 'd');
          animateScaleElement(animateElements.bonusBaits);
        }

        await sleep(350);

        dispatch(setIsShowTriesSummary(false));

        scene.setStageGame = EStageGame.wait;
        scene.buttonText?.changeText(EButtonText.wait);
        scene.runButtonBubble();

        const timeTapTap = getTimeOut(counter.current) - 2000;
        timeTapTap >= 2000 && scene.buttonText?.animateReduction(timeTapTap / 1000);

        if (scene.fishHook) tl.add(scene.fishHook.swing(-1));

        if (getTimeOut(counter.current) >= 7000) {
          randomProgressHooking.current = getRandomTimeoutValues(2000, getTimeOut(counter.current) - 2000, 1, 3);
          tlHooking.current.kill();

          randomProgressHooking.current.forEach((time) => {
            tlHooking.current.add(() => scene.fisherman?.switchAnimation(EFishermanActions.hooking), time / 1000);
          });
          tlHooking.current.pause();
        }

        setTimeout(
          () => {
            scene.stopButtonBubble();
            scene.animateTapTap();
          },
          timeTapTap === 0 ? 200 : timeTapTap,
        );

        setTimeout(() => {
          tl.clear();
          if (scene.fishHook) tl.add(scene.fishHook.swingStop());
          setStatusGame(EStageGame.start);
          scene.fisherman?.switchAnimation(EFishermanActions.fishing);
        }, getTimeOut(counter.current));
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error starting fishing: ${data?.message}`);
        if (data) toast.error(data.message);
        setStatusGame(EStageGame.ready);
      })
      .finally(() => {
        startWait.current = false;
      });
  };

  const startGame = () => {
    if (!scene) return;

    scene.setStopGame = false;
    scene.setStageGame = EStageGame.start;
    scene.buttonText?.changeText(EButtonText.tap);
    tlHooking.current.resume();

    gameLinkTimeOut.current = setTimeout(() => {
      fishGone.current = true;
      setStatusGame(EStageGame.stop);
    }, getTimeOut(counter.current));
  };

  const stopGame = () => {
    if (!scene) return;
    if (!fishGone.current) {
      win.current = true;
      gameLinkTimeOut.current && clearTimeout(gameLinkTimeOut.current);
    }

    tlHooking.current.kill();
    scene.fisherman?.switchAnimation(EFishermanActions.fishing);
    scene.setStopGame = true;
    scene.setStageGame = EStageGame.stop;

    win.current && scene.buttonText?.changeText(EButtonText.win);
    fishGone.current && scene.buttonText?.changeText(EButtonText.fishGone);

    win.current &&
      fishingWin({ gameId: currentGame!.gameId })
        .unwrap()
        .then((data: IWinFishing) => {
          ga4Event(EGa4Event.FishingSuccess, EGa4Category.Fishing, '', { reward_sum: data.rewardAmount });
          amplitude.track(AmplitudeEventClient.FishingSuccess, { coins: data.rewardAmount });

          dispatch(setFishingWin(data));
          scene.fisherman?.switchAnimation(EFishermanActions.win, () => {
            scene.setFloatLocationShow = false;
          });

          if (fishingInfo) {
            const winCoins = gamesPlayed.current <= fishingInfo.baitsPerDay ? data.rewardAmount : 0;
            const winBonusCoins = gamesPlayed.current > fishingInfo.baitsPerDay ? data.rewardAmount : 0;

            updateFishingWin(winCoins, 1, winBonusCoins);
          }

          scene.stop(() => {
            dispatch(setViewFishingResultModals({ modal: EFishingResultModals.yourCatch, value: true }));
            animateFlyBalance(data.rewardAmount);
          });
        })
        .catch(({ data }: { data: IApiError }) => {
          console.error(`Error winning fishing: ${data?.message}`);
          if (data) toast.error(data.message);
          setStatusGame(EStageGame.ready);
        });

    if (fishGone.current) {
      ga4Event(EGa4Event.FishingFailed, EGa4Category.Fishing);
      amplitude.track(AmplitudeEventClient.FishingFailed);
      scene.fisherman?.switchAnimation(EFishermanActions.fishGone, () => {
        scene.setFloatLocationShow = false;
      });
      scene.stop(() => {
        dispatch(setViewFishingResultModals({ modal: EFishingResultModals.fisGone, value: true }));
      });
    }

    gamesPlayed.current = gamesPlayed.current + 1;
  };

  const disabledGame = () => {
    if (!scene) return;
  };

  useEffect(() => {
    if (!scene) return;

    switch (statusGame) {
      case EStageGame.disabled:
        disabledGame();
        break;
      case EStageGame.ready:
        readyGame();
        break;
      case EStageGame.wait:
        waitGame();
        break;
      case EStageGame.start:
        startGame();
        break;
      case EStageGame.stop:
        stopGame();
        break;
    }
  }, [statusGame]);

  useEffect(() => {
    if (!scene) return;
    scene.onButtonTouchCallback(() => {
      if (countClick.current >= counter.current) {
        setStatusGame(EStageGame.stop);
        return;
      }

      const newCount = countClick.current + countToTap.current;
      const newProgress = Math.round((newCount / counter.current) * 100);
      countClick.current = newCount;
      progress.current = newProgress;

      scene.buttonProgress?.animateProgress(newProgress);
      scene.changeColors(newProgress);
      newCount % 10 === 0 && scene.fisherman?.nextSpeed();
    });

    scene.onButtonClickCallback(() => {
      vibration('impactOccurred', 'heavy');

      switch (scene.stageGame) {
        case EStageGame.ready:
          samplePlay(ESamples.buttonConfirm);
          setStatusGame(EStageGame.wait);

          break;
      }
    });
    loadData();
  }, [scene]);

  const loadData = async () => {
    dispatch(setDisabledFooter(true));
    dispatch(setDisabledSettings(true));

    let info: IFishingInfo | undefined = undefined;

    if (!fishingInfo) {
      await getFishingInfo()
        .unwrap()
        .then((data) => {
          dispatch(setFishingInfo(data));
          info = data;
          gamesPlayed.current = data.baitsPerDay - data.baits;
        })
        .catch(({ data }: { data: IApiError }) => {
          console.error(`Error loading fishing info: ${data?.message}`);
          if (data) toast.error(data.message);
          readyGame();
        });
    } else {
      info = fishingInfo;
    }

    await sleep(1000);

    if (info !== undefined && scene && (info as IFishingInfo).baits === 0 && (info as IFishingInfo).bonusBaits === 0) {
      scene.disable();
    }

    setTimeout(() => {
      readyGame(info);
    }, 1000);

    loadingScene?.hidden();

    dispatch(setDisabledFooter(false));
    dispatch(setDisabledSettings(false));
  };

  const getRandomTimeoutValues = (min: number, max: number, countMin: number, countMax: number): number[] => {
    const count = getRandomMinMax(countMin, countMax);
    const timeoutValues = new Set<number>();

    while (timeoutValues.size < count) {
      let randomTimeout = getRandomMinMax(min, max);
      randomTimeout = Math.ceil(randomTimeout / 2000) * 2000;
      if (randomTimeout <= max) {
        timeoutValues.add(randomTimeout);
      }
    }

    return Array.from(timeoutValues).sort((a, b) => a - b);
  };

  useEffect(() => {
    isCloseResultModals && setStatusGame(EStageGame.ready);
    isCloseResultModals && dispatch(setIsCloseResultModals(false));
  }, [isCloseResultModals]);
  return { isInitScene, setStatusGame, initGame, destroyScene };
};
