import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderProgressBar } from '@pages/loading/widgets/LoaderProgressBar';
import { useStorage } from '@shared/hooks/integration/useStorage';
import {
  loadingPercent,
  selectLoadingLoaderButton,
  setLoadingPercent,
  setStartApp,
} from '@shared/store/slice/CommonSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { Button, Image } from '@shared/ui';
import { getBoolean } from '@shared/utils/helpers.utils';

export const LoadingPage = () => {
  const { t } = useTranslation();
  const { getItem, setItem } = useStorage();
  const dispatch = useAppDispatch();
  const [firstStart, setFirstStart] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const percent = useAppSelector(loadingPercent);
  const loading = useAppSelector(selectLoadingLoaderButton);

  const tournamentLoading = getBoolean(import.meta.env.VITE_APP_ENABLE_SPECIAL_LOADING_BG, false);
  let backgroundImageName;
  if (tournamentLoading) {
    backgroundImageName = 'BackgroundLoadingDailyPrizePool.png';
  } else {
    backgroundImageName = 'BackgroundLoading.jpg';
  }

  const background: React.CSSProperties = {
    backgroundImage: `url('/assets/images/backgrounds/${backgroundImageName}?v=${import.meta.env.VITE_APP_GIT_HASH}')`,
  };

  // Таймаут на загрузку
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (percent > 0 && percent < 100) {
        setLoadingError(true);
        // Принудительное завершение загрузки
        dispatch(setLoadingPercent(100));
      }
    }, 4000); // Таймаут 4 секунды

    return () => clearTimeout(timeoutId);
  }, [percent, dispatch]);

  // Инициализация первого запуска
  useEffect(() => {
    const initFirstStart = async () => {
      try {
        const value = await getItem(EKeyStorage.firstStart);
        setFirstStart(getBoolean(value, false));
      } catch (error) {
        console.error('Failed to get first start status:', error);
        // Возврат к false для взаимодействия
        setFirstStart(false);
      }
    };

    initFirstStart();
  }, [getItem]);

  const startApp = async () => {
    if (percent !== 100 || loading) return;

    try {
      await setItem(EKeyStorage.firstStart, true);
      dispatch(setStartApp(false));
    } catch (error) {
      console.error('Failed to set first start status:', error);
      // Продолжение все равно для предотвращения блокировки пользователя
      dispatch(setStartApp(false));
    }
  };

  // Добавление кнопки повторной загрузки, если загрузка не удалась
  const handleRetry = () => {
    setLoadingError(false);
    // Сброс состояния загрузки
    dispatch(setLoadingPercent(0));
    // Триггер перезагрузки ресурсов
    window.location.reload();
  };

  return (
    <div
      className="grid grid-rows-[auto_8rem] grid-cols-1 w-full min-h-dvh bg-no-repeat bg-cover bg-center items-start justify-center py-5"
      style={background}
    >
      <div className="flex flex-col text-white font-bold items-center gap-y-1 w-full py-[16px]">
        {!tournamentLoading && <Image src="/assets/images/logo/LogoLoading.png" isLoader={false} />}
      </div>

      <div className="flex flex-col gap-y-[10px]">
        <LoaderProgressBar progress={percent} className="px-2" />
        {loadingError && (
          <Button size="lg" onClick={handleRetry} className="max-w-[200px] m-auto">
            {t('global:retry')}
          </Button>
        )}
        {!firstStart && !loadingError && (
          <Button
            size="lg"
            loading={loading || firstStart}
            onClick={startApp}
            shine={true}
            className={`max-w-[200px] m-auto transition-opacity duration-500 delay-200 ${
              percent === 100 ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
          >
            {t('global:letsGo')}
          </Button>
        )}
      </div>
    </div>
  );
};
