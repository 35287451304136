import { createBrowserRouter } from 'react-router-dom';
import { BaseLayout } from 'src/layouts/BaseLayout/BaseLayout';
import { ErrorLayout } from 'src/layouts/ErrorLayout';
import { GameLayout } from 'src/layouts/GameLayout/GameLayout';

import { AppInitializer } from '@app/AppInitializer';
import { FriendsPage } from '@pages/friends/FriendsPage';
import { TasksPage } from '@pages/tasks/TasksPage';
import { DailyRewardsPage } from '@pages/dailyRewards/DailyRewardsPage';
import { LeaderBoardPage } from '@pages/leaderBoard/LeaderBoardPage';
import { FishingPage } from '@pages/fishing/FishingPage';
import { GamesPage } from '@pages/games/GamesPage';
import { FastMatchPage } from '@pages/games/multiplayer/FastMatchPage';
import { WinnersMatchPage } from '@pages/games/multiplayer/WinnersMatchPage';
import { ClassicMatchPage } from '@pages/games/multiplayer/ClassicMatchPage';
import { SinglePlayerPage } from '@pages/games/singlePlayer/SinglePlayerPage';
import { ContentNoTelegramPage } from '@pages/error/ContentNoTelegramPage';
import { ServersUnavailablePage } from '@pages/error/ServersUnavailablePage';
import { NotUserRegistration } from '@pages/error/NotUserRegistration';

export enum EAppRouterUrl {
  friends = '/friends',
  tasks = '/tasks',
  farming = '/',
  leaderboard = '/leaderboard',
  dailyRewards = '/daily-rewards',
  contentNoTelegram = '/content-no-telegram',
  serversUnavailable = '/servers-unavailable',
  notUserRegistration = '/not-user-registration',
  fishing = '/fishing',
  fastMatch = '/fishing/fastMatch',
  classicMatch = '/fishing/classicMatch',
  winnersMatch = '/fishing/winnersMatch',
  singlePlayer = '/fishing/singlePlayer',
}

export const appRouter = createBrowserRouter([
  {
    element: <AppInitializer />,
    errorElement: <ServersUnavailablePage />, // TODO: 404 page
    children: [
      {
        element: <BaseLayout />,
        children: [
          { path: EAppRouterUrl.friends, element: <FriendsPage /> },
          { path: EAppRouterUrl.tasks, element: <TasksPage /> },
          { path: EAppRouterUrl.dailyRewards, element: <DailyRewardsPage /> },
          { path: EAppRouterUrl.leaderboard, element: <LeaderBoardPage /> },
        ],
      },
      {
        element: <GameLayout />,
        children: [
          { index: true, path: EAppRouterUrl.farming, element: <FishingPage /> },
          { path: EAppRouterUrl.fishing, element: <GamesPage /> },
          { path: EAppRouterUrl.fastMatch, element: <FastMatchPage /> },
          {
            path: EAppRouterUrl.winnersMatch,
            element: <WinnersMatchPage />,
          },
          { path: EAppRouterUrl.classicMatch, element: <ClassicMatchPage /> },
          { path: EAppRouterUrl.singlePlayer, element: <SinglePlayerPage /> },
        ],
      },
      {
        element: <ErrorLayout />,
        children: [
          { path: EAppRouterUrl.contentNoTelegram, element: <ContentNoTelegramPage /> },
          { path: EAppRouterUrl.serversUnavailable, element: <ServersUnavailablePage /> },
          { path: EAppRouterUrl.notUserRegistration, element: <NotUserRegistration /> },
        ],
      },
    ],
  },
]);
