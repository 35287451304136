import { useTranslation } from 'react-i18next';

import { NoConnectionIllustration, TelegramIcon, XIcon } from '@shared/assets';
import { getValue, openLink } from '@shared/utils/helpers.utils';

export const ServersUnavailablePage = () => {
  const background: React.CSSProperties = {
    backgroundImage: `url('/assets/images/backgrounds/BackgroundLoading.png')`,
  };
  const { t } = useTranslation();

  return (
    <div className="w-full min-h-dvh flex flex-col gap-y-4  justify-center items-center ">
      <div className="absolute w-full h-full opacity-15 z-1 bg-no-repeat bg-cover bg-center" style={background}></div>
      <div className="flex flex-col gap-y-2 p-[10px] bg-black/20 rounded-10 text-white text-[18px] font-bold items-center z-[2]">
        <span className="text-center">{t('error:serversUnavailable.maintenance')} </span>
        <NoConnectionIllustration className="w-8/12 h-auto" />
        <span className="text-base font-normal text-center">{t('error:serversUnavailable.information')}</span>
      </div>
      <div className="flex justify-center gap-x-4 z-[2]">
        <TelegramIcon
          className="w-8 h-8 cursor-pointer"
          onClick={() => openLink(getValue(import.meta.env.VITE_APP_INFO_LINK_JOIN_TELEGRAM, ''))}
        />
        <XIcon
          className="w-8 h-8 cursor-pointer"
          onClick={() => openLink(import.meta.env.VITE_APP_INFO_LINK_JOIN_TWITTER)}
        />
      </div>
    </div>
  );
};
