import GrandfatherFrog from '@shared/assets/images/avatars/GrandfatherFrog.jpg';
import BackgroundLoading from '@shared/assets/images/backgrounds/BackgroundLoading.png';
import BackgroundPepeCoins from '@shared/assets/images/backgrounds/BackgroundPepeCoins.png';
import CupBronze from '@shared/assets/images/cups/CupBronze.png';
import CupGold from '@shared/assets/images/cups/CupGold.png';
import CupSilver from '@shared/assets/images/cups/CupSilver.png';
import QrBotDev from '@shared/assets/images/qr/QrBotDev.png';
import QrBotProd from '@shared/assets/images/qr/QrBotProd.png';
import QrBotStage from '@shared/assets/images/qr/QrBotStage.png';
import PepeBronzeIllustration from '@shared/assets/svg/illustrations/pepe/PepeBronzeIllustration.svg';
import PepeDiamondIllustration from '@shared/assets/svg/illustrations/pepe/PepeDiamondIllustration.svg';
import PepeGoldIllustration from '@shared/assets/svg/illustrations/pepe/PepeGoldIllustration.svg';
import PepePlatinumIllustration from '@shared/assets/svg/illustrations/pepe/PepePlatinumIllustration.svg';
import PepeSilverIllustration from '@shared/assets/svg/illustrations/pepe/PepeSilverIllustration.svg';

export const IMAGE = {
  BACKGROUNDS: {
    LOADING: BackgroundLoading,
    PEPE_COINS: BackgroundPepeCoins,
  },
  INFO_BLOCKS: {
    AVATAR: null,
  },
  PEPE: {
    PepeBronzeIllustration,
    PepeSilverIllustration,
    PepeGoldIllustration,
    PepePlatinumIllustration,
    PepeDiamondIllustration,
  },
  LIFE_TABLE_HEADER: '/assets/svg/LifeTableHeaderIllustration.svg',
  QR: {
    BOT_DEV: QrBotDev,
    BOT_STAGE: QrBotProd,
    BOT_PROD: QrBotStage,
  },
  CUPS: {
    CUP_BRONZE: CupBronze,
    CUP_SILVER: CupSilver,
    CUP_GOLD: CupGold,
  },
  AVATARS: {
    GRANDFATHER_FROG: GrandfatherFrog,
  },
};
