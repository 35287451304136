import i18n from 'i18next';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastItem, toast } from 'react-toastify';
import { ModalsContainer } from 'src/modals/ModalsContainer';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { LoadingPage } from '@pages/loading/LoadingPage';
import { serverAPI } from '@shared/api/api';
import { userApi } from '@shared/api/user';
import { backgroundsAssets, gameScreenAssets, matchScreenAssets, soundsAssets } from '@shared/configs/assets.config';
import { useAssets } from '@shared/hooks/integration/useAssets';
import { ESamples, ESounds, useAudio } from '@shared/hooks/integration/useAudio';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { useWallet } from '@shared/hooks/integration/useWallet';
import { useDailyReward } from '@shared/hooks/useDailyReward';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  ECommonModals,
  loadingAssets,
  selectEnableBaseMask,
  selectIsErrorLayout,
  selectIsInitialized,
  selectLoadingLoaderButton,
  selectStartApp,
  selectWelcomeRegularShown,
  setIsErrorLayout,
  setIsInitialized,
  setLoadingLoaderButton,
  setStartApp,
  setViewCommonModals,
} from '@shared/store/slice/CommonSlice';
import { setUser, setUserInitData, setUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { EKeyStorage } from '@shared/types/storage.types';
import { getBoolean } from '@shared/utils/helpers.utils';

import { useUserInteraction } from '../shared/providers/UserInteractionProvider';
import { useWebApp } from '../shared/providers/WebAppProvider';

export const AppInitializer = () => {
  const errorPages = [
    EAppRouterUrl.contentNoTelegram,
    EAppRouterUrl.serversUnavailable,
    EAppRouterUrl.notUserRegistration,
  ];
  const isNoTelegramMode = getBoolean(import.meta.env.VITE_APP_WEB_TELEGRAM_MODE, false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { initGa4, ga4Event } = useGa4();
  const { hasInteracted, setHasInteracted } = useUserInteraction();

  const isWelcomeRegularShown = useAppSelector(selectWelcomeRegularShown);

  const { webApp, isReady } = useWebApp();
  const { getItem, setItem } = useStorage();
  const { getLocale, vibration } = useUtils();
  const { initAudio, soundPlay, samplePlay } = useAudio();
  const { loadAssets } = useAssets([backgroundsAssets, gameScreenAssets, matchScreenAssets, soundsAssets]);

  const isLoadingAssets = useAppSelector(loadingAssets);
  const isStartApp = useAppSelector(selectStartApp);
  const enableBaseMask = useAppSelector(selectEnableBaseMask);
  const isInitialized = useAppSelector(selectIsInitialized);
  const isErrorLayout = useAppSelector(selectIsErrorLayout);
  const loadingProfile = useAppSelector(selectLoadingLoaderButton);

  const [getUserProfile] = userApi.useLazyUserProfileQuery();
  const { checkConnectCurrentUser } = useWallet({ onReward: undefined, onStatusChangeEnabled: false });
  const { checkDailyReward } = useDailyReward();

  const devUser: WebAppUser = {
    id: 0,
    first_name: '',
    username: import.meta.env.VITE_APP_TELEGRAM_USER_NAME,
  };
  const user = useRef<WebAppUser | undefined>(webApp?.initDataUnsafe?.user);
  const initData = useRef<string | undefined>(webApp?.initData);
  const platform = useRef<string | undefined>(webApp?.platform);

  toast.onChange((payload: ToastItem) => {
    if (payload.status !== 'added') return;

    if (payload.type === 'error') {
      samplePlay(ESamples.errorMessage, 0.01);
      vibration('notificationOccurred', 'error');
    }

    if (payload.type === 'success') {
      samplePlay(ESamples.notification, 0.01);
      vibration('notificationOccurred', 'success');
    }
  });

  // Отслеживание первого взаимодействия пользователя
  // Современные браузеры блокируют автозапуск медиа без пользовательского действия
  useEffect(() => {
    const handleUserInteraction = () => {
      setHasInteracted(true);

      // Удаление слушателей после первого взаимодействия
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    console.log('🚀 ~ useEffect isReady:', isReady);

    if (!isReady || !isStartApp || isInitialized) return;
    dispatch(serverAPI.util.resetApiState());
    webApp?.ready();

    // Инициализация GA4
    initGa4({ userId: webApp?.initDataUnsafe.user?.id ?? 0 });
    ga4Event(EGa4Event.Login, EGa4Category.Onboarding);

    webApp?.expand();
    webApp?.setHeaderColor('#222B4A');
    webApp?.setBackgroundColor('#222B4A');
    webApp?.disableVerticalSwipes();

    user.current = webApp?.initDataUnsafe?.user ?? undefined;
    initData.current = webApp?.initData ?? undefined;
    platform.current = webApp?.platform ?? undefined;

    if (isNoTelegramMode) {
      user.current = user.current ?? devUser;
      initData.current = initData.current ?? 'initData';
      platform.current = platform.current ?? '';
    }

    if (!user.current && (!initData.current || initData.current === '')) {
      dispatch(setIsErrorLayout(true));
      ga4Event(EGa4Event.ErrorStart, EGa4Category.Error, '', { message: 'Empty initData' });
      console.error('Empty initData');
      navigate(EAppRouterUrl.notUserRegistration);
      return;
    }

    if (platform.current === 'web') {
      dispatch(setIsErrorLayout(true));
      ga4Event(EGa4Event.ErrorStart, EGa4Category.Error, '', { message: 'Try open in web' });
      console.error('Try open in web');
      navigate(EAppRouterUrl.contentNoTelegram);
      return;
    }

    localStorage.setItem(EKeyStorage.dataCheckString, initData.current || '');

    const code = new URLSearchParams(window.location.search).get('tgWebAppStartParam');
    dispatch(setLoadingLoaderButton(true));
    getUserProfile({ code })
      .unwrap()
      .then(async (profile) => {
        const { userId } = profile;
        profile && dispatch(setUserProfile(profile));
        const lang = await getLocale();

        i18n.changeLanguage(lang);

        if (user.current && initData.current) {
          dispatch(setUser(user.current as WebAppUser));
          dispatch(setUserInitData(initData.current));

          await getItem(EKeyStorage.viewModalPartnerCat, true).then(async (res: any) => {
            if (res === null && code === import.meta.env.VITE_APP_PARTNER_MODAL_CODE_CAT) {
              dispatch(setViewCommonModals({ modal: ECommonModals.partnerCat, value: true }));
            }
          });
          await checkDailyReward();

          dispatch(setIsInitialized(true));
          dispatch(setIsErrorLayout(false));

          checkConnectCurrentUser();

          getItem(EKeyStorage.enabledVibration).then((value) => {
            value === null && setItem(EKeyStorage.enabledVibration, true);
          });
          vibration('notificationOccurred', 'success');
          initAmplitude(userId);
        }
      })
      .catch((error) => {
        console.error('Get user profile error', error);
        const errorText =
          error.message || error.data?.message || error.data?.error || error.data?.detail || error.data || error;
        dispatch(setIsErrorLayout(true));
        ga4Event(EGa4Event.ErrorStart, EGa4Category.Error, '', {
          message: JSON.stringify(errorText),
        });
        navigate(EAppRouterUrl.serversUnavailable);
      })
      .finally(() => {
        dispatch(setLoadingLoaderButton(false));
      });

    loadAssets('coreScreenAssets', async () => {
      const firstStart = await getItem(EKeyStorage.firstStart);
      const autoStart = getBoolean(import.meta.env.VITE_APP_AUTO_START, false);

      if (firstStart === null && autoStart) {
        setItem(EKeyStorage.firstStart, true);
        dispatch(setStartApp(false));
        return;
      }
      if (getBoolean(firstStart, false)) {
        dispatch(setStartApp(false));
      }
    });

    errorPages.includes(location.pathname as EAppRouterUrl) && navigate(EAppRouterUrl.farming);
  }, [isReady, isStartApp, isInitialized]);

  const initAmplitude = (userId: number) => {
    const enabled = getBoolean(import.meta.env.VITE_APP_AMPLITUDE_ENABLED, false);
    const enabledReplay = getBoolean(import.meta.env.VITE_APP_AMPLITUDE_REPLAY_ENABLED, false);

    if (enabledReplay) {
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1,
      });

      amplitude.add(sessionReplayTracking);
    }
    enabled && amplitude.init(import.meta.env.VITE_APP_AMPLITUDE_API_KEY, `fisher-${userId}`);
  };

  useEffect(() => {
    if (isInitialized && hasInteracted && !isLoadingAssets) {
      let retryCount = 0;
      const maxRetries = 3;

      const tryInitAudio = async () => {
        try {
          await initAudio();
          soundPlay(ESounds.ambientPianoBPM82);
        } catch (error) {
          console.error('Failed to initialize audio:', error);
          if (retryCount < maxRetries) {
            retryCount++;
            console.log(`Retrying audio initialization (${retryCount}/${maxRetries})...`);
            setTimeout(tryInitAudio, 1000);
          }
        }
      };

      tryInitAudio();
    }
  }, [isInitialized, hasInteracted, isLoadingAssets]);

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location.pathname]);

  // Показ модального окна WelcomeRegularModal один раз после инициализации
  useEffect(() => {
    if (
      isInitialized &&
      !isErrorLayout &&
      !isWelcomeRegularShown &&
      getBoolean(import.meta.env.VITE_APP_ENABLE_WELCOME_REGULAR, false)
    ) {
      dispatch(setViewCommonModals({ modal: ECommonModals.welcomeRegular, value: true }));
    }
  }, [isInitialized, isErrorLayout]);

  if (isErrorLayout) return <Outlet />;
  if (isLoadingAssets || isStartApp || loadingProfile) return <LoadingPage />;

  if (isInitialized && !isErrorLayout)
    return (
      <>
        {enableBaseMask && <div className="absolute h-full w-full bg-primary-darkBlue z-50" />}
        <Outlet />
        <ModalsContainer />
      </>
    );
};
