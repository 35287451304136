import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { AmplitudeEventClient } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { DailyBonusDetail } from '@pages/dailyRewards/widgest/DailyBonusDetail';
import { DailyBonusIllustration } from '@shared/assets';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useDailyReward } from '@shared/hooks/useDailyReward';
import { returnDailyRewards, useUtils } from '@shared/hooks/utils/useUtils';
import { ECommonModals, selectViewCommonModal } from '@shared/store/slice/CommonSlice';
import { useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event, EGa4EventTiming } from '@shared/types/ga4.types';
import { Button, Wrapper } from '@shared/ui';
import { sleep } from '@shared/utils/helpers.utils';

export const DailyRewardsPage = () => {
  const { t } = useTranslation();
  const { dailyRewardList, dailyRewardInfo } = useDailyReward();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const isOpenReward = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.dailyRewards));
  const { toClick } = useUtils();
  const { ga4Event, ga4StartTiming, ga4EndTiming } = useGa4();
  const scrollToElement = async () => {
    if (scrollToRef.current) {
      await sleep(200);
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    !isOpenReward && scrollToElement();
  }, [isOpenReward]);

  useEffect(() => {
    amplitude.track(AmplitudeEventClient.CalendarOpened);
    ga4Event(EGa4Event.CalendarOpened, EGa4Category.Onboarding, '', { days_streak: dailyRewardInfo?.streakCount });
    ga4StartTiming(EGa4Event.CalendarOpened);
    return () => {
      ga4Event(EGa4Event.CalendarClosed, EGa4Category.Onboarding, '', { days_streak: dailyRewardInfo?.streakCount });
      const time = ga4EndTiming(EGa4Event.CalendarOpened);
      time && ga4Event(EGa4EventTiming.ViewCalendar, EGa4Category.Onboarding, '', { view_time: time });
    };
  }, []);
  return (
    <>
      <div className="h-full flex flex-col items-center gap-[10px]">
        <Wrapper className="flex flex-col gap-4 items-center z-10">
          <DailyBonusIllustration className=" w-6/12 h-auto" />
          <span className="text-[25px] font-bold">{t('daily_bonus:daily.bonus.title')}</span>
        </Wrapper>
        <Wrapper className="text-center text-[15px]">{t('daily_bonus:daily.bonus.description')} </Wrapper>
        <Wrapper className="flex flex-1 w-full relative overflow-hidden after:absolute after:w-full after:h-[20px] after:bg-darkeningList after:bottom-0">
          <div className="h-auto overflow-hidden overflow-y-auto scroll-smooth flex justify-center gap-[10px] pb-[10px]   flex-wrap">
            {dailyRewardList.map(({ baits, day, coins, active, pulse }, _) => (
              <DailyBonusDetail
                refScroll={(pulse && scrollToRef) || null}
                key={day}
                active={active}
                day={day}
                baits={baits}
                coins={coins}
                pulse={pulse}
              />
            ))}
          </div>
        </Wrapper>
        <NavLink
          to={returnDailyRewards ? returnDailyRewards : EAppRouterUrl.tasks}
          onClick={(event) =>
            toClick(event, returnDailyRewards ? returnDailyRewards : EAppRouterUrl.tasks, false, ESamples.buttonClick)
          }
          className="w-full"
        >
          <Button size="lg" className="w-full" shine={true} sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('global:close')}
          </Button>
        </NavLink>
      </div>
    </>
  );
};
