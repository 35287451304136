import gsap from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';
import { Application, Graphics, ICanvas, IPointData } from 'pixi.js';
import BackgroundScreen from 'src/pixi/screens/BackgroundScreen';
import GameScreen from 'src/pixi/screens/GameScreen';
import LoadingScreen from 'src/pixi/screens/LoadingScreen';
import MatchScreen from 'src/pixi/screens/MatchScreen';

export enum ScreenId {
  Game = 'game-screen',
  Loading = 'loading-screen',
  Match = 'match-screen',
  Background = 'background-screen',
}

export type TScreens = GameScreen | LoadingScreen | MatchScreen | BackgroundScreen;

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);
class Producer {
  private containerGame: HTMLDivElement | null = null;
  private app: Application<ICanvas> | null = null;
  private width: number = 0;
  private height: number = 0;
  private screens: Map<ScreenId, TScreens> = new Map();

  get getApp(): Application<ICanvas> | null {
    return this.app;
  }

  get getSizeApp(): IPointData {
    if (!this?.app) {
      return {
        x: 0,
        y: 0,
      };
    }

    return {
      x: this.app.screen.width,
      y: this.app.screen.height,
    };
  }

  /**
   * Монтирование canvas в приложение
   * @param containerGame контейнер для игры
   */
  bindCanvas(containerGame: HTMLDivElement) {
    this.containerGame = containerGame;

    this.resize();

    const devicePixelRatio = window.devicePixelRatio || 1;

    this.app = new Application({
      resizeTo: window,
      autoDensity: true,
      autoStart: false,
      antialias: true,
      backgroundAlpha: 0,
      resolution: devicePixelRatio,
    });

    (globalThis as any).__PIXI_APP__ = this.app;

    // window.addEventListener('resize', () => {
    //   this.resize();
    // });

    const view = this.app.view as HTMLCanvasElement;
    view.style.touchAction = 'auto';

    this.app.start();
    this.containerGame.appendChild(view);
    this.app.stage.sortableChildren = true;
  }

  addScreen(sn: TScreens, ZIndex: number = 1) {
    if (this.app === null) return;

    this.screens.set(sn.id, sn);
    sn.zIndex = ZIndex;
    this.app.stage.addChild(sn);
  }

  removeScreen(id: ScreenId) {
    const sn = this.screens.get(id);
    if (this.app === null || !sn) return;

    this.app?.stage.removeChild(sn);
    this.screens.delete(id);
  }

  showScreen(id: ScreenId) {
    const sn = this.screens.get(id);
    if (!sn) return;

    sn.show();
  }

  hiddenScreen(id: ScreenId) {
    const sn = this.screens.get(id);
    if (!sn) return;

    sn.hidden();
  }

  /**
   * Получение экрана
   * @param id идентификатор экрана
   * @returns
   */
  getScreen<T = TScreens>(id: ScreenId): T | null {
    return (this.screens.get(id) as T) ?? null;
  }

  // Перерасчет размеров
  resize() {
    if (!this.containerGame) return;

    this.width = this.containerGame.clientWidth;
    this.height = this.containerGame.clientHeight;
    // this.screens.forEach((screen) => {
    //   screen.adaptive();
    // });
  }

  createSceneMask(view: PIXI.Container) {
    const mask = new Graphics();
    mask.beginFill(0x000000);
    mask.drawRect(0, 0, ProducerCore.getSizeApp.x, ProducerCore.getSizeApp.y);
    mask.endFill();
    view.addChild(mask);
    view.mask = mask;
  }
  destroyed() {
    this.app = null;
    this.containerGame = null;
  }
}

export const ProducerCore = new Producer();
