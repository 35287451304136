import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ETaskCustomType } from '@shared/types/taskList.types';

export interface IRewardsModal {
  isOpen?: boolean;
  title?: string;
  btnText?: string;
  description?: string;
  baits?: number;
  coins?: number;
  isClear?: boolean;
  /** ETaskCustomType для frontend заданий, string (taskId) для ext названий */
  taskType: ETaskCustomType | string | undefined;
}
interface TaskState {
  rewardsModal: IRewardsModal;
}

const initState: TaskState = {
  rewardsModal: {
    isOpen: false,
    title: '',
    btnText: '',
    description: '',
    baits: 0,
    coins: 0,
    taskType: undefined,
  },
};

export const taskSlice = createSlice({
  name: 'taskSlice',
  initialState: initState,
  selectors: {
    selectRewardsModal: (state: TaskState) => state.rewardsModal,
  },
  reducers: {
    setRewardsModal: (state, action: PayloadAction<IRewardsModal>) => {
      state.rewardsModal = action.payload;
      action.payload?.isClear && (state.rewardsModal = initState.rewardsModal);
    },
  },
});

export const { setRewardsModal } = taskSlice.actions;
export const { selectRewardsModal } = taskSlice.selectors;
