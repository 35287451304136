import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';

import { ResultModalPersonageFishGone } from '@pages/fishing/widgets/ResultModals/ui/ResultModalPersonageFishGone';
import { ResultModalPersonageYourCatch } from '@pages/fishing/widgets/ResultModals/ui/ResultModalPersonageYourCatch';
import { ResultModalRadial } from '@pages/fishing/widgets/ResultModals/ui/ResultModalRadial';
import { ResultModalRays } from '@pages/fishing/widgets/ResultModals/ui/ResultModalRays';
import { ResultModalStarts } from '@pages/fishing/widgets/ResultModals/ui/ResultModalStars';
import { ResultModalTitle } from '@pages/fishing/widgets/ResultModals/ui/ResultModalTitle';
import { CoinIcon } from '@shared/assets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import {
  EFishingResultModals,
  setIsCloseResultModals,
  setViewFishingResultModals,
} from '@shared/store/slice/FishingSlice';
import { useAppDispatch } from '@shared/store/store';
import { Button } from '@shared/ui';
import { Modal } from '@shared/widgets';

interface Props {
  viewFishGone: boolean;
  viewYourCatch: boolean;
  rewardAmount: number;
}
export const ResultModals = ({ viewFishGone, viewYourCatch, rewardAmount }: Props) => {
  const { t } = useTranslation();
  const { useAnimScale } = useAnimateSpring();
  const dispatch = useAppDispatch();

  const toggleModal = (modal: EFishingResultModals, value: boolean) => {
    dispatch(setViewFishingResultModals({ modal, value }));
    !value && dispatch(setIsCloseResultModals(true));
  };

  return (
    <>
      <Modal
        isOpen={viewFishGone}
        isFullScreen={true}
        isOverlay={true}
        // onClose={() => toggleModal(EFishingResultModals.fisGone, !viewFishGone)}
      >
        <animated.div
          style={useAnimScale(viewFishGone)}
          className="w-full h-full flex flex-col justify-center items-center gap-y-2"
        >
          <div className="w-full h-full absolute z-10 flex flex-col items-center justify-end py-[80px]">
            <div className="w-full px-[30px]">
              <Button size="lg" theme="gray" onClick={() => toggleModal(EFishingResultModals.fisGone, !viewFishGone)}>
                {t('global:noProblem')}
              </Button>
            </div>
          </div>
          {viewFishGone && (
            <div className="relative w-full h-full flex justify-center items-center">
              <ResultModalRadial fishGone={true} className="radial absolute" />
              <ResultModalRays fishGone={true} className="rays absolute" />
              <ResultModalPersonageFishGone className="personage-fish-gone absolute translate-x-[6%] translate-y-[12%] scale-[0.9]" />
              <ResultModalTitle
                title={t('fishing:modal.fishGone.title')}
                className="title absolute translate-y-[-220%]"
                fishGone={true}
              />
            </div>
          )}
        </animated.div>
      </Modal>

      <Modal
        isOpen={viewYourCatch}
        isFullScreen={true}
        isOverlay={true}
        // onClose={() => toggleModal(EFishingResultModals.yourCatch, !viewYourCatch)}
      >
        <animated.div
          style={useAnimScale(viewYourCatch)}
          className="w-full h-full flex flex-col justify-center items-center gap-y-2"
        >
          <div className="w-full h-full absolute z-10 flex flex-col items-center justify-end py-[80px]">
            <div className="w-full px-[30px] flex flex-col items-center gap-y-[14px]">
              <div className="max-w-[240px] w-full rounded-30 bg-black/20 flex items-center justify-center gap-x-[14px] p-[10px]">
                <CoinIcon className="w-[54px] h-[61px]" />
                <span id="referAnimateBalance" className="text-primary-purpleLight font-bold text-[40px]">
                  {rewardAmount ?? 0}
                </span>
              </div>
              <Button size="lg" onClick={() => toggleModal(EFishingResultModals.yourCatch, !viewYourCatch)}>
                {t('global:continue')}
              </Button>
            </div>
          </div>
          {viewYourCatch && (
            <div className="relative w-full h-full flex justify-center items-center">
              <ResultModalRadial fishGone={false} className="radial absolute" />
              <ResultModalRays fishGone={false} className="rays absolute" />
              {/* <ResultModalHook fishGone={false} className="hook absolute translate-y-[-55%] translate-x-[10%]" /> */}
              <ResultModalStarts className="starts absolute translate-y-[-5%]" />
              <ResultModalPersonageYourCatch className="personage-your-catch absolute translate-y-[6%] translate-x-[5%]" />
              {/* <ResultModalYourCatch className="your-catch absolute translate-y-[6%] translate-x-[10%]" /> */}
              <ResultModalTitle
                title={t('fishing:modal.yourCatch.title')}
                className="absolute translate-y-[-220%]"
                fishGone={false}
              />
            </div>
          )}
        </animated.div>
      </Modal>
    </>
  );
};
