import { useState } from 'react';

import { LeaderboardLeague } from '@hf/shared-common';

import { LeaderBoardConfig } from '@pages/leaderBoard/configs/LeaderBoard.config';
import { IMAGE } from '@shared/assets';
import { Image } from '@shared/ui';

interface Props {
  league: LeaderboardLeague;
  onLoadingImage?: (loading: boolean) => void;
}
export const LeaderBoardHeaderPersonages = ({ league, onLoadingImage }: Props) => {
  const { leaguePersonages } = LeaderBoardConfig();
  const [loading, setLoading] = useState(true);
  return (
    <div className="w-full h-[190px] relative flex justify-center items-center">
      <div className="absolute z-[1] w-full h-full flex justify-center items-center">
        {!loading && (
          <>
            <div className="absolute w-[120px] h-[120px] top-[10px] bg-radialWhiteCircle rounded-full opacity-15 "></div>
            <div className="absolute w-[135px] h-[65px] top-[75px] bg-radialWhiteCircle rounded-full opacity-15"></div>
          </>
        )}
        <div
          className={`absolute w-[500px]  h-[300px] ${leaguePersonages[league].leftRadial} rounded-full opacity-20`}
        ></div>
      </div>
      <div className={`absolute z-10 full-absolute flex justify-center items-center max-w-[340px] mx-auto`}>
        <Image src={IMAGE.BACKGROUNDS.PEPE_COINS} className="!absolute full-absolute " />
        <Image
          src={leaguePersonages[league].image}
          className={`w-full min-h-[190px] h-auto flex justify-center items-center`}
          onLoading={(loading) => {
            setLoading(loading);
            onLoadingImage && onLoadingImage(loading);
          }}
        />
      </div>
    </div>
  );
};
