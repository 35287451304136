import { FISHING_COUNT_TO_TAP } from '@hf/shared-common';

import { EmitterConfigV3 } from '@pixi/particle-emitter';
import { gameScreenAssets } from '@shared/configs/assets.config';
import { getValue } from '@shared/utils/helpers.utils';

const maxParticles = +getValue<number>(import.meta.env.VITE_APP_FISHING_COUNT_TO_TAP, FISHING_COUNT_TO_TAP);

export const emitterCoinConfig: EmitterConfigV3 = {
  lifetime: {
    min: 0.8,
    max: 0.8,
  },
  frequency: 0.008,
  spawnChance: 1,
  particlesPerWave: 1,
  emitterLifetime: 0.41,
  maxParticles: maxParticles,
  pos: {
    x: 0,
    y: 0,
  },
  autoUpdate: true,
  addAtBack: false,
  behaviors: [
    {
      type: 'alpha',
      config: {
        alpha: {
          list: [
            {
              value: 1,
              time: 0,
            },
            {
              value: 0.1,
              time: 1,
            },
          ],
        },
      },
    },
    {
      type: 'scale',
      config: {
        scale: {
          list: [
            {
              value: 0.1,
              time: 0,
            },
            {
              value: 0.05,
              time: 1,
            },
          ],
        },
      },
    },
    {
      type: 'moveSpeed',
      config: {
        speed: {
          list: [
            {
              value: 300,
              time: 0,
            },
            {
              value: 150,
              time: 1,
            },
          ],
          isStepped: false,
        },
      },
    },
    {
      type: 'rotationStatic',
      config: {
        min: 0,
        max: 10,
      },
    },
    {
      type: 'animatedRandom',
      config: {
        anims: [
          {
            framerate: 30,
            loop: true,
            textures: [
              'coin_0001.png',
              'coin_0002.png',
              'coin_0003.png',
              'coin_0004.png',
              'coin_0005.png',
              'coin_0006.png',
              'coin_0007.png',
              'coin_0008.png',
              'coin_0009.png',
              'coin_0010.png',
              'coin_0011.png',
              'coin_0012.png',
              'coin_0013.png',
              'coin_0014.png',
              'coin_0015.png',
              'coin_0016.png',
              'coin_0017.png',
              'coin_0018.png',
              'coin_0019.png',
              'coin_0020.png',
              'coin_0021.png',
              'coin_0022.png',
              'coin_0023.png',
              'coin_0024.png',
              'coin_0025.png',
              'coin_0026.png',
              'coin_0027.png',
              'coin_0028.png',
              'coin_0029.png',
              'coin_0030.png',
            ],
          },
          {
            framerate: 30,
            loop: true,
            textures: [
              'coin_0030.png',
              'coin_0029.png',
              'coin_0028.png',
              'coin_0027.png',
              'coin_0026.png',
              'coin_0025.png',
              'coin_0024.png',
              'coin_0023.png',
              'coin_0022.png',
              'coin_0021.png',
              'coin_0020.png',
              'coin_0019.png',
              'coin_0018.png',
              'coin_0017.png',
              'coin_0016.png',
              'coin_0015.png',
              'coin_0014.png',
              'coin_0013.png',
              'coin_0012.png',
              'coin_0011.png',
              'coin_0010.png',
              'coin_0009.png',
              'coin_0008.png',
              'coin_0007.png',
              'coin_0006.png',
              'coin_0005.png',
              'coin_0004.png',
              'coin_0003.png',
              'coin_0002.png',
              'coin_0001.png',
            ],
          },
        ],
      },
    },
    {
      type: 'spawnShape',
      config: {
        type: 'torus',
        data: {
          x: 0,
          y: 0,
          radius: 40,
          innerRadius: 39,
          affectRotation: true,
        },
      },
    },
  ],
};
export const emitterBubblesButtonConfig: EmitterConfigV3 = {
  lifetime: {
    min: 0.8,
    max: 1.2,
  },
  frequency: 0.3,
  emitterLifetime: -1,
  spawnChance: 1,
  maxParticles: 8,
  autoUpdate: true,
  addAtBack: false,
  pos: {
    x: 0,
    y: 0,
  },
  behaviors: [
    {
      type: 'alpha',
      config: {
        alpha: {
          list: [
            {
              time: 0,
              value: 1,
            },
            {
              time: 1,
              value: 0.16,
            },
          ],
        },
      },
    },
    {
      type: 'moveSpeed',
      config: {
        speed: {
          list: [
            {
              time: 0,
              value: 200,
            },
            {
              time: 1,
              value: 100,
            },
          ],
        },
      },
    },
    {
      type: 'scale',
      config: {
        scale: {
          list: [
            {
              time: 0,
              value: 0.25,
            },
            {
              time: 1,
              value: 0.45,
            },
          ],
        },
        minMult: 0.5,
      },
    },
    {
      type: 'rotation',
      config: {
        accel: 0,
        minSpeed: 0,
        maxSpeed: 20,
        minStart: -75, // 267
        maxStart: -115, // 273
      },
    },
    {
      type: 'textureRandom',
      config: {
        textures: [gameScreenAssets.urls.bubble, gameScreenAssets.urls.bubbleMini],
      },
    },
    {
      type: 'spawnPoint',
      config: {},
    },
  ],
};

export const emitterBubbleStreamConfig: EmitterConfigV3 = {
  lifetime: {
    min: 0.2,
    max: 0.3,
  },
  frequency: 0.1,
  emitterLifetime: 0.2,
  spawnChance: 1,
  maxParticles: 1,
  autoUpdate: true,
  addAtBack: false,
  pos: {
    x: 0,
    y: 0,
  },
  behaviors: [
    {
      type: 'alpha',
      config: {
        alpha: {
          list: [
            {
              time: 0,
              value: 1,
            },
            {
              time: 1,
              value: 0.16,
            },
          ],
        },
      },
    },
    {
      type: 'moveSpeed',
      config: {
        speed: {
          list: [
            {
              time: 0,
              value: 300,
            },
            {
              time: 1,
              value: 200,
            },
          ],
        },
      },
    },
    {
      type: 'scale',
      config: {
        scale: {
          list: [
            {
              time: 0,
              value: 0.3,
            },
            {
              time: 1,
              value: 0.6,
            },
          ],
        },
        minMult: 0.5,
      },
    },
    {
      type: 'rotation',
      config: {
        accel: 0,
        minSpeed: 0,
        maxSpeed: 20,
        minStart: -90, // 267
        maxStart: -100, // 273
      },
    },
    {
      type: 'textureRandom',
      config: {
        textures: [gameScreenAssets.urls.bubble, gameScreenAssets.urls.bubbleMini],
      },
    },
    {
      type: 'spawnPoint',
      config: {},
    },
  ],
};
