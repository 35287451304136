import { useTranslation } from 'react-i18next';

import { IMAGE } from '@shared/assets';

export const ContentNoTelegramPage = () => {
  const nameBot = import.meta.env.VITE_APP_INFO_LINK_TELEGRAM_BOT.replace('https://t.me/', '@');
  const { t } = useTranslation();
  const getQr = () => {
    switch (import.meta.env.VITE_APP_MODE) {
      case 'dev':
        return IMAGE.QR.BOT_DEV;
      case 'stage':
        return IMAGE.QR.BOT_STAGE;
      case 'prod':
        return IMAGE.QR.BOT_PROD;
    }
  };
  const qrImage = getQr();

  return (
    <div className="flex flex-col items-center gap-y-4">
      <span className="text-[20px] font-bold">{t('error:noTelegram.client')}</span>
      <img className="w-[240px] h-auto rounded-20" src={qrImage} />
      <a className="text-[16px] font-bold" href={import.meta.env.VITE_APP_INFO_LINK_TELEGRAM_BOT}>
        {nameBot}
      </a>
    </div>
  );
};
