import { TabsItem } from '@shared/ui/Tabs/TabsItem';

export type TTabsSize = 'sm' | 'md' | 'lg';
export type TTabsTheme = 'purple';

export interface TTabsItemOption {
  key: number;
  value: string | number;
  label: string | React.ReactNode;
  disabled?: boolean;
}
interface Props {
  className?: string;
  theme?: TTabsTheme;
  size?: TTabsSize;
  disabled?: boolean;

  loading?: boolean;
  options?: TTabsItemOption[];
  selected?: string | number;
  onChange: (key: number, value: TTabsItemOption['value']) => void;
}

export const Tabs = ({
  className,
  theme = 'purple',
  size = 'lg',
  disabled,
  loading,
  options,
  selected,
  onChange,
}: Props) => {
  const getThemeStyle = (): {
    themeStyle: string;
    textStyle: string;
  } => {
    switch (theme) {
      case 'purple':
        return {
          themeStyle: `bg-black/40`,
          textStyle: `text-white`,
        };
      default:
        return {
          themeStyle: '',
          textStyle: '',
        };
    }
  };

  const getSizeStyle = (): { sizeStyle: string } => {
    switch (size) {
      case 'lg':
        return { sizeStyle: 'h-[34px] text-[15px]' };
      case 'md':
        return { sizeStyle: 'h-[28px] text-[12px]' };
      case 'sm':
        return { sizeStyle: 'h-[22px] text-[12px]' };
    }
  };

  const { themeStyle, textStyle } = getThemeStyle();
  const { sizeStyle } = getSizeStyle();

  return (
    <>
      <div
        className={`w-full  rounded-10 flex ${disabled ? 'pointer-events-none opacity-30' : ''} ${sizeStyle} ${themeStyle} ${textStyle} ${className ?? ''}`}
      >
        <div className="w-full h-full flex">
          {options &&
            options.map(({ key, value, label, disabled }) => {
              return (
                <TabsItem
                  key={key}
                  keyItem={key}
                  value={value}
                  label={label}
                  disabled={disabled}
                  theme={theme}
                  size={size}
                  active={selected === value}
                  className="flex justify-center items-center"
                  clickHandler={(key: number, value: TTabsItemOption['value']) => {
                    if (disabled) return;
                    onChange(key, value);
                  }}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};
