import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { TTabsItemOption, TTabsSize, TTabsTheme } from '@shared/ui/Tabs/Tabs';

type TWidth = 'w-1/2' | 'w-1/3';
interface Props {
  className?: string;
  disabled?: boolean;
  width?: TWidth;
  theme: TTabsTheme;
  sound?: {
    samples: ESamples;
    volume?: number;
    disabled?: boolean;
  };
  size: TTabsSize;
  keyItem: number;
  value: TTabsItemOption['value'];
  label: TTabsItemOption['label'];
  active: boolean;
  clickHandler: (key: number, value: TTabsItemOption['value']) => void;
}
export const TabsItem = ({
  className,
  disabled,
  theme,
  sound = { samples: ESamples.buttonClick },
  width = 'w-1/2',
  size,
  keyItem,
  value,
  label,
  active,
  clickHandler,
}: Props) => {
  const { vibration } = useUtils();
  const { samplePlay } = useAudio();

  const getThemeStyle = (): {
    selectedStyle: string;
    unSelectedStyle: string;
  } => {
    switch (theme) {
      case 'purple':
        return {
          selectedStyle: 'bg-primary-purpleDark ',
          unSelectedStyle: '',
        };
      default:
        return {
          selectedStyle: '',
          unSelectedStyle: '',
        };
    }
  };
  const getSizeStyle = (): { sizeText: string } => {
    switch (size) {
      case 'lg':
        return { sizeText: 'text-[15px]' };
      case 'md':
        return { sizeText: 'text-[12px]' };
      case 'sm':
        return { sizeText: 'text-[12px]' };
    }
  };
  const { sizeText } = getSizeStyle();
  const { selectedStyle, unSelectedStyle } = getThemeStyle();
  return (
    <>
      <div
        className={`${width} h-full rounded-10 ${sizeText} ${disabled ? 'pointer-events-none opacity-30' : ''} transition-colors ${active ? selectedStyle : unSelectedStyle} font-bold ${className ?? ''}`}
        onClick={() => {
          if (disabled || active) return;
          vibration('selectionChanged');
          sound && !sound?.disabled && sound?.samples && samplePlay(sound.samples, sound?.volume ?? undefined);
          clickHandler(keyItem, value);
        }}
      >
        {label}
      </div>
    </>
  );
};
