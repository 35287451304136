import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import MatchScreen from 'src/pixi/screens/MatchScreen';

import { MultiplayerNomination, TOTAL_ROUNDS } from '@hf/shared-common';

import {
  ArrowTopIcon,
  BurgerMenuIcon,
  IMAGE,
  MaxFishWeightIcon,
  QuantityFishIcon,
  StatDownIcon,
  StatUpIcon,
  SumFishWeightIcon,
} from '@shared/assets';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { IMatchLifeTable, IMatchLifeTableItem } from '@shared/types/games.type';
import { NBSP } from '@shared/utils/helpers.utils';

interface IProps {
  className?: string;
  list: IMatchLifeTable;
  me: IMatchLifeTable<IMatchLifeTableItem>;
  playersReady: { current: number; max: number };
  roundLeft: number;
}
interface IRowItem {
  placeIncreased: boolean;
  userName: string;
  score: number;
  className?: string;
}

interface IRow {
  isMe: boolean;
  isHeader?: boolean;
  place: number;
  nominations: {
    node1: React.ReactNode;
    node2: React.ReactNode;
    node3: React.ReactNode;
  };
  className?: string;
}
export const MatchLifeLeaderboard = ({ className, list, me, playersReady, roundLeft }: IProps) => {
  const { t } = useTranslation();
  const [isOpenList, setIsOpenList] = useState(false);
  const matchSc = useRef<MatchScreen | null>(null);
  const { getLightweightUserName, vibration } = useUtils();
  const { samplePlay } = useAudio();

  useEffect(() => {
    matchSc.current = ProducerCore.getScreen<MatchScreen>(ScreenId.Match);
  }, []);

  const leftBorderRowItem =
    'before:absolute before:left-[-2px] before:w-[2px] before:top-0 before:h-full before:bg-white/80';
  const leftBorderExtraRowItem =
    'before:absolute before:left-[-2px] before:w-[2px] before:top-0 before:h-full before:bg-linerWhileLifeTable';
  const onToggleList = useCallback(() => {
    setIsOpenList((prev) => {
      samplePlay(ESamples.buttonClick);
      vibration('selectionChanged');
      matchSc.current && matchSc.current.moveToBottom(!prev);
      return !prev;
    });
  }, []);

  const prizeRowClass: Record<number, string> = {
    1: 'bg-[#EBA900]/40 border-white/20',
    2: 'bg-[#D9D8D8]/40 border-white/20',
    3: 'bg-[#F28300]/40 border-white/20',
  };

  const RowItem = ({ placeIncreased, userName, score, className }: IRowItem) => {
    return (
      <div className={`relative flex justify-between p-[5px] ${className ?? ''}`}>
        <span>{getLightweightUserName(userName, 8, '')}</span>
        <div className="flex items-center gap-x-[1px]">
          <span>{placeIncreased ? <StatUpIcon width={8} /> : <StatDownIcon width={8} />}</span>
          <span>{score}</span>
        </div>
      </div>
    );
  };

  const Row = ({ isMe, place, nominations, className, isHeader }: IRow) => {
    const { node1, node2, node3 } = nominations;
    return (
      <div
        className={`w-full grid grid-cols-[30px_calc((100%-30px)/3)_calc((100%-30px)/3)_calc((100%-30px)/3)]  text-[12px] ${isHeader ? 'bg-center bg-no-repeat bg-cover' : 'border-t border-white/80'}   ${className ?? ''}`}
        style={isHeader ? { backgroundImage: `url(${IMAGE.LIFE_TABLE_HEADER})` } : {}}
      >
        <div className="flex items-center p-[5px]">{isHeader ? '' : isMe ? t('global:me') : `#${place}`}</div>
        {node1}
        {node2}
        {node3}
      </div>
    );
  };
  const {
    [MultiplayerNomination.SUM_WEIGHT]: sumWeight,
    [MultiplayerNomination.QUANTITY]: quantity,
    [MultiplayerNomination.WEIGHT]: maxWeight,
  } = me;

  return (
    <>
      <div className={`w-full ${className ?? ''}`}>
        <Row
          isMe={false}
          place={0}
          isHeader={true}
          nominations={{
            node1: (
              <div
                className={`h-full relative flex items-center justify-center ${leftBorderExtraRowItem} before:h-[85%] before:bottom-0 before:top-auto before:rotate-180`}
              >
                <SumFishWeightIcon width={48} />
              </div>
            ),
            node2: (
              <div
                className={`h-full relative flex items-center justify-center ${leftBorderExtraRowItem} before:h-[85%] before:bottom-0 before:top-auto before:rotate-180`}
              >
                <QuantityFishIcon width={48} />
              </div>
            ),
            node3: (
              <div
                className={`h-full relative  flex items-center justify-center ${leftBorderExtraRowItem} before:h-[85%] before:bottom-0 before:top-auto before:rotate-180`}
              >
                <MaxFishWeightIcon width={48} />
              </div>
            ),
          }}
        />
        <div
          className={`bg-secondary-grayBlue/80 flex flex-col overflow-hidden overflow-y-auto  no-scroll transition-all ${isOpenList ? 'max-h-[calc(29px*5)]' : 'max-h-0'}`}
        >
          {list[MultiplayerNomination.SUM_WEIGHT].map(({ placeIncreased, place, score, userName }, index) => {
            const quantity = list[MultiplayerNomination.QUANTITY][index];
            const maxWeight = list[MultiplayerNomination.WEIGHT][index];

            return (
              <Row
                className={`${prizeRowClass[place] ?? ''}`}
                key={index}
                isMe={false}
                place={place}
                nominations={{
                  node1: (
                    <RowItem
                      placeIncreased={placeIncreased}
                      userName={`@${userName}`}
                      score={score}
                      className={`${leftBorderRowItem}`}
                    />
                  ),
                  node2: (
                    <RowItem
                      placeIncreased={quantity.placeIncreased}
                      userName={`@${quantity.userName}`}
                      score={quantity.score}
                      className={`${leftBorderRowItem}`}
                    />
                  ),
                  node3: (
                    <RowItem
                      placeIncreased={maxWeight.placeIncreased}
                      userName={`@${maxWeight.userName}`}
                      score={maxWeight.score}
                      className={`${leftBorderRowItem}`}
                    />
                  ),
                }}
              />
            );
          })}
        </div>
        <Row
          className="bg-secondary-grayBlue/80"
          isMe={true}
          place={0}
          nominations={{
            node1: (
              <RowItem
                placeIncreased={sumWeight.placeIncreased}
                userName={`#${sumWeight.place}`}
                score={sumWeight.score}
                className={`${leftBorderExtraRowItem}`}
              />
            ),
            node2: (
              <RowItem
                placeIncreased={quantity.placeIncreased}
                userName={`#${quantity.place}`}
                score={quantity.score}
                className={`${leftBorderExtraRowItem}`}
              />
            ),
            node3: (
              <RowItem
                placeIncreased={maxWeight.placeIncreased}
                userName={`#${maxWeight.place}`}
                score={maxWeight.score}
                className={`${leftBorderExtraRowItem}`}
              />
            ),
          }}
        />
        <div className="h-[26px] flex justify-between font-bold">
          <div className="flex justify-start items-center gap-x-[10px] text-[10px] px-[6px] bg-secondary-grayBlue/80 rounded-b-10">
            <span>
              {t('global:playersReady')}
              {NBSP} {playersReady.current}/{playersReady.max}
            </span>
            <span>
              {t('global:roundsLeft')}
              {NBSP} {roundLeft}/{TOTAL_ROUNDS}
            </span>
          </div>
          <div
            className="w-[60px] h-full bg-secondary-grayBlue/80 rounded-b-10 flex justify-center items-center p-[6px]"
            onClick={onToggleList}
          >
            {isOpenList ? <ArrowTopIcon width={18} /> : <BurgerMenuIcon width={18} />}
          </div>
        </div>
      </div>
    </>
  );
};
