import { useEffect, useState } from 'react';

export const useCountdown = (onComplete?: () => void) => {
  let intervalId: NodeJS.Timeout;

  const [timeLeft, setTimeLeft] = useState('');
  const [targetDate, setTargetDate] = useState<number>(0);

  const updateCountdown = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;
    if (difference <= 0) {
      setTimeLeft('');
      if (onComplete) onComplete();
      clearInterval(intervalId);
      return;
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    const formattedTime = [
      hours > 0 ? `${hours}h` : null,
      minutes > 0 ? `${formatTime(minutes)}m` : null,
      `${formatTime(seconds)}s`,
    ]
      .filter(Boolean)
      .join(':');

    setTimeLeft(formattedTime);
  };
  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  useEffect(() => {
    intervalId = setInterval(updateCountdown, 1000);

    updateCountdown();

    return () => clearInterval(intervalId);
  }, [targetDate]);
  return { timeLeft, setTargetDate };
};
