import { useEffect, useRef, useState } from 'react';

import { Loader } from '@shared/ui/Loader/Loader';

interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  isLoader?: boolean;
  className?: string;
  src?: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  classNameVideo?: string;
  onLoadedData?: () => void;
}

export const Video = ({
  isLoader = true,
  className,
  src,
  autoPlay = true,
  muted = true,
  loop = true,
  classNameVideo,
  onLoadedData,
  ...props
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [playAttempted, setPlayAttempted] = useState(false);
  const [autoplayFailed, setAutoplayFailed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = async () => {
    const video = videoRef.current;
    if (!video) return;

    try {
      video.muted = true;
      await video.play();
      if (!muted) {
        video.muted = false;
      }
      setAutoplayFailed(false);
    } catch (error) {
      console.warn('Video playback failed:', error);
      setAutoplayFailed(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    if (autoPlay && !playAttempted) {
      setPlayAttempted(true);
      playVideo();
    }

    const handleVisibilityChange = () => {
      const video = videoRef.current;
      if (!video) return;

      if (document.hidden) {
        video.pause();
      } else if (autoPlay && !autoplayFailed) {
        video.play().catch((error) => {
          console.warn('Video resume failed:', error);
          setAutoplayFailed(true);
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [videoRef, autoPlay, muted, playAttempted, autoplayFailed]);

  const handlePlayClick = () => {
    playVideo();
  };

  return (
    <>
      <div className={`w-full h-auto relative overflow-hidden ${className ?? ''}`}>
        {isLoader && isLoading && <Loader classNameByFull="bg-primary-grayBlue/30" />}
        {autoplayFailed && (
          <button
            onClick={handlePlayClick}
            className="absolute inset-0 z-10 flex items-center justify-center bg-black/50 hover:bg-black/60 transition-colors"
            aria-label="Play video"
          >
            <svg
              className="w-16 h-16 text-white"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        )}
        <video
          ref={videoRef}
          className={`w-full h-auto ${classNameVideo ?? ''}`}
          muted={muted}
          preload="metadata"
          loop={loop}
          playsInline
          {...props}
          onPlaying={() => {
            setIsLoading(false);
            onLoadedData?.();
          }}
          onWaiting={() => {
            setIsLoading(true);
          }}
          onError={(e) => {
            const videoElement = e.currentTarget as HTMLVideoElement;
            console.error('Video error:', {
              currentSrc: videoElement.currentSrc,
              networkState: videoElement.networkState,
              readyState: videoElement.readyState,
              error: videoElement.error?.message,
              code: videoElement.error?.code,
            });
            setIsLoading(false);
            setAutoplayFailed(true);
          }}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </>
  );
};
