import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AmplitudeEventClient } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { FriendList } from '@pages/friends/widgets/FriendList';
import { Guide } from '@pages/friends/widgets/Guide';
import { referralAPI } from '@shared/api/referral';
import { FriendsIllustration } from '@shared/assets';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { Button, Wrapper } from '@shared/ui';
import { Loader } from '@shared/ui/Loader/Loader';
import { openTelegramLink } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';

export const FriendsPage = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { ga4Event } = useGa4();
  const { data: refInfo, isLoading: isLoadingRefInfo } = referralAPI.useReferralInfoQuery();
  const { data: linkInfo, isLoading: isLoadingLinkInfo } = referralAPI.useReferralLinkQuery();

  const onToggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    amplitude.track(AmplitudeEventClient.ReferralOpened);
  }, []);

  const onSendLink = useCallback(() => {
    if (linkInfo?.link) {
      ga4Event(EGa4Event.InviteSent, EGa4Category.Earn);

      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        linkInfo?.link,
      )}&text=Join%20now%20using%20my%20referral%20link!`;
      setTimeout(() => {
        openTelegramLink(telegramUrl);
        amplitude.track(AmplitudeEventClient.FriendInviteLinkSent, { referral_code: linkInfo?.link });
      }, 300);
    }
  }, [linkInfo?.link]);

  const onCopyLink = useCallback(() => {
    if (linkInfo?.link) {
      ga4Event(EGa4Event.InviteCopied, EGa4Category.Earn);
      amplitude.track(AmplitudeEventClient.FriendInviteLinkCopied, { referral_code: linkInfo?.link });
      navigator.clipboard
        .writeText(linkInfo?.link)
        .then(() => {
          toast.success(t('friends:clipboard.message'));
          onToggleModal();
        })
        .catch((err) => {
          console.error(`Error copying link: ${err}`);
          if (err) toast.error('Failed to copy the link: ', err);
        });
    }
  }, [linkInfo?.link]);

  return (
    <>
      <div className="h-full flex flex-col gap-[10px] text-blue-light">
        <Wrapper>
          <div className="flex flex-col items-center  py-[5px]">
            <FriendsIllustration className="w-8/12 h-auto" />
            <p className=" font-bold text-[18px] text-center">{t('friends:points.title')}</p>
          </div>
        </Wrapper>

        <div className="flex flex-col h-[calc(100%-245px)]">
          {isLoadingRefInfo ? (
            <div className="h-full w-full flex items-center justify-center">
              <Loader className="mx-auto" />
            </div>
          ) : refInfo && refInfo?.list.length !== 0 ? (
            <FriendList />
          ) : (
            <Guide />
          )}
        </div>
        <div className="flex h-[45px] items-end justify-center my-[0.5rem] ">
          <Button
            size="lg"
            className="bg-opacity-80"
            disabled={isLoadingLinkInfo}
            onClick={onToggleModal}
            sound={{ samples: ESamples.buttonClick, disabled: isLoadingLinkInfo }}
          >
            {refInfo?.left ? t('friends:button.invite', { count: refInfo?.left }) : t('friends:button.invited')}
          </Button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} title={t('friends:modal.title')} onClose={onToggleModal}>
        <div className="flex flex-col gap-[10px]">
          <Button size="lg" onClick={onSendLink} vibrationOpt={{ func: 'notificationOccurred', style: 'success' }}>
            {t('global:send')}
          </Button>
          <Button size="lg" onClick={onCopyLink} sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('global:copyLink')}
          </Button>
          <Button size="lg" theme="gray" onClick={onToggleModal}>
            {t('global:close')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
