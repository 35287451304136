import { gsap } from 'gsap';
import { AnimatedSprite, Assets, Container } from 'pixi.js';

import { matchScreenAssets } from '@shared/configs/assets.config';

export default class UiExplosion {
  private _view: Container | null = null;
  private _explosionAnimateSprite: AnimatedSprite | null = null;
  private _isDestroyed: boolean = false;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'Explosion';
    }
    return this._view;
  }

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    try {
      this.initExplosion();
      this.adaptive();
      this.view.alpha = 0;
    } catch (error) {
      console.error('Failed to initialize explosion:', error);
    }
  }

  private initExplosion(): void {
    if (this._isDestroyed) return;

    try {
      const explosionAssets = Assets.cache.get(matchScreenAssets.urls.explosion);
      if (!explosionAssets?.data?.animations) {
        console.error('Explosion assets not found in cache');
        return;
      }

      const animations = explosionAssets.data.animations;
      const explosionFrames = animations['Explosion'];

      if (!explosionFrames) {
        console.error('Explosion animation frames not found');
        return;
      }

      this._explosionAnimateSprite = AnimatedSprite.fromFrames(explosionFrames);

      if (this._explosionAnimateSprite) {
        this._explosionAnimateSprite.animationSpeed = 1;
        this._explosionAnimateSprite.loop = false;

        this._explosionAnimateSprite.onComplete = () => {
          if (this._isDestroyed || !this._explosionAnimateSprite) return;

          this._explosionAnimateSprite.gotoAndPlay(0);
          this._explosionAnimateSprite.stop();

          if (this._view) {
            this._view.alpha = 0;
          }
        };

        this.view.addChild(this._explosionAnimateSprite);
      }
    } catch (error) {
      console.error('Failed to initialize explosion animation:', error);
    }
  }

  play(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      tl.add(() => {
        if (this._isDestroyed) return;

        if (this._view) {
          this._view.alpha = 1;
        }

        if (this._explosionAnimateSprite) {
          this._explosionAnimateSprite.play();
        }
      }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to play explosion animation:', error);
      return gsap.timeline();
    }
  }

  stop(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      if (this._view) {
        tl.to(this._view, { alpha: 0, duration: 0.2 }, 0);
      }

      tl.add(() => {
        if (!this._isDestroyed && this._explosionAnimateSprite) {
          this._explosionAnimateSprite.stop();
        }
      }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to stop explosion animation:', error);
      return gsap.timeline();
    }
  }

  destroy(): boolean {
    if (this._isDestroyed) return true;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      if (this._view) {
        gsap.killTweensOf(this._view);
      }

      // Clean up explosion sprite
      if (this._explosionAnimateSprite) {
        this._explosionAnimateSprite.stop();
        this._explosionAnimateSprite.onComplete = undefined;
        this._explosionAnimateSprite.destroy();
        this._explosionAnimateSprite = null;
      }

      // Clean up view
      if (this._view) {
        this._view.destroy({ children: true });
        this._view = null;
      }

      return true;
    } catch (error) {
      console.error('Failed to destroy explosion:', error);
      return false;
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._explosionAnimateSprite) return;

    try {
      const width = this._explosionAnimateSprite.width;
      const height = this._explosionAnimateSprite.height;

      if (width && height) {
        this._explosionAnimateSprite.pivot.set(width / 2, height / 2);
        this._explosionAnimateSprite.scale.set(0.8);
      }
    } catch (error) {
      console.error('Failed to adapt explosion:', error);
    }
  }
}
