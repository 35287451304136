import gsap from 'gsap';
import { Container, Graphics } from 'pixi.js';
import { ProducerCore } from 'src/pixi/ProducerCore';

import { sleep } from '@shared/utils/helpers.utils';

export default class UiBubbles {
  private _view: Container | null = null;
  private _bubblesContainer: Container | null = null;
  private _bubbles: Graphics[] = [];
  private _background: Graphics | null = null;
  private _isDestroyed: boolean = false;
  private _hasStopped: boolean = false;

  private readonly color = 0x2a4e73;
  private readonly height = 100;
  private readonly radius = 50;
  private readonly width: number;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'Bubbles';
    }
    return this._view;
  }

  get bubblesContainer(): Container {
    if (!this._bubblesContainer) {
      this._bubblesContainer = new Container();
      this._bubblesContainer.name = 'BubblesContainer';
    }
    return this._bubblesContainer;
  }

  set stop(value: boolean) {
    this._hasStopped = value;
    if (value) {
      this._bubbles.forEach((bubble) => {
        if (bubble) {
          gsap.killTweensOf(bubble.scale);
        }
      });
    }
  }

  constructor(width: number) {
    this.width = ProducerCore.getSizeApp.x;

    this.initialize();
  }

  private initialize() {
    try {
      this._background = this.createBackground();
      if (this._background) {
        this.createBubbles();
        this.view.addChild(this._background, this.bubblesContainer);
        this.adaptive();
        this.animateToHeight(this.height);
        this.animateBubbles();
      }
    } catch (error) {
      console.error('Failed to initialize bubbles:', error);
    }
  }

  private createBubbles() {
    if (this._isDestroyed) return;

    try {
      let startX = 0;
      for (let i = 0; i <= Math.round(this.width / this.radius); i++) {
        const circle = this.createCircle();
        if (circle) {
          circle.x = startX;
          circle.scale.set(0.5 + Math.random() * 0.5);
          this.bubblesContainer.addChild(circle);
          startX += this.radius;
          this._bubbles.push(circle);
        }
      }
    } catch (error) {
      console.error('Failed to create bubbles:', error);
    }
  }

  private calculateAnimateBubbles(index: number) {
    const size = 0.8;
    let step = Math.random() * 100;
    const speed = index * 0.1 * Math.random() * 0.5 + 0.5;
    step += index * 0.1 * speed;
    return {
      scale: Math.sin(step) * 0.4 + size,
      delay: Math.random() * 0.5 + 0.5,
    };
  }

  animateToHeight(height: number) {
    if (this._isDestroyed || !this._background || !this._bubblesContainer) {
      return gsap.timeline();
    }

    const tl = gsap.timeline();
    tl.to(
      this._background,
      {
        y: height,
        duration: 1,
        ease: 'power1.inOut',
      },
      0,
    ).to(
      this._bubblesContainer,
      {
        y: height,
        duration: 1,
        ease: 'power1.inOut',
      },
      0,
    );
    return tl;
  }

  animateFullHeight() {
    if (this._isDestroyed || !this._bubblesContainer) {
      return gsap.timeline();
    }

    const tl = gsap.timeline();
    const targetY = ProducerCore.getSizeApp.y;

    tl.add(this.animateToHeight(targetY), 0).to(
      this._bubblesContainer,
      {
        y: targetY + this.height,
        duration: 0.7,
        delay: 1.2,
        ease: 'power1.inOut',
        onComplete: () => {
          if (!this._isDestroyed) {
            this.stop = true;
          }
        },
      },
      '1.2',
    );
    return tl;
  }

  animateBubbles() {
    if (this._isDestroyed) return;

    this._bubbles.forEach((bubble, index) => {
      if (!bubble || this._isDestroyed) return;

      const config = () => {
        const { scale, delay } = this.calculateAnimateBubbles(index);
        const params = {
          y: scale,
          x: scale,
          duration: 0.8,
          repeat: 1,
          yoyo: true,
          ease: 'power1.inOut',
          delay: delay,
        };
        return {
          ...params,
          onRepeat: () => {
            if (this._hasStopped || this._isDestroyed) return;
            const { scale, delay } = this.calculateAnimateBubbles(index);
            if (bubble) {
              gsap.to(bubble.scale, {
                ...params,
                x: scale,
                y: scale,
                delay: delay,
                onRepeat: config().onRepeat,
              });
            }
          },
        };
      };

      gsap.to(bubble.scale, {
        ...config(),
      });
    });
  }

  async reset() {
    if (this._isDestroyed) return;

    this.stop = false;
    this.adaptive();
    this.animateToHeight(this.height);
    await sleep(1200);
    this.animateBubbles();
  }

  private createBackground(): Graphics | null {
    try {
      const background = new Graphics();
      background.beginFill(this.color);
      background.drawRect(0, 0, this.width, ProducerCore.getSizeApp.y);
      background.endFill();
      return background;
    } catch (error) {
      console.error('Failed to create background:', error);
      return null;
    }
  }

  private createCircle(): Graphics | null {
    try {
      const circle = new Graphics();
      circle.beginFill(this.color);
      circle.drawCircle(0, 0, this.radius);
      circle.endFill();
      return circle;
    } catch (error) {
      console.error('Failed to create circle:', error);
      return null;
    }
  }

  adaptive() {
    if (this._isDestroyed) return;

    if (this._background) {
      this._background.y = 0;
    }
    if (this._bubblesContainer) {
      this._bubblesContainer.y = 0;
    }
  }

  destroy() {
    this._isDestroyed = true;
    this.stop = true;

    // Clean up all bubbles
    this._bubbles.forEach((bubble) => {
      if (bubble) {
        gsap.killTweensOf(bubble.scale);
        bubble.destroy();
      }
    });
    this._bubbles = [];

    // Clean up containers and background
    if (this._bubblesContainer) {
      this._bubblesContainer.destroy({ children: true });
      this._bubblesContainer = null;
    }

    if (this._background) {
      this._background.destroy();
      this._background = null;
    }

    if (this._view) {
      this._view.destroy({ children: true });
      this._view = null;
    }
  }
}
