import { Socket } from 'socket.io-client';

import { MatchFinalScores, MatchLeaderboard, MatchLeaderboardPlayer, MultiplayerNomination } from '@hf/shared-common';

import { useUtils } from '@shared/hooks/utils/useUtils';
import { setRemainingBaits } from '@shared/store/slice/MatchSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { store, useAppDispatch, useAppSelector } from '@shared/store/store';
import {
  ELastCoefficient,
  IFinalLeaderBoard,
  IFinalLeaderBoardItem,
  IMatchLifeTable,
  IMatchLifeTableItem,
} from '@shared/types/games.type';
import { EKeyStorage } from '@shared/types/storage.types';
import { getLocalStorage, setLocalStorage } from '@shared/utils/localStorage.storage';

type RestorePlaceItem = { index: number; increased: boolean };
type RestorePlace = Map<number, RestorePlaceItem>;

let lastBaits = true;
export const useMatchUtils = () => {
  const { getUserName } = useUtils();
  const profile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  // Проверяем можем ли списать деньги с баланса
  const checkPayBet = (value: number): boolean => {
    const profile = store.getState().userSlice.userProfile;
    if (profile === undefined) return false;

    const { balance } = profile;
    return Number(balance) >= value;
  };

  // Отключение от  активного сокета
  const resetSocketRoom = (socket: Socket | null) => {
    if (!socket) return;

    socket?.removeAllListeners();
    socket?.connected && socket.disconnect();
    socket = null;

    lastBaits = true;
  };

  // Получаем номер следующего раунда
  const getNextRound = (round: number, maxRound: number) => {
    const nextRound = maxRound - round;
    return nextRound > 0 ? nextRound : 0;
  };

  // Проверяем можем ли использовать приманку
  const checkUseBaits = () => {
    const remainingBaits = store.getState().matchSlice.remainingBaits;

    if (remainingBaits === 0) {
      const result = { baits: 0, disabled: true };
      if (lastBaits) {
        result.disabled = false;
        lastBaits = false;
      }
      return result;
    }
    return { baits: remainingBaits, disabled: false };
  };

  // Списываем приманку
  const usedBaits = () => {
    const { baits, disabled } = checkUseBaits();
    lastBaits && dispatch(setRemainingBaits(!disabled ? baits - 1 : 0));
  };

  // Задает формат для значения мультиплеера
  const formatMultiplier = (multiplier: string): string => {
    if (multiplier.includes('.')) {
      let [int, frac] = multiplier.split('.');

      if (int.length === 1) {
        int = `0${int}`;
      }

      if (frac.length === 1) {
        frac = `${frac}0`;
      }
      return `${int}.${frac}`;
    }
    if (multiplier.length === 1) {
      return `0${multiplier}.00`;
    }
    if (multiplier.length === 2) {
      return `${multiplier}.00`;
    }
    return multiplier;
  };

  // Получаем текущий интервал по мультиплееру
  const getInterval = (multiplier: number): number => {
    if (multiplier < 20) return 1;
    else if (multiplier >= 20 && multiplier < 40) return 2;
    else if (multiplier >= 40 && multiplier < 60) return 3;
    else if (multiplier >= 60 && multiplier < 80) return 4;
    else if (multiplier >= 80 && multiplier < 90) return 5;
    else return 6;
  };
  const getTypeCoefficient = (value: number): ELastCoefficient => {
    if (value < 10) return ELastCoefficient.very_low;
    else if (value >= 10 && value < 20) return ELastCoefficient.low;
    else if (value >= 20 && value < 40) return ELastCoefficient.medium_low;
    else if (value >= 40 && value < 60) return ELastCoefficient.medium_high;
    else if (value >= 60 && value < 80) return ELastCoefficient.high;
    else return ELastCoefficient.very_high;
  };

  const getLifeTableData = (
    data: MatchLeaderboard,
  ): { user: IMatchLifeTable<IMatchLifeTableItem>; list: IMatchLifeTable<IMatchLifeTableItem[]> } => {
    const userId = profile!.userId;
    const user: IMatchLifeTable<IMatchLifeTableItem> = {
      [MultiplayerNomination.SUM_WEIGHT]: {
        userId,
        userName: getUserName,
        place: 0,
        score: 0,
        placeIncreased: false,
      },
      [MultiplayerNomination.QUANTITY]: {
        userId,
        userName: getUserName,
        place: 0,
        score: 0,
        placeIncreased: false,
      },
      [MultiplayerNomination.WEIGHT]: {
        userId,
        userName: getUserName,
        score: 0,
        place: 0,
        placeIncreased: false,
      },
    };

    const processLeaderboard = (
      data: MatchLeaderboardPlayer[],
      localStorageKey: EKeyStorage,
      nomination: MultiplayerNomination,
    ): IMatchLifeTableItem[] => {
      const restorePlace = getLocalStorage<RestorePlace>(localStorageKey, true);
      let userPlaceMap = new Map<number, RestorePlaceItem>();
      if (restorePlace && Object.keys(restorePlace).length) {
        userPlaceMap = new Map(restorePlace);
      }

      let isPlace = false;
      let isPlaceCount = 0;

      const list: IMatchLifeTableItem[] = data.map((leaderboardUser, i) => {
        const index = i + 1;
        const userPlace = userPlaceMap.get(leaderboardUser.userId);
        if (!userPlace) {
          userPlaceMap.set(leaderboardUser.userId, { index, increased: true });
        } else {
          if (userPlace.index < i) {
            userPlaceMap.set(leaderboardUser.userId, { index, increased: false });
          } else if (userPlace.index > i) {
            userPlaceMap.set(leaderboardUser.userId, { index, increased: true });
          }
        }
        setLocalStorage(localStorageKey, Array.from(userPlaceMap.entries()), true);

        if (leaderboardUser.userId === userId) {
          isPlaceCount = 1;
          user[nomination].score = leaderboardUser.score;
          user[nomination].place = index;
          user[nomination].placeIncreased = userPlaceMap.get(leaderboardUser.userId)!.increased;
        } else {
          isPlace = true;
        }

        if (!userPlace) return { ...leaderboardUser, place: index, placeIncreased: true };

        if (isPlace && isPlaceCount === 0) {
          user[nomination].place = data.length;
          user[nomination].score = 0;
          user[nomination].placeIncreased = false;
        }

        return {
          ...leaderboardUser,
          place: index,
          placeIncreased: userPlaceMap.get(leaderboardUser.userId)!.increased,
        };
      });

      return list;
    };

    const wList = processLeaderboard(data.byWeight, EKeyStorage.weightMatchLifePlace, MultiplayerNomination.WEIGHT);

    const qList = processLeaderboard(
      data.byQuantity,
      EKeyStorage.quantityMatchLifePlace,
      MultiplayerNomination.QUANTITY,
    );

    const swList = processLeaderboard(
      data.bySumWeight,
      EKeyStorage.sumWeightMatchLifePlace,
      MultiplayerNomination.SUM_WEIGHT,
    );

    return {
      user,
      list: {
        [MultiplayerNomination.SUM_WEIGHT]: swList,
        [MultiplayerNomination.QUANTITY]: qList,
        [MultiplayerNomination.WEIGHT]: wList,
      },
    };
  };

  const calcFinalScores = (data: MatchFinalScores) => {
    const currentUserId = profile!.userId;

    const sortedFinalLeaderBoard: IFinalLeaderBoard = {
      [MultiplayerNomination.WEIGHT]: [],
      [MultiplayerNomination.QUANTITY]: [],
      [MultiplayerNomination.SUM_WEIGHT]: [],
    };
    const userScore: IFinalLeaderBoard<IFinalLeaderBoardItem | undefined> = {
      [MultiplayerNomination.WEIGHT]: undefined,
      [MultiplayerNomination.QUANTITY]: undefined,
      [MultiplayerNomination.SUM_WEIGHT]: undefined,
    };
    let userNewBalance = 0;
    Object.entries(data).forEach(([nomination, list]) => {
      Object.entries(list).forEach(([userId, value]) => {
        if (+userId === currentUserId) {
          userScore[nomination as unknown as MultiplayerNomination] = {
            ...(value as unknown as Omit<IFinalLeaderBoardItem, 'userId'>),
            userId: +userId,
          };
          userNewBalance += +(value as IFinalLeaderBoardItem).prize;
        }

        sortedFinalLeaderBoard[nomination as unknown as MultiplayerNomination].push({
          ...(value as unknown as Omit<IFinalLeaderBoardItem, 'userId'>),
          userId: +userId,
        });
      });
      sortedFinalLeaderBoard[nomination as unknown as MultiplayerNomination].sort((a, b) => a.position - b.position);
    });
    return { sortedFinalLeaderBoard, userScore, userNewBalance };
  };

  return {
    checkPayBet,
    resetSocketRoom,
    getNextRound,
    checkUseBaits,
    usedBaits,
    formatMultiplier,
    getInterval,
    getTypeCoefficient,
    getLifeTableData,
    calcFinalScores,
  };
};
