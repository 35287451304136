import { Ref } from 'react';

import classes from './Loader.module.scss';

type LoaderSize = 'sm' | 'lg';
interface Props {
  size?: LoaderSize;
  className?: string | undefined;
  measureRef?: Ref<HTMLDivElement>;
  full?: boolean;
  classNameByFull?: string;
}
export const Loader = ({ size, className, measureRef, full, classNameByFull }: Props) => {
  const getSizeStyle = (): string => {
    switch (size) {
      case 'sm':
        return classes.uiLoaderSm;
      case 'lg':
        return classes.uiLoaderLg;
      default:
        return classes.uiLoaderLg;
    }
  };
  const baseLoader = <div ref={measureRef} className={`${className ?? ''} ${classes.uiLoader} ${getSizeStyle()}`} />;
  if (full) {
    return (
      <div className={`w-full h-full absolute flex justify-center items-center ${classNameByFull ?? ''}`}>
        {baseLoader}
      </div>
    );
  }
  return baseLoader;
};
