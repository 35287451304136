import { IUserProfile, IUserTonWallet } from '@hf/shared-common';

import { serverAPI } from '@shared/api/api';

export const userApi = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    userProfile: build.query<IUserProfile, { code?: string | null }>({
      query: ({ code }) => ({
        url: `/user/profile${code !== '' && code !== null ? `?code=${code}` : ''}`,
      }),
    }),
    tonWalletConnect: build.mutation<IUserTonWallet, Omit<IUserTonWallet, 'isActive'>>({
      query: (body) => ({
        url: `/user/ton-wallet/connect`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
