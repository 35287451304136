export enum EKeyStorage {
  dataCheckString = 'data-check-string',
  viewModalCatchToDay = 'view-modal-catch-to-day',
  viewModalGreatStart = 'view-modal-great-start',
  viewModalHowToPlay = 'view-modal-how-to-play',
  viewModalWelcome = 'view-modal-welcome',
  viewModalOnboardingTap = 'view-modal-onboarding-tap',
  viewModalPartnerCat = 'view-modal-partner-cat',
  viewModalOnboardingMp = 'view-modal-onboarding-mp',
  enabledSound = 'enabled-sound',
  enabledSamples = 'enabled-samples',
  enabledVibration = 'enabled-vibration',
  currentTgUserId = 'current-tg-user-id',
  currentLang = 'current-lang',
  lastLeaderboardHistory = 'last-leaderboard-history',
  weightMatchLifePlace = 'weight-match-life-place',
  quantityMatchLifePlace = 'quantity-match-life-place',
  sumWeightMatchLifePlace = 'sum-weight-match-life-place',
  restoreMatchId = 'restore-match-id',
  firstStart = 'first-start',
}
