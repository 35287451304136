import { useTranslation } from 'react-i18next';

import { VIDEO } from '@shared/assets';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { ECommonModals, selectViewCommonModal, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { Button, Video, Wrapper } from '@shared/ui';
import { Modal } from '@shared/widgets';

export const OnboardingTapModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.onboardingTap));
  const { setItem } = useStorage();

  const closeModal = () => {
    dispatch(setViewCommonModals({ modal: ECommonModals.onboardingTap, value: false }));
    setItem(EKeyStorage.viewModalOnboardingTap, 'true', true);
  };

  return (
    <Modal isOpen={isOpen} isButtonClose={false} classNameOverlay="bg-primary-darkBlue" className="z-30">
      <div className="w-full h-full flex flex-col justify-center items-center gap-y-[10px]">
        {isOpen ? (
          <Wrapper className="flex flex-col items-center gap-y-[10px]">
            <Video className="rounded-10 max-w-[95%]" src={VIDEO.COMMON_MODALS.TAP} />
          </Wrapper>
        ) : (
          <></>
        )}
        <Button size="lg" onClick={closeModal} shine={true}>
          {t('global:letsGo')}
        </Button>
      </div>
    </Modal>
  );
};
