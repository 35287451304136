import { Fragment } from 'react/jsx-runtime';

interface Props {
  currentStep?: number;
  numberOfSteps: number;
  small?: boolean;
}
export const Stepper = ({ currentStep, numberOfSteps, small }: Props) => {
  const activeColor = (index: number) =>
    currentStep && currentStep >= index ? 'bg-primary-purpleDefault' : 'bg-white';
  const isFinalStep = (index: number) => index === numberOfSteps - 1;

  return (
    <div className="flex flex-col items-center py-2 pr-3">
      {Array.from({ length: numberOfSteps }).map((_, index) => (
        <Fragment key={index}>
          <div className={`w-3 h-3 rounded-full ${activeColor(index)}`}></div>
          {isFinalStep(index) ? null : (
            <div className={`w-[3px] ${small ? 'h-7' : 'h-11'} ${activeColor(index)}`}></div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
