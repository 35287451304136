import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { animated } from 'react-spring';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import LoadingScreen from 'src/pixi/screens/LoadingScreen';

import { AmplitudeEventClient } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { GamesConfig } from '@pages/games/configs/Games.config';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  ECommonModals,
  setDisabledFooter,
  setShowGameHeader,
  setViewCommonModals,
} from '@shared/store/slice/CommonSlice';
import { Wrapper } from '@shared/ui';
import { sleep } from '@shared/utils/helpers.utils';

import { useStorage } from '../../shared/hooks/integration/useStorage';
import { EKeyStorage } from '../../shared/types/storage.types';

export const GamesPage = () => {
  const dispatch = useDispatch();
  const [showList, setShowList] = useState(false);
  const { gameList } = GamesConfig();
  const { getItem } = useStorage();

  const { useAnimTranslateX } = useAnimateSpring();
  const { toClick } = useUtils();
  const { t } = useTranslation();

  const runPage = async () => {
    const sn = ProducerCore.getScreen<LoadingScreen>(ScreenId.Loading);
    if (sn && sn.isShow) {
      dispatch(setDisabledFooter(true));
      await sleep(300);
      sn && sn.hidden();
      await sleep(1000);
    }

    sn && !sn.isShow && (await sleep(300));
    setShowList(true);
    dispatch(setShowGameHeader(true));
    setTimeout(() => {
      dispatch(setDisabledFooter(false));
    }, 1500);
  };

  useEffect(() => {
    runPage();
    amplitude.track(AmplitudeEventClient.FishingOpened);
    getItem(EKeyStorage.viewModalOnboardingMp, true).then(async (res) => {
      if (res === null) {
        dispatch(setViewCommonModals({ modal: ECommonModals.onboardingMp, value: true }));
      }
    });

    return () => {
      setShowList(false);
      dispatch(setShowGameHeader(false));
    };
  }, []);

  return (
    <>
      <div className="h-full w-full  relative">
        <animated.div
          style={useAnimTranslateX(showList)}
          className="h-full flex flex-col gap-y-[40px]  justify-between"
        >
          <Wrapper title={t('global:gameMode')} theme="darkBlue" />
          <Wrapper className="flex flex-col items-center gap-y-[20px] py-[30px] mb-[20px]" theme="darkBlue">
            {gameList.map(({ name, page, disabled, soon }, index) => {
              return (
                <Wrapper
                  key={index}
                  title={name}
                  soon={soon}
                  theme="light"
                  onClick={(event) => toClick(event, page, disabled, ESamples.buttonClick)}
                />
              );
            })}
          </Wrapper>
        </animated.div>
      </div>
    </>
  );
};
