export enum EGa4Category {
  Error = 'error',
  Onboarding = 'onboarding',
  Earn = 'earn',
  Fishing = 'fishing',
}

export enum EGa4Event {
  Login = 'login', //Пользователь вошел в приложение
  OnboardingOpened = 'onboarding_opened', //Пользователю показан экран онбординга
  CalendarOpened = 'calendar_opened', //Пользователь открыл календарь
  CalendarClosed = 'calendar_closed', //Пользователь вышел из календаря
  FarmingOpened = 'farming_opened', //Пользователь вошел на вкладку Farming
  FarmingPressed = 'farming_pressed', //Пользователь нажал кнопку Farm
  UserRewarded = 'user_rewarded', //Пользователь забрал награду за Farm
  TasksOpened = 'tasks_opened', //Пользователь вошел на вкладку Tasks
  TaskStarted = 'task_started', //Пользователь начал Task
  AdsWatched = 'ads_watched', //Пользователь просмотрел рекламу
  InviteCopied = 'invite_copied', //Инвайт скопирован
  InviteSent = 'invite_sent', //Инвайт отправлен
  ReferralActivated = 'referral_activated', //Пользователю засчитан рефереал
  FishingStarted = 'fishing_started', //Пользователь начал заброс
  FishingSuccess = 'fishing_success', //Пользователь вытянул рыбу
  FishingFailed = 'fishing_failed', //Рыба сорвалась
  LeaderboardOpened = 'leaderboard_opened', //Открыт лидерборд,
  FishingOpened = 'fishing_opened', //Пользователь вошел на вкладку Fishing
  ErrorStart = 'error_start', // Ошибка при старте приложения
}

export enum EGa4EventTiming {
  ViewCalendar = 'view_calendar',
  ViewTask = 'view_task',
  ViewFishing = 'view_fishing',
  ViewLeaderboard = 'view_leaderboard',
}

export type EGa4Params =
  | 'days_streak'
  | 'reward_sum'
  | 'task_type'
  | 'reward_sum'
  | 'user_tg_id'
  | 'modal_type'
  | 'view_time'
  | 'message';
export type EGa4ParamsType = Partial<{ [key in EGa4Params]: string | number }>;
export type EGa4TimingList = Partial<Record<EGa4Event, { start: number; end: number }>>;

export interface EGa4EventOptions extends EGa4ParamsType {
  category: EGa4Category;
  label?: string;
}
