import i18n, { TFunction, TOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export enum EI18Lang {
  en = 'en',
  ru = 'ru',
  fr = 'fr',
  esMx = 'es_mx',
  hi = 'hi',
  id = 'id',
}
i18n
  .use(Backend)
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'checkEmpty',
    process: (value: string, key: string, options: TOptions, translator: { translate: TFunction }) => {
      if (value === '') {
        return translator.translate(key, { lng: 'en', replace: options });
      }
      return value;
    },
  })
  .init({
    ns: [
      'error',
      'fishing',
      'footer',
      'friends',
      'global',
      'farming',
      'loading',
      'task',
      'leaderboard',
      'daily_bonus',
      'common_modals',
      'multiplayer',
    ],
    debug: false,
    fallbackLng: EI18Lang.en,
    supportedLngs: [EI18Lang.en, EI18Lang.ru, EI18Lang.fr, EI18Lang.esMx, EI18Lang.hi, EI18Lang.id],
    // appendNamespaceToCIMode:
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { v: import.meta.env.VITE_APP_GIT_HASH },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    postProcess: ['checkEmpty'],
  });
