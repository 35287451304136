import { IConfettiOptions } from 'node_modules/@tsparticles/confetti/types/IConfettiOptions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SlotCounter from 'react-slot-counter';
import { animated, useSpringRef } from 'react-spring';

import { BaitsIcon, CoinIcon, MainBgIllustration } from '@shared/assets';
import { gameScreenAssets } from '@shared/configs/assets.config';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useDailyReward } from '@shared/hooks/useDailyReward';
import { useLeaderBoard } from '@shared/hooks/useLeaderBoard';
import { ECommonModals, selectViewCommonModal, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { selectDailyRewardInfo } from '@shared/store/slice/DailyRewardSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { Button, Wrapper } from '@shared/ui';
import { sleep } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';
import { confetti } from '@tsparticles/confetti';
import { RecursivePartial } from '@tsparticles/engine';

export const DailyRewardsModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { useAnimOpacity, useAnimMarginTop } = useAnimateSpring();
  const dailyRewardInfo = useAppSelector(selectDailyRewardInfo);
  const isOpenReward = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.dailyRewards));

  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [currentDay, setCurrentDay] = useState(dailyRewardInfo?.prevStreakCount || 0);
  const { checkWeekHistory } = useLeaderBoard();
  const { currentBonus } = useDailyReward();
  const { ga4Event } = useGa4();

  const bonusBaits = currentBonus.current.baits;
  const bonusCoins = currentBonus.current.coins;

  const optionsConfetti: RecursivePartial<IConfettiOptions> = {
    particleCount: 50,
    origin: {
      x: 0.5,
      y: 0.5,
    },
    angle: 90,
    count: 50,
    position: {
      x: 50,
      y: 50,
    },
    spread: 45,
    startVelocity: 45,
    decay: 0.9,
    gravity: 1,
    drift: 0,
    ticks: 200,
    shapes: ['image'],
    shapeOptions: {
      type: 'image',
      image: {
        src: gameScreenAssets.urls.particleCoin,
        width: 200,
        height: 200,
      },
    },
    scalar: 2.5,
    zIndex: 100,
    disableForReducedMotion: true,
  };

  // Поднятие блока с днем наверх
  const dayMoveRef = useSpringRef();
  const dayMove = useAnimMarginTop(0, -(window.innerHeight / 2), { animRef: dayMoveRef, duration: 250 });

  const fadeAnimationRef = useSpringRef();
  const fadeAnimation = useAnimOpacity(1, 0, { animRef: fadeAnimationRef, duration: 300 });

  const onContinue = async () => {
    fadeAnimationRef.start();
    checkWeekHistory();
    await sleep(300);
    dispatch(setViewCommonModals({ modal: ECommonModals.dailyRewards, value: false }));
    ga4Event(EGa4Event.CalendarClosed, EGa4Category.Onboarding, '');
  };

  const animateStart = async () => {
    await sleep(400);
    setCurrentDay(dailyRewardInfo?.streakCount || 1);
    await sleep(100);
    confetti('tsParticles', optionsConfetti);
    await sleep(1600);
    setIsOpenDetails(true);
    dayMoveRef.start();
  };

  useEffect(() => {
    isOpenReward && animateStart();
  }, [isOpenReward]);

  return (
    <Modal isOpen={isOpenReward} isFullScreen={true} classNameOverlay=" bg-primary-darkBlue" className="z-30">
      <animated.div style={fadeAnimation} className="w-full h-full flex flex-col justify-center">
        <div id="tsParticles"></div>

        <animated.div style={dayMove} className="h-1/2 flex justify-center items-center">
          <MainBgIllustration className="absolute w-11/12 h-auto  z-0" />
          <div className="z-[1]  flex justify-center items-center flex-col gap-[10px]">
            <span className="text-[80px] h-[130px] font-bold">
              <SlotCounter value={currentDay} sequentialAnimationMode useMonospaceWidth autoAnimationStart={false} />
            </span>
            <span className="text-[30px] font-bold">{t('daily_bonus:modal.rewards.title')}</span>
          </div>
        </animated.div>

        <div
          className={`absolute bottom-0 w-full h-auto min-h-[230px] bg-primary-darkBlue rounded-t-10 shadow-[0_-15px_19px_0_rgba(91,76,152,1)] flex flex-col gap-y-[10px] p-[5px_20px_20px] border-t-2 border-secondary-purpleLight ${isOpenDetails ? 'modal-enter' : 'opacity-0'}`}
        >
          <div className="py-[10px] w-full relative flex justify-center items-center">
            <div className="font-bold text-[25px] leading-[27px] max-w-[300px]  text-white text-center">
              {t('daily_bonus:modal.rewards.your')}
            </div>
          </div>
          <div className="flex flex-col gap-y-[10px]">
            <Wrapper className="flex justify-center items-center gap-[10px] flex-wrap">
              {bonusCoins > 0 && (
                <Wrapper
                  className={`!w-[48%] flex justify-center items-center gap-x-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
                  paddingSize="sm"
                  theme="darkBlue"
                >
                  <CoinIcon className="w-[24px] h-auto" />
                  <span className="text-[18px] font-bold text-primary-purpleLight ">{bonusCoins}</span>
                </Wrapper>
              )}
              {bonusBaits > 0 && (
                <Wrapper
                  className={`!w-[48%] flex justify-center items-center gap-x-[10px] opacity-20 scale-[0.2] ${isOpenDetails ? 'animate-scaleAndFade ' : ''}`}
                  paddingSize="sm"
                  theme="darkBlue"
                >
                  <BaitsIcon className="w-[24px] h-auto" />
                  <span className="text-[18px] font-bold text-white">
                    +{bonusBaits} {t('global:baits')}
                  </span>
                </Wrapper>
              )}
              <div className="w-full text-[14px] text-center">{t('daily_bonus:modal.rewards.description')}</div>
            </Wrapper>
            <div className="w-full flex flex-col items-center justify-center px-[10px] ">
              <Button className="w-full" size="lg" shine={true} onClick={onContinue}>
                {t('global:continue')}
              </Button>
            </div>
          </div>
        </div>
      </animated.div>
    </Modal>
  );
};
