import { useTranslation } from 'react-i18next';

export const NotUserRegistration = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full min-h-dvh flex flex-col gap-y-4  justify-center items-center px-4">
      <h1 className="text-[22px] font-bold text-white text-center">{t('error:notUserRegistration.title')}</h1>
      <p className="text-[18px] font-normal text-white text-center">
        {t('error:notUserRegistration.information')} <b>/start</b>
      </p>
    </div>
  );
};
