import { initTelegramWebApp } from './initTelegramWebApp';

// Устанавливаем ключ с значением в хранилище тг
export const setTgCloudStorage = (key: string, data: any = null, json = false) => {
  if (json) {
    data = JSON.stringify(data);
  }

  return new Promise((resolve, reject) => {
    initTelegramWebApp().then((webApp) => {
      webApp.CloudStorage.setItem(key, data, (error, success) => {
        if (error || !success) {
          return reject(null);
        }

        return resolve(success);
      });
    });
  });
};

// Получаем значение по ключу из хранилища тг
export const getTgCloudStorage = async (key: string, json = false) => {
  return new Promise((resolve, reject) => {
    initTelegramWebApp().then((webApp) => {
      webApp.CloudStorage.getItem(key, (error, value) => {
        if (value && json) value = JSON.parse(value);

        if (error || value === '') {
          return resolve(null);
        }

        return resolve(value);
      });
    });
  });
};

// Удаляем ключ из хранилища тг
export const removeTgCloudStorage = (key: string) => {
  return new Promise((resolve, reject) => {
    initTelegramWebApp().then((webApp) => {
      webApp.CloudStorage.removeItem(key, (error, success) => {
        if (error || !success) {
          return reject(null);
        }
        return resolve(success);
      });
    });
  });
};
