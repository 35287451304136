import { IFarmInfo } from '@hf/shared-common';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum EFarmingModals {
  settings = 'settings',
}

interface FarmState {
  farmInfo: IFarmInfo | undefined;
}

const initState: FarmState = {
  farmInfo: undefined,
};

export const farmSlice = createSlice({
  name: 'farmSlice',
  initialState: initState,
  selectors: {
    selectFarmInfo: (state: FarmState) => state.farmInfo,
  },
  reducers: {
    setFarmInfo(state, action: PayloadAction<IFarmInfo>) {
      state.farmInfo = action.payload;
    },
  },
});

export const { setFarmInfo } = farmSlice.actions;
export const { selectFarmInfo } = farmSlice.selectors;
