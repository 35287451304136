interface Props {
  children?: React.ReactNode;
  position?: 'left' | 'right';
  icon?: React.ReactNode;
  className?: string;
  theme: 'win' | 'fishGone' | 'default';
  disabled?: boolean;
}

export const InfoPanel = ({ children, position, icon, className, theme, disabled }: Props) => {
  const getStylePosition = () => {
    const left = 'rounded-[0_20px_40px_20px] border-b-[3px]  border-r-[3px]  border-black/70 pl-[15px]';
    switch (position) {
      case 'left':
        return left;
      case 'right':
        return 'rounded-[20px_0_20px_40px] border-b-[3px] border-l-[3px] border-black/70';
      default:
        return left;
    }
  };
  const getStyleTheme = () => {
    switch (theme) {
      case 'win':
        return 'bg-primary-purpleDark  border-white/70 border-t-white/70';
      case 'fishGone':
        return 'bg-[#984C4C] border-white/70  border-t-white/70';
      default:
        return 'bg-black/25 border-black/70';
    }
  };
  return (
    <div
      className={`h-[26px] relative  flex items-center pt-[3px]  border-t-[1px] rounded-tr-30 border-t-transparent  transition-colors ${disabled ? 'opacity-30' : ''} ${icon && position === 'right' ? 'pr-[30px]' : ''} ${className ?? ''}  ${getStylePosition()} ${getStyleTheme()}`}
    >
      {children}
      <div className="absolute right-[-5px] w-[30px] h-[30px]"> {position === 'right' && icon}</div>
    </div>
  );
};
