import { Dispatch, SetStateAction, useCallback } from 'react';

import { ITaskAdInfo, IUserTonWallet } from '@hf/shared-common';

import { IExternalTask } from '@shared/api/task';
import { setRewardsModal } from '@shared/store/slice/TaskSlice';
import { useAppDispatch } from '@shared/store/store';
import { ModalRewards } from '@shared/widgets/Modal/ModalRewards';

import { TaskCard } from '../TaskCard/TaskCard';
import { TaskCardAds } from '../TaskCard/TaskCardAds';
import { TaskCardReferral } from '../TaskCard/TaskCardReferral';
import { TaskCardWallet } from '../TaskCard/TaskCardWallet';

interface Props {
  tasks: IExternalTask[];
  adsInfo?: ITaskAdInfo;
  blockId?: string;
  userWallet?: IUserTonWallet;
  setAvailableTaskCount: Dispatch<SetStateAction<number>>;
}

const TaskList = ({ tasks, adsInfo, blockId, userWallet, setAvailableTaskCount }: Props) => {
  // Sort tasks by order
  const sortedTasks = [...tasks].sort((a, b) => a.order - b.order);
  const dispatch = useAppDispatch();
  const showAdsTask = adsInfo && blockId;

  const onCloseRewardsModal = useCallback(() => {
    dispatch(
      setRewardsModal({
        isClear: true,
        taskType: undefined,
      }),
    );
  }, []);

  return (
    <>
      <ul className="flex flex-col gap-[8px]">
        {showAdsTask && (
          <TaskCardAds adsInfo={adsInfo} blockId={blockId} setAvailableTaskCount={setAvailableTaskCount} />
        )}
        <TaskCardWallet userWallet={userWallet} setAvailableTaskCount={setAvailableTaskCount} />
        {sortedTasks.map((task) => (
          <TaskCard key={task.id} task={task} setAvailableTaskCount={setAvailableTaskCount} />
        ))}
        <TaskCardReferral />
      </ul>

      <ModalRewards onClose={onCloseRewardsModal} />
    </>
  );
};

export default TaskList;
