import { Outlet, useLocation } from 'react-router-dom';
import Footer from 'src/layouts/BaseLayout/widgets/Footer';

export const BaseLayout = () => {
  const location = useLocation();

  return (
    <>
      <div className={`h-dvh `}>
        <div className={`relative flex flex-col  h-[calc(100vh-70px)]  overflow-y-auto overflow-x-hidden`}>
          <div className={`flex flex-col flex-1  h-full px-6 pt-[20px]`}>
            <Outlet />
          </div>
        </div>
        {/* {location.pathname === EAppRouterUrl.farming && <Circles />} */}
        <div className="w-full flex justify-center items-center h-[70px] fixed bottom-0">
          <Footer className={`px-4 h-[55px] mb-1 self-center `} />
        </div>
      </div>
    </>
  );
};
