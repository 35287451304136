import { EAppRouterUrl } from '@app/AppRouter';

export enum ETaskCustomType {
  ADS_GRAM = 'ADS_GRAM',
  TON_CONNECT_WALLETS = 'TON_CONNECT_WALLETS',
  REFERRAL = 'REFERRAL',
}

export interface ITaskItemCustom {
  type: ETaskCustomType;
}

interface Config {
  text: string;
  icon: React.ReactNode;
  link?: string;
  btnText: string;
  instruction?: string[];
  description?: string;
}

export interface ITaskListConfigCustom extends Config {
  type: ETaskCustomType;
  to?: EAppRouterUrl;
  baits?: number;
  coins?: number;
}

export type TTaskMetaDataCustomListType = Record<ETaskCustomType, ITaskListConfigCustom>;
