import ModalGreatStart from '@shared/assets/video/ModalGreatStart_.mp4';
// import ModalMp from '@shared/assets/video/ModalMp.mp4';
import ModalTap from '@shared/assets/video/ModalTap.mp4';
import ModalWelcome from '@shared/assets/video/ModalWelcome.mp4';

export const VIDEO = {
  COMMON_MODALS: {
    WELCOME: ModalWelcome,
    GREAT_START: ModalGreatStart,
    TAP: ModalTap,
    // MP: ModalMp,
  },
};
