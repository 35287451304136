import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { TSelectItemOption, TSelectSize, TSelectTheme } from '@shared/ui/Select/Select';

interface Props {
  className?: string;
  theme: TSelectTheme;
  size: TSelectSize;
  value: TSelectItemOption['value'];
  label: TSelectItemOption['label'];
  sound?: {
    samples: ESamples;
    volume?: number;
    disabled?: boolean;
  };
  clickHandler: (value: TSelectItemOption['value']) => void;
}
export const SelectItem = ({
  className,
  theme,
  size,
  value,
  label,
  sound = { samples: ESamples.buttonClick },
  clickHandler,
}: Props) => {
  const { samplePlay } = useAudio();
  const { vibration } = useUtils();
  const getSizeStyle = (): { sizeText: string; sizeH: string } => {
    switch (size) {
      case 'lg':
        return { sizeText: 'text-[15px]', sizeH: 'h-[34px]' };
      case 'sm':
        return { sizeText: 'text-[12px]', sizeH: 'h-[28px]' };
      default:
        return { sizeText: 'text-[12px]', sizeH: 'h-[22px]' };
    }
  };
  const { sizeText, sizeH } = getSizeStyle();
  return (
    <>
      <div
        className={`w-full ${sizeH} border-white/20 border-b ${className ?? ''} ${sizeText} `}
        onClick={() => {
          vibration('selectionChanged');
          sound && !sound?.disabled && sound?.samples && samplePlay(sound.samples, sound?.volume ?? undefined);
          clickHandler(value);
        }}
      >
        {label}
      </div>
    </>
  );
};
