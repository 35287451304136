import { useRef } from 'react';

import { leaderBoardApi } from '@shared/api/leaderBoard';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { ECommonModals, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { resetStateLeaderboard } from '@shared/store/slice/LeaderBoardSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { checkEqualityDate, getLastWeekStartDate } from '@shared/utils/helpers.utils';

let firstRequestTime = 0;
const expirationTime = 2 * 60 * 1000 + 15 * 1000; // 2m:15s
// const expirationTime = 20 * 1000; // 10s

export const useLeaderBoard = () => {
  const isInitCacheTime = useRef(firstRequestTime !== 0);
  const dispatch = useAppDispatch();

  const isUpdateCache = useRef(false);
  const { getItem, setItem } = useStorage();
  const profile = useAppSelector(selectUserProfile);

  const initCacheTime = () => {
    if (firstRequestTime === 0) {
      firstRequestTime = new Date().getTime();
      isInitCacheTime.current = true;
    }
  };

  const checkCache = () => {
    isUpdateCache.current && (isUpdateCache.current = false);

    if (firstRequestTime !== 0 && new Date().getTime() - firstRequestTime < expirationTime) return false;

    firstRequestTime = new Date().getTime();
    dispatch(leaderBoardApi.util.invalidateTags(['leaderBoard']));
    dispatch(resetStateLeaderboard());
    isUpdateCache.current = true;
    console.log('🎉 clear cache');
    return true;
  };

  const checkWeekHistory = () => {
    if (!profile?.leaderboardHistory) return;
    const lastWeekStartDate = getLastWeekStartDate();

    getItem<Date>(EKeyStorage.lastLeaderboardHistory).then((data) => {
      if (data === null || (data !== null && !checkEqualityDate(data, lastWeekStartDate))) {
        dispatch(setViewCommonModals({ modal: ECommonModals.leaderBoardHistory, value: true }));
        setItem(EKeyStorage.lastLeaderboardHistory, lastWeekStartDate);
      }
    });
  };

  return {
    isInitCacheTime: isInitCacheTime.current,
    isUpdateCache: isUpdateCache.current,
    firstRequestTime,
    initCacheTime,
    checkCache,
    checkWeekHistory,
  };
};
