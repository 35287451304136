import { useTranslation } from 'react-i18next';

import { Currency, MultiplayerBetAmount, MultiplayerNomination } from '@hf/shared-common';

import { EAppRouterUrl } from '@app/AppRouter';
import { MaxFishWeightIcon, QuantityFishIcon, SumFishWeightIcon } from '@shared/assets';
import { IGameList, IMatchBetList } from '@shared/types/games.type';
import { ITextSliderOptions } from '@shared/types/ui.type';
import { TTabsItemOption } from '@shared/ui/Tabs/Tabs';
import { NBSP } from '@shared/utils/helpers.utils';

export const GamesConfig = () => {
  const { t } = useTranslation();
  const gameList: IGameList[] = [
    {
      name: t('global:fastMatch'),
      page: EAppRouterUrl.fastMatch,
      disabled: false,
      soon: false,
    },
    {
      name: t('global:classicMatch'),
      page: EAppRouterUrl.classicMatch,
      disabled: true,
      soon: true,
    },
    {
      name: t('global:singlePlayer'),
      page: EAppRouterUrl.singlePlayer,
      disabled: true,
      soon: true,
    },
  ];

  // TODO: проверка гипотезы на одну ставку в 100 монет (2 и 3 ставка отключены)
  const fastMatchBetList: IMatchBetList[] = [
    { value: MultiplayerBetAmount[Currency.HYPE][0], disabled: false, hidden: false },
    { value: MultiplayerBetAmount[Currency.HYPE][1], disabled: true, hidden: true },
    { value: MultiplayerBetAmount[Currency.HYPE][2], disabled: true, hidden: true },
  ];

  const winnersMatchTabs: TTabsItemOption[] = [
    {
      key: 0,
      value: 1,
      label: t('multiplayer:sumFishScore'),
    },
    {
      key: 1,
      value: 2,
      label: t('multiplayer:totalFishScore'),
      disabled: false,
    },
    {
      key: 2,
      value: 3,
      label: t('multiplayer:maxKgFishScore'),
      disabled: false,
    },
  ];

  const nominationWinnersSlides: ITextSliderOptions = {
    0: {
      label: (
        <div className="flex items-center">
          {t('multiplayer:sumFishScore')} {NBSP}
          <SumFishWeightIcon width={34} height={20} />
        </div>
      ),
      className: 'bg-primary-blueExtraLight/20',
      titleClassName: 'text-secondary-blueExtraLight',
      value: MultiplayerNomination.SUM_WEIGHT,
    },
    1: {
      label: (
        <div className="flex items-center">
          {t('multiplayer:totalFishScore')} {NBSP}
          <QuantityFishIcon width={34} height={20} />
        </div>
      ),
      className: 'bg-primary-blueLight/20',
      titleClassName: 'text-secondary-blueLight',
      value: MultiplayerNomination.QUANTITY,
    },
    2: {
      label: (
        <div className="flex items-center">
          {t('multiplayer:maxKgFishScore')} {NBSP}
          <MaxFishWeightIcon width={34} height={20} />
        </div>
      ),
      className: 'bg-primary-blueExtraDark/20',
      titleClassName: 'text-primary-purpleLight',
      value: MultiplayerNomination.WEIGHT,
    },
  };
  const getIconNominationWinnersLeaderBoard = (nomination: MultiplayerNomination) => {
    switch (nomination) {
      case MultiplayerNomination.SUM_WEIGHT:
        return <SumFishWeightIcon width={34} height={20} />;
      case MultiplayerNomination.QUANTITY:
        return <QuantityFishIcon width={34} height={20} />;
      case MultiplayerNomination.WEIGHT:
        return <MaxFishWeightIcon width={34} height={20} />;
      default:
        return null;
    }
  };

  return { gameList, fastMatchBetList, winnersMatchTabs, nominationWinnersSlides, getIconNominationWinnersLeaderBoard };
};
