export const counterColorsHex = [
  '#ffffff',
  '#fefcff',
  '#fdfaff',
  '#fcf9ff',
  '#fbf5ff',
  '#faf4ff',
  '#f9f1ff',
  '#f8f0ff',
  '#f7edff',
  '#f5ebff',
  '#f4e9ff',
  '#f3e7ff',
  '#f2e4ff',
  '#f1e1ff',
  '#f0dfff',
  '#efddff',
  '#eddbff',
  '#ecd8ff',
  '#ebd6ff',
  '#ead5ff',
  '#e9d2ff',
  '#e8d0ff',
  '#e6cdff',
  '#e5ccff',
  '#e4caff',
  '#e3c7ff',
  '#e1c4ff',
  '#e0c3ff',
  '#dfc1ff',
  '#debeff',
  '#dcbcff',
  '#dbb9ff',
  '#dab7ff',
  '#d9b6ff',
  '#d7b3ff',
  '#d6b0ff',
  '#d4aeff',
  '#d3adff',
  '#d2aaff',
  '#d1a8ff',
  '#cfa6ff',
  '#cea4ff',
  '#cca1ff',
  '#cba0ff',
  '#c99dff',
  '#c89aff',
  '#c799ff',
  '#c596ff',
  '#c493ff',
  '#c292ff',
  '#c18fff',
  '#c08dff',
  '#be8cff',
  '#bc89ff',
  '#bb87ff',
  '#b984ff',
  '#b882ff',
  '#b680ff',
  '#b57eff',
  '#b37cff',
  '#b279ff',
  '#b077ff',
  '#ae74ff',
  '#ad73ff',
  '#ab70ff',
  '#a96eff',
  '#a86bff',
  '#a669ff',
  '#a466ff',
  '#a265ff',
  '#a162ff',
  '#9f60ff',
  '#9d5dff',
  '#9c5bff',
  '#9959ff',
  '#9756ff',
  '#9654ff',
  '#9451ff',
  '#914fff',
  '#904dff',
  '#8d4aff',
  '#8c47ff',
  '#8945ff',
  '#8742ff',
  '#8540ff',
  '#833cff',
  '#8139ff',
  '#7f37ff',
  '#7c34ff',
  '#7a30ff',
  '#782eff',
  '#752bff',
  '#7227ff',
  '#7023ff',
  '#6d1fff',
  '#6b1bff',
  '#6816ff',
  '#6510ff',
  '#6208ff',
  '#5f00ff',
];
