import { Swiper, SwiperSlide } from 'swiper/react';

import { ILastCoefficient } from '@shared/types/games.type';

interface IProps {
  className?: string;
  list: ILastCoefficient[];
}
export const MatchLastCoefficient = ({ className, list }: IProps) => {
  const getTheme = (type: ILastCoefficient['type']) => {
    switch (type) {
      case 'very_low':
        return 'bg-[#CCCCCC] border-[#c5c5c5]';
      case 'low':
        return 'bg-[#999FD1] border-[#AAB0E9]';
      case 'medium_low':
        return 'bg-[#4037BA] border-[#483FD5]';
      case 'medium_high':
        return 'bg-[#26098C] border-[#300CB6]';
      case 'high':
        return 'bg-[#3B0796] border-[#4508AF]';
      case 'very_high':
        return 'bg-[#56027D] border-[#7102A5]';
    }
  };

  return (
    <Swiper
      className={`w-full h-[28px] rounded-10 bg-secondary-grayBlue/80 px-2 ${className ?? ''}`}
      spaceBetween={4}
      slidesPerView={6}
    >
      {list.map((item, index) => (
        <SwiperSlide
          key={index}
          className={`rounded-10 w-[58px] h-[20px] border-2 flex justify-center items-center my-auto text-[12px] ${getTheme(item.type)}`}
        >
          {item.value}x
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
