import { animated, useSpring } from 'react-spring';

interface Props {
  className?: string;
}
export const ResultModalStarts = ({ className }: Props) => {
  const getRandomScale = () => Math.random() * (1.2 - 0.8) + 0.8;
  const getRandomSpeed = () => Math.random() * (1000 - 500) + 500;
  const getRandomOpacity = () => Math.round((Math.random() * (0.9 - 0.5) + 0.5) * 10) / 10;

  const animateStars = [];
  for (let i = 0; i < 9; i++) {
    const config = {
      from: { transform: `scale(${getRandomScale()})`, opacity: getRandomOpacity() },
      to: { transform: `scale(${getRandomScale()})`, opacity: 1 },
      config: { duration: getRandomSpeed() },
      loop: { reverse: true },
    };
    animateStars.push(config);
  }

  return (
    <svg
      width="375"
      height="229"
      viewBox="0 0 375 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ?? ''}`}
    >
      <animated.circle
        style={useSpring(animateStars[0])}
        cx="112.5"
        cy="49.5291"
        r="9.5"
        fill="url(#paint0_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="112.5 49.5291"
      />
      <animated.path
        style={useSpring(animateStars[0])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.935 48.9971C124.781 49.0027 124.627 49.0055 124.473 49.0055C117.742 49.0055 112.257 43.6716 112.014 37H111.997C111.76 43.5184 106.518 48.7599 100 48.9972V49.0139C106.656 49.2562 111.981 54.7169 112.006 61.4268C112.03 54.5625 117.603 49.0055 124.473 49.0055C124.627 49.0055 124.781 49.0083 124.935 49.0139V48.9971ZM112.014 61.9346C112.009 61.7966 112.006 61.658 112.006 61.5189C112.005 61.658 112.002 61.7966 111.997 61.9346H112.014Z"
        fill="#D4CAFF"
        transform-origin="112.5 49.5291"
      />

      <animated.circle
        style={useSpring(animateStars[1])}
        cx="345.5"
        cy="213.529"
        r="9.5"
        fill="url(#paint1_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="345.5 213.529"
      />
      <animated.path
        style={useSpring(animateStars[1])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.935 212.997C357.781 213.003 357.627 213.006 357.473 213.006C350.742 213.006 345.257 207.672 345.014 201H344.997C344.76 207.518 339.518 212.76 333 212.997V213.014C339.656 213.256 344.981 218.717 345.006 225.427C345.03 218.562 350.603 213.006 357.473 213.006C357.627 213.006 357.781 213.008 357.935 213.014V212.997ZM345.014 225.935C345.009 225.797 345.006 225.658 345.006 225.519C345.005 225.658 345.002 225.797 344.997 225.935H345.014Z"
        fill="#D4CAFF"
        transform-origin="345.5 213.529"
      />

      <animated.circle
        style={useSpring(animateStars[2])}
        cx="362.5"
        cy="12.5291"
        r="9.5"
        fill="url(#paint2_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="362.5 12.5291"
      />
      <animated.path
        style={useSpring(animateStars[2])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.935 11.9971C374.781 12.0027 374.627 12.0055 374.473 12.0055C367.742 12.0055 362.257 6.67157 362.014 0H361.997C361.76 6.51836 356.518 11.7599 350 11.9972V12.0139C356.656 12.2562 361.981 17.7169 362.006 24.4268C362.03 17.5625 367.603 12.0055 374.473 12.0055C374.627 12.0055 374.781 12.0083 374.935 12.0139V11.9971ZM362.014 24.9346C362.009 24.7966 362.006 24.658 362.006 24.5189C362.005 24.658 362.002 24.7966 361.997 24.9346H362.014Z"
        fill="#D4CAFF"
        transform-origin="362.5 12.5291"
      />

      <animated.circle
        style={useSpring(animateStars[3])}
        cx="12.5"
        cy="162.529"
        r="9.5"
        fill="url(#paint3_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="12.5 162.529"
      />
      <animated.path
        style={useSpring(animateStars[3])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9346 161.997C24.7813 162.003 24.6274 162.006 24.4728 162.006C17.7419 162.006 12.2568 156.672 12.0139 150H11.9971C11.7598 156.518 6.51835 161.76 0 161.997V162.014C6.65642 162.256 11.9813 167.717 12.0056 174.427C12.0304 167.562 17.6027 162.006 24.4728 162.006C24.6274 162.006 24.7813 162.008 24.9346 162.014V161.997ZM12.0139 174.935C12.0089 174.797 12.0061 174.658 12.0056 174.519C12.0051 174.658 12.0023 174.797 11.9973 174.935H12.0139Z"
        fill="#D4CAFF"
        transform-origin="12.5 162.529"
      />

      <animated.ellipse
        style={useSpring(animateStars[4])}
        cx="118.047"
        cy="211.089"
        rx="13.7159"
        ry="13.7159"
        fill="url(#paint4_radial_185_32475)"
        transform-origin="118.047 211.089"
      />
      <animated.path
        style={useSpring(animateStars[4])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136 210.321C135.779 210.329 135.557 210.333 135.333 210.333C125.615 210.333 117.696 202.632 117.345 193H117.321C116.979 202.411 109.411 209.979 100 210.321V210.345C109.61 210.695 117.298 218.579 117.333 228.266C117.37 218.356 125.415 210.333 135.333 210.333C135.557 210.333 135.779 210.337 136 210.345V210.321ZM117.345 229C117.338 228.801 117.334 228.601 117.333 228.401C117.333 228.601 117.329 228.801 117.321 229H117.345Z"
        fill="#D4CAFF"
        transform-origin="118.047 211.089"
      />

      <animated.ellipse
        style={useSpring(animateStars[5])}
        cx="300.05"
        cy="48.0941"
        rx="14.4779"
        ry="14.4779"
        fill="url(#paint5_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="300.05 48.0941"
      />
      <animated.path
        style={useSpring(animateStars[5])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M319 47.2835C318.769 47.2919 318.538 47.2962 318.305 47.2963C318.538 47.2964 318.769 47.3007 319 47.3091V47.2835ZM299.309 67C299.301 66.7899 299.297 66.5789 299.296 66.3671C299.296 66.5789 299.291 66.7899 299.284 67H299.309ZM281 47.3091C291.144 47.6783 299.259 56 299.296 66.2255C299.335 55.7675 307.822 47.3009 318.288 47.2963C308.034 47.2917 299.679 39.1645 299.309 29H299.284C298.922 38.9339 290.934 46.9219 281 47.2836V47.3091Z"
        fill="#D4CAFF"
        transform-origin="300.05 48.0941"
      />

      <animated.circle
        style={useSpring(animateStars[6])}
        cx="340.5"
        cy="110.529"
        r="9.5"
        fill="url(#paint6_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="340.5 110.529"
      />
      <animated.path
        style={useSpring(animateStars[6])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M352.935 109.997C352.781 110.003 352.627 110.006 352.473 110.006C345.742 110.006 340.257 104.672 340.014 98H339.997C339.76 104.518 334.518 109.76 328 109.997V110.014C334.656 110.256 339.981 115.717 340.006 122.427C340.03 115.562 345.603 110.006 352.473 110.006C352.627 110.006 352.781 110.008 352.935 110.014V109.997ZM340.014 122.935C340.009 122.797 340.006 122.658 340.006 122.519C340.005 122.658 340.002 122.797 339.997 122.935H340.014Z"
        fill="#D4CAFF"
        transform-origin="340.5 110.529"
      />

      <animated.circle
        style={useSpring(animateStars[7])}
        cx="195.5"
        cy="22.5291"
        r="9.5"
        fill="url(#paint7_radial_185_32475)"
        fillOpacity="0.5"
        transform-origin="195.5 22.5291"
      />
      <animated.path
        style={useSpring(animateStars[7])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.935 21.9971C207.781 22.0027 207.627 22.0055 207.473 22.0055C200.742 22.0055 195.257 16.6716 195.014 10H194.997C194.76 16.5184 189.518 21.7599 183 21.9972V22.0139C189.656 22.2562 194.981 27.7169 195.006 34.4268C195.03 27.5625 200.603 22.0055 207.473 22.0055C207.627 22.0055 207.781 22.0083 207.935 22.0139V21.9971ZM195.014 34.9346C195.009 34.7966 195.006 34.658 195.006 34.5189C195.005 34.658 195.002 34.7966 194.997 34.9346H195.014Z"
        fill="#D4CAFF"
        transform-origin="195.5 22.5291"
      />

      <animated.circle
        style={useSpring(animateStars[8])}
        cx="119.5"
        cy="112.557"
        r="8.76293"
        fill="url(#paint8_radial_185_32475)"
        transform-origin="119.5 112.557"
      />
      <animated.path
        style={useSpring(animateStars[8])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131 112.066C130.859 112.071 130.717 112.074 130.574 112.074C124.365 112.074 119.306 107.154 119.082 101H119.066C118.847 107.013 114.013 111.847 108 112.066V112.082C114.14 112.305 119.052 117.342 119.074 123.532C119.097 117.2 124.237 112.074 130.574 112.074C130.717 112.074 130.859 112.077 131 112.082V112.066ZM119.082 124C119.077 123.873 119.075 123.745 119.074 123.617C119.074 123.745 119.071 123.873 119.066 124H119.082Z"
        fill="#D4CAFF"
        transform-origin="119.5 112.557"
      />
      <defs>
        <radialGradient
          id="paint0_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(112.5 49.5291) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(345.5 213.529) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(362.5 12.5291) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.5 162.529) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(118.047 211.089) rotate(90) scale(13.7159)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(300.05 48.0941) rotate(90) scale(14.4779)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(340.5 110.529) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(195.5 22.5291) rotate(90) scale(9.5)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_185_32475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(119.53 112.557) rotate(90) scale(8.76293)"
        >
          <stop stopColor="#D4CAFF" />
          <stop offset="1" stopColor="#D4CAFF" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
