import { IDailyRewardCheckResult } from '@hf/shared-common';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DailyRewardState {
  dailyRewardInfo: IDailyRewardCheckResult | undefined;
}
const initState: DailyRewardState = {
  dailyRewardInfo: undefined,
};

export const dailyRewardSlice = createSlice({
  name: 'dailyRewardSlice',
  initialState: initState,
  selectors: {
    selectDailyRewardInfo: (state: DailyRewardState) => state.dailyRewardInfo,
  },
  reducers: {
    setDailyRewardInfo(state, action: PayloadAction<IDailyRewardCheckResult>) {
      state.dailyRewardInfo = action.payload;
    },
  },
});

export const { setDailyRewardInfo } = dailyRewardSlice.actions;
export const { selectDailyRewardInfo } = dailyRewardSlice.selectors;
