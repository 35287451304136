import { IReferralEarningInfo, IReferralLink, ITaskAdInfo, TaskExtUserStatus } from '@hf/shared-common';

import { serverAPI } from './api';

export interface IExternalTask {
  id: number;
  link: string;
  linkType: number;
  order: number;
  rewardCoins: number;
  rewardBaits: number;
  isActive: boolean;
  userStatus?: TaskExtUserStatus;
  translations: {
    languageCode: string;
    title: string;
    description: string | null;
    instructions: string[];
  }[];
}

export interface IExternalTaskReward {
  rewardCoins: number;
  rewardBaits: number;
}

export interface IExternalTaskClaim extends IExternalTaskReward {
  newBalance: string;
}

export const taskAPI = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    // Ads task endpoints
    adsInfo: build.query<ITaskAdInfo, void>({
      query: () => ({
        url: `/task/ads/info`,
      }),
    }),

    // New external task endpoints
    externalTaskInfo: build.query<IExternalTask[], string | void>({
      query: (langCode) => ({
        url: `/task/external/info${langCode ? `?langCode=${langCode}` : ''}`,
      }),
    }),
    externalTaskComplete: build.mutation<IExternalTaskReward, { taskId: number }>({
      query: (body) => ({
        url: `/task/external/complete`,
        method: 'POST',
        body,
      }),
    }),
    externalTaskClaim: build.mutation<IExternalTaskClaim, { taskId: number }>({
      query: (body) => ({
        url: `/task/external/claim`,
        method: 'POST',
        body,
      }),
    }),

    // Referral endpoints
    referralInfo: build.query<IReferralEarningInfo, void>({
      query: () => ({
        url: `/referral/info`,
      }),
    }),
    referralLink: build.query<IReferralLink, void>({
      query: () => ({
        url: `/referral/link`,
      }),
    }),
  }),
});
