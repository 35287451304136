import { useEffect, useState } from 'react';

export const useCountdownToUTC = (isStop: boolean) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setUTCDate(now.getUTCDate() + 1);
    nextDay.setUTCHours(0, 0, 0, 0);

    const difference = nextDay.getTime() - now.getTime();

    let timeLeft: { hours: number; minutes: number; seconds: number } = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (isStop) return;
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [isStop]);

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  return (
    <div className="flex justify-end gap-x-[2px]">
      {timeLeft.hours > 0 && <span className="w-[32px] text-end">{formatTime(timeLeft.hours || 0)}h</span>}
      {timeLeft.minutes > 0 && <span className="w-[32px]  text-end">{formatTime(timeLeft.minutes || 0)}m</span>}
      <span className="w-[29px]  text-center">{formatTime(timeLeft.seconds || 0)}s</span>
    </div>
  );
};
