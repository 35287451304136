import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { MultiplayerNomination } from '@hf/shared-common';

import { EAppRouterUrl } from '@app/AppRouter';
import { GamesConfig } from '@pages/games/configs/Games.config';
import { PedestalItem } from '@pages/games/multiplayer/ui/PedestalItem';
import { WinnersMatchLeaderBoard } from '@pages/games/multiplayer/widgets/WinnersMatchLeaderBoard/WinnersMatchLeaderBoard';
import { CoinIcon, IMAGE } from '@shared/assets';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { setShowGameHeader } from '@shared/store/slice/CommonSlice';
import { selectFinalLeaderBoard } from '@shared/store/slice/MatchSlice';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { IFinalLeaderBoardItem } from '@shared/types/games.type';
import { Button, TextSlider, Wrapper } from '@shared/ui';

export const WinnersMatchPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const profile = useAppSelector(selectUserProfile);
  const userId = profile!.userId;
  const finalScore = useAppSelector(selectFinalLeaderBoard);
  const { getBalanceFormat } = useUtils();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [prizePool, setPrizePool] = useState(0);
  const [nomination, setNomination] = useState<MultiplayerNomination>(MultiplayerNomination.SUM_WEIGHT);
  const [win, setWin] = useState('0');
  const [leaderByNomination, setLeaderByNomination] = useState<
    Record<number, { score: number; position: number; prize: string; userName: string }>
  >({});

  const { nominationWinnersSlides } = GamesConfig();
  const { toClick } = useUtils();

  useEffect(() => {
    dispatch(setShowGameHeader(false));
    getLeaderByNomination(nomination);
  }, []);

  const onChangeSlide = (slide: number) => {
    setSelectedSlide(slide);
    const nomination = nominationWinnersSlides[slide].value as MultiplayerNomination;
    setNomination(nomination);
    getLeaderByNomination(nomination);
  };

  const getLeaderByNomination = useCallback(
    (nomination: MultiplayerNomination) => {
      const list = finalScore.list[nomination];
      let sum = 0;
      const leaders: Record<number, { score: number; position: number; prize: string; userName: string }> = {};

      list.forEach((item, index) => {
        if (userId === item.userId) {
          setWin(item.prize);
        }
        sum += +item.prize;
        leaders[index + 1] = item;
      });

      setLeaderByNomination(leaders);

      setPrizePool(sum);
    },
    [finalScore, userId],
  );

  const getList = useMemo(() => {
    const list = Object.values(finalScore.list[nomination])
      .slice(3)
      .map((item, i) => {
        return {
          userName: item.userName,
          prize: item.prize,
          position: item.position,
          score: item.score,
          row: item.position,
        };
      });
    return list as unknown as IFinalLeaderBoardItem[];
  }, [finalScore, nomination]);

  useEffect(() => {
    if (finalScore.list[nomination][0] === undefined) {
      navigate(EAppRouterUrl.fishing);
    }
  }, [nomination, navigate]);

  return (
    <>
      <div className="flex flex-col  h-full pt-[10px] gap-y-[10px]">
        <Wrapper theme="darkBlue" className="flex items-center">
          <span className="w-1/2 h-full text-[25px] content-center">{t('global:winners')}</span>
          <div className="flex  w-1/2  justify-end items-center gap-x-[8px] text-[12px] text-center leading-[14px]">
            <div className="flex justify-center gap-x-[4px] items-center">
              <span>{t('global:yourWin')}</span>
              <span className="flex items-center gap-x-[2px]">
                {getBalanceFormat(finalScore.win)} <CoinIcon width={16} height={18} />
              </span>
            </div>
          </div>
        </Wrapper>
        <Wrapper theme="darkBlue" className="h-full flex flex-col gap-y-[10px]">
          <div className="flex h-[152px] justify-between">
            <PedestalItem
              place={2}
              coins={leaderByNomination[2]?.prize}
              nomination={leaderByNomination[2]?.score}
              cup={IMAGE.CUPS.CUP_SILVER}
              userName={leaderByNomination[2]?.userName}
              nominationType={nomination}
            />
            <PedestalItem
              place={1}
              coins={leaderByNomination[1]?.prize}
              nomination={leaderByNomination[1]?.score}
              cup={IMAGE.CUPS.CUP_GOLD}
              userName={leaderByNomination[1]?.userName}
              nominationType={nomination}
            />
            <PedestalItem
              place={3}
              coins={leaderByNomination[3]?.prize}
              nomination={leaderByNomination[3]?.score}
              cup={IMAGE.CUPS.CUP_BRONZE}
              userName={leaderByNomination[3]?.userName}
              nominationType={nomination}
            />
          </div>
          <TextSlider
            currentSlide={selectedSlide}
            options={nominationWinnersSlides}
            countSlides={2}
            classNameTitle="!text-[12px]"
            onChange={onChangeSlide}
          />
          <WinnersMatchLeaderBoard
            className="!h-[calc((100%-40px)-170px)]"
            posts={getList}
            nomination={nomination}
            showHeaderText={false}
          />
        </Wrapper>
        <NavLink
          to={EAppRouterUrl.fishing}
          onClick={(event) => toClick(event, EAppRouterUrl.fishing, false, ESamples.buttonClick)}
          className="w-full"
        >
          <Button size="lg" className="w-full" shine={true} sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('global:close')}
          </Button>
        </NavLink>
      </div>
    </>
  );
};
