import { ILeaderboardUsers, LeaderboardLeague, LeaderboardType } from '@hf/shared-common';

export enum ELeaderBoardPrizePlace {
  GOLD = 1,
  SILVER = 2,
  BRONZE = 3,
}

export interface ILeaderBoardListConfig {
  items: ILeaderboardUsers | undefined;
  offset: number;
  hasMore: boolean;
  lastOffset: number | undefined;
  scrollTop: number;
}
export type ILeaderBoardList = Record<LeaderboardType, Record<LeaderboardLeague, ILeaderBoardListConfig>>;
