
import { IUserProfile } from '@hf/shared-common';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserState {
  user: WebAppUser | undefined;
  userInitData: string | undefined;
  userProfile: IUserProfile | undefined;
}

const initState: UserState = {
  user: undefined,
  userInitData: undefined,
  userProfile: undefined,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: initState,
  selectors: {
    selectUser: (state: UserState) => state.user,
    selectUserInitData: (state: UserState) => state.userInitData,
    selectUserProfile: (state: UserState) => state.userProfile,
  },
  reducers: {
    setUser(state, action: PayloadAction<WebAppUser>) {
      state.user = action.payload;
    },
    setUserInitData(state, action: PayloadAction<string>) {
      state.userInitData = action.payload;
    },
    setUserProfile(state, action: PayloadAction<IUserProfile>) {
      state.userProfile = action.payload;
    },
  },
});

export const { setUser, setUserInitData, setUserProfile } = userSlice.actions;
export const { selectUser, selectUserInitData, selectUserProfile } = userSlice.selectors;
