import * as Sentry from '@sentry/react';

export class CustomConsoleIntegration {
  name = 'CustomConsoleIntegration';

  setupOnce() {
    const originalConsoleError = console.error;

    console.error = (...args: any[]) => {
      // Если объект ошибки среди аргументов
      let errorObject = null;
      for (const arg of args) {
        if (arg instanceof Error) {
          errorObject = arg;
          break;
        }
      }

      // Если ошибка не найдена, создаем новую
      if (!errorObject) {
        const message = args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg))).join(' ');
        errorObject = new Error(message);
      }

      // Отправка ошибки в Sentry
      Sentry.captureException(errorObject);

      // Вызываем оригинальный console.error
      originalConsoleError.apply(console, args);
    };
  }
}
