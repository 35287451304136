import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AmplitudeEventClient,
  DAILY_REWARD_BAITS,
  DAILY_REWARD_COINS,
  DAILY_REWARD_COINS_AFTER_STREAK,
  DAILY_REWARD_COINS_MAX_STREAK,
} from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { IApiError } from '@shared/api/api';
import { dailyRewardApi } from '@shared/api/dailyReward';
import { useLeaderBoard } from '@shared/hooks/useLeaderBoard';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { ECommonModals, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { selectDailyRewardInfo, setDailyRewardInfo } from '@shared/store/slice/DailyRewardSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';

interface IDailyBonus {
  day: number;
  coins: number;
  baits: number;
  active: boolean;
  pulse: boolean;
}

export const useDailyReward = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkWeekHistory } = useLeaderBoard();
  const { setReturnDailyRewards, updateProfileCoins } = useUtils();
  const [getCheck] = dailyRewardApi.useLazyCheckQuery();
  const dailyRewardInfo = useAppSelector(selectDailyRewardInfo);
  const currentBonus = useRef<{ coins: number; baits: number }>({
    coins: 0,
    baits: 0,
  });

  const [dailyRewardList, setDailyRewardList] = useState<IDailyBonus[]>([]);

  const getCurrentBonus = (streakCount: number, baits: number, isMaxDay: boolean) => {
    currentBonus.current.baits = baits;
    currentBonus.current.coins = isMaxDay
      ? DAILY_REWARD_COINS_AFTER_STREAK
      : DAILY_REWARD_COINS[streakCount as keyof typeof DAILY_REWARD_COINS];
  };

  const calcInfo = (streakCount: number) => {
    const diffDay = streakCount - DAILY_REWARD_COINS_MAX_STREAK;
    const isMaxDay = streakCount > DAILY_REWARD_COINS_MAX_STREAK;
    const addDays = 3 - (diffDay % 3);
    const lengthList = isMaxDay ? addDays + streakCount : DAILY_REWARD_COINS_MAX_STREAK;
    const baits = Object.keys(DAILY_REWARD_BAITS).includes(`${streakCount}`) ? DAILY_REWARD_BAITS[streakCount] : 0;

    return { lengthList, baits, isMaxDay };
  };

  const generateList = (length: number, isMaxDay: boolean) => {
    if (!dailyRewardInfo) return [];
    const { streakCount } = dailyRewardInfo;
    const list: IDailyBonus[] = [];
    for (let index = 1; index <= length; index++) {
      const item = {
        day: index,
        coins: isMaxDay
          ? DAILY_REWARD_COINS_AFTER_STREAK
          : DAILY_REWARD_COINS[index as keyof typeof DAILY_REWARD_COINS],
        baits: DAILY_REWARD_BAITS[index as keyof typeof DAILY_REWARD_BAITS] || 0,
        active: index <= streakCount,
        pulse: index === streakCount,
      };
      list.push(item);
    }
    setDailyRewardList(list);
  };

  const checkDailyReward = async (redirect: boolean = false) => {
    await getCheck()
      .unwrap()
      .then((data) => {
        dispatch(setDailyRewardInfo(data));
        if (data.updated) {
          const isDailyPage = location.pathname === EAppRouterUrl.dailyRewards;
          dispatch(setViewCommonModals({ modal: ECommonModals.dailyRewards, value: true }));

          setReturnDailyRewards(EAppRouterUrl.farming);
          !isDailyPage && navigate(EAppRouterUrl.dailyRewards);
          updateProfileCoins(data.reward?.coins ?? 0);
          amplitude.track(AmplitudeEventClient.DailyReward, {
            days_streak: data.streakCount,
            sum: data.reward?.coins ?? 0,
            baits: data.reward?.baits ?? 0,
          });
        } else {
          checkWeekHistory();
          redirect && navigate(EAppRouterUrl.farming);
        }
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error checking daily reward: ${data?.message}`);
        if (data) toast.error(data.message);
      });
  };

  useEffect(() => {
    if (!dailyRewardInfo) return;
    const { streakCount } = dailyRewardInfo;
    const { lengthList, isMaxDay, baits } = calcInfo(streakCount);

    generateList(lengthList, isMaxDay);
    getCurrentBonus(streakCount, baits, isMaxDay);
  }, [dailyRewardInfo]);

  return { dailyRewardList, dailyRewardInfo, currentBonus, checkDailyReward };
};
