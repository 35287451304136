import { useCallback, useState } from 'react';

import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { Loader } from '@shared/ui/Loader/Loader';
import { SelectItem } from '@shared/ui/Select/SelectItem';

export type TSelectSize = 'sm' | 'lg';
export type TSelectTheme = 'purple';

export type TSelectItemOption = {
  key: string | number;
  value: string | number;
  label: string | React.ReactNode;
  disabled?: boolean;
};

interface Props {
  className?: string;
  theme?: TSelectTheme;
  size?: TSelectSize;
  disabled?: boolean;
  sound?: {
    samples: ESamples;
    volume?: number;
    disabled?: boolean;
  };
  loading?: boolean;
  options?: TSelectItemOption[];
  selected?: string | number;
  onChange: (value: TSelectItemOption['value']) => void;
}
export const Select = ({
  className,
  theme = 'purple',
  size = 'lg',
  disabled,
  sound = { samples: ESamples.buttonClick },
  loading,
  options,
  selected,
  onChange,
}: Props) => {
  const [openList, setOpenList] = useState(false);
  const { samplePlay } = useAudio();

  const getSelected = (selected: Props['selected']) => {
    if (!options) return '';
    const result = options.find((item) => {
      return item.value === selected;
    });
    return result ? result.label : '';
  };

  const getThemeStyle = (): { themeSelectedStyle: string; textStyle: string; themeStyleList: string } => {
    switch (theme) {
      case 'purple':
        return {
          themeSelectedStyle: `bg-primary-purpleDark`,
          textStyle: `text-white`,
          themeStyleList: `bg-primary-grayBlue`,
        };
      default:
        return {
          themeSelectedStyle: '',
          textStyle: '',
          themeStyleList: '',
        };
    }
  };
  const getSizeStyle = (): { sizeText: string; sizeH: string; sizeMaxH: string } => {
    switch (size) {
      case 'lg':
        return { sizeText: 'text-[15px]', sizeH: 'h-[34px]', sizeMaxH: 'max-h-[140px]' };
      case 'sm':
        return { sizeText: 'text-[12px]', sizeH: 'h-[28px]', sizeMaxH: 'max-h-[116px]' };
      default:
        return { sizeText: 'text-[12px]', sizeH: 'h-[22px]', sizeMaxH: 'max-h-[92px]' };
    }
  };

  const { themeSelectedStyle, textStyle, themeStyleList } = getThemeStyle();
  const { sizeText, sizeH, sizeMaxH } = getSizeStyle();

  const clickItemHandler = (value: TSelectItemOption['value']) => {
    if (disabled) return;

    changeOpenList();
    onChange(value);
  };

  const changeOpenList = useCallback(() => {
    if (disabled || loading) return;

    setOpenList((prev) => {
      !prev && sound && !sound?.disabled && sound?.samples && samplePlay(sound.samples, sound?.volume ?? undefined);
      return !prev;
    });
  }, [disabled, loading]);

  return (
    <>
      <div
        className={`w-full relative rounded-t-10  ${className ?? ''} ${disabled ? 'pointer-events-none opacity-30' : ''}  ${sizeText}  ${textStyle} ${!loading && openList ? themeStyleList : ''}`}
      >
        {loading && (
          <div className={`w-full h-full absolute flex justify-center items-center rounded-10 ${themeSelectedStyle}`}>
            <Loader size="sm" />
          </div>
        )}
        <span
          className={`w-full  ${sizeH} font-bold flex justify-center items-center rounded-10  ${themeSelectedStyle}`}
          onClick={changeOpenList}
        >
          {getSelected(selected)}
        </span>
        <div
          className={`w-full ${sizeMaxH}  overflow-hidden overflow-y-auto flex flex-wrap rounded-b-10  absolute  z-20  transition-all  ${themeStyleList} ${!loading && openList ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          {options &&
            options
              .filter((item) => item.value !== selected)
              .map(({ key, value, label }) => {
                return (
                  <SelectItem
                    key={key}
                    theme={theme}
                    size={size}
                    value={value}
                    label={label}
                    clickHandler={clickItemHandler}
                    className={`flex justify-center items-center last:border-b-0 `}
                  />
                );
              })}
        </div>
      </div>
    </>
  );
};
