import { Container, Graphics, IPointData, Point, Sprite, Texture } from 'pixi.js';
import { ProducerCore } from 'src/pixi/ProducerCore';

import { backgroundsAssets } from '@shared/configs/assets.config';

export enum BackgroundType {
  cover = 'cover',
  container = 'container',
}

export enum BackgroundName {
  core = 'core',
  dubai = 'dubai',
}

const backgrounds: Record<BackgroundName, string> = {
  [BackgroundName.core]: backgroundsAssets.urls.backgroundCore,
  [BackgroundName.dubai]: backgroundsAssets.urls.backgroundDubai,
};

export default class UiBackground {
  private _view: Container | null = null;
  private _background: Sprite | null = null;
  private _backgrounds: Map<string, Sprite> = new Map();
  private _isDestroyed: boolean = false;
  private _currentBgContainer: Container | null = null;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'Background';
    }
    return this._view;
  }

  constructor() {
    this.initBackgrounds();
    this._background = this._backgrounds.get(BackgroundName.dubai) ?? null;

    if (this._background) {
      this.backgroundScaling(ProducerCore.getSizeApp, BackgroundType.cover);
    }
  }

  /**
   * Создает спрайты баграундов
   */
  private initBackgrounds() {
    try {
      for (const [key, value] of Object.entries(backgrounds)) {
        const texture = Texture.from(value);
        if (texture) {
          this._backgrounds.set(key, new Sprite(texture));
        }
      }
    } catch (error) {
      console.error('Failed to initialize backgrounds:', error);
    }
  }

  /**
   * Смена баграунда
   * @param name название фона
   */
  change(name: BackgroundName) {
    if (this._isDestroyed) return;

    const newBackground = this._backgrounds.get(name);
    if (!newBackground) return;

    if (this._background) {
      this._background.alpha = 0;
    }

    this._background = newBackground;
    this._background.alpha = 1;

    const size = ProducerCore.getSizeApp;
    this.resize(size, BackgroundType.cover);
  }

  /**
   * Создает контейнер с маской для фона
   * @param size размеры области экрана
   * @param type тип показа фона
   * @returns
   */
  private backgroundScaling(size: IPointData, type: BackgroundType) {
    if (!this._background || this._isDestroyed) return;

    try {
      // Clean up existing container if any
      if (this._currentBgContainer) {
        this._currentBgContainer.destroy({ children: true });
      }

      this._currentBgContainer = new Container();
      const mask = new Graphics().beginFill(0x8bc5ff).drawRect(0, 0, size.x, size.y).endFill();

      if (this._currentBgContainer && mask) {
        this._currentBgContainer.mask = mask;
        this._currentBgContainer.addChild(mask);
        this._currentBgContainer.addChild(this._background);
        this.view.addChild(this._currentBgContainer);
      }

      this.resize(size, type);
    } catch (error) {
      console.error('Failed to scale background:', error);
    }
  }

  /**
   * Изменяет размер фона в соответствии с размером экрана
   * @param size размеры области экрана
   * @param type тип показа фона
   * @param forceSize принудительные размеры фона
   * @returns
   */
  resize(size: IPointData, type: BackgroundType, forceSize?: IPointData) {
    if (!this._background || this._isDestroyed) return;

    try {
      let scale = 1;
      const position = new Point(0, 0);
      let sprite = {
        x: this._background.width,
        y: this._background.height,
      };

      if (forceSize) {
        sprite = forceSize;
      }

      const aspectRatio = size.x / size.y;
      const spriteAspectRatio = sprite.x / sprite.y;

      if (type === BackgroundType.cover ? aspectRatio > spriteAspectRatio : aspectRatio < spriteAspectRatio) {
        scale = size.x / sprite.x;
        position.y = 0;
      } else {
        scale = size.y / sprite.y;
        position.x = -(sprite.x * scale - size.x) / 2;
      }

      if (this._background) {
        this._background.scale.set(scale);
        this._background.position.copyFrom(position);
      }
    } catch (error) {
      console.error('Failed to resize background:', error);
    }
  }

  destroy() {
    this._isDestroyed = true;

    // Clean up all sprites in the backgrounds map
    this._backgrounds.forEach((sprite) => {
      if (sprite) {
        sprite.destroy({ children: true, texture: true });
      }
    });
    this._backgrounds.clear();

    // Clean up current background reference
    this._background = null;

    // Clean up background container
    if (this._currentBgContainer) {
      this._currentBgContainer.destroy({ children: true });
      this._currentBgContainer = null;
    }

    // Clean up main view
    if (this._view) {
      this._view.destroy({ children: true });
      this._view = null;
    }
  }
}
