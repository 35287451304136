import gsap from 'gsap';
import { Container, Graphics } from 'pixi.js';
import { colorBaseHex, colorProgressHex } from 'src/pixi/configs/button.color.config';
import { emitterBubbleStreamConfig } from 'src/pixi/configs/emitters.config';
import GameScreen, { gameScreenCountPositions } from 'src/pixi/screens/GameScreen';

import { calculateGlobalPosition, convertHexToPixiFormat } from '@shared/utils/helpers.utils';

const config = {
  baseCircle: {
    radius: 106,
  },
  innerCircle: {
    radius: 90,
    changeColorSpeed: 0.5,
  },
  outerCircle: {
    radius: 98,
    changeColorSpeed: 0.5,
    width: 4,
    mask: {
      duration: 0.5,
      ease: 'power1.inOut',
    },
  },
};

export default class UiButtonProgress {
  private _view: Container | null = null;
  private readonly scene: GameScreen;
  private _currentProgressEmitter = 0;
  private _currentProgress = 0;
  private _currentTween: gsap.core.Tween | null = null;
  private _isDestroyed: boolean = false;

  private _baseCircle: Graphics | null = null;
  private _innerCircle: Graphics | null = null;
  private _outerCircle: Graphics | null = null;
  private _outerCircleMask: Graphics | null = null;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'ProgressButton';
    }
    return this._view;
  }

  // Изменение цвета внутреннего круга
  set changeFillInnerCircle({ position, speed }: { position: gameScreenCountPositions; speed?: number }) {
    if (!this._innerCircle || this._isDestroyed) return;

    gsap.to(this._innerCircle, {
      pixi: { tint: convertHexToPixiFormat(colorBaseHex[position]) },
      duration: speed ?? config.innerCircle.changeColorSpeed,
    });
  }

  // Изменение цвета внешнего круга
  set changeFillOuterCircle({ position, speed }: { position: gameScreenCountPositions; speed?: number }) {
    if (!this._outerCircle || this._isDestroyed) return;

    gsap.to(this._outerCircle, {
      pixi: { tint: convertHexToPixiFormat(colorProgressHex[position]) },
      duration: speed ?? config.outerCircle.changeColorSpeed,
    });
  }

  constructor(scene: GameScreen) {
    this.scene = scene;
    this.initialize();
  }

  private initialize() {
    try {
      this.createBaseCircle();
      this.createInnerCircle();
      this.createOuterCircle();
      this.createOuterCircleMask();
      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize button progress:', error);
    }
  }

  private createBaseCircle() {
    try {
      const baseCircle = new Graphics();
      const { radius } = config.baseCircle;
      baseCircle.name = 'BaseCircle';

      baseCircle.beginFill(0xffffff, 0.15).drawCircle(0, 0, radius).endFill();
      this.view.addChild(baseCircle);
      this._baseCircle = baseCircle;
    } catch (error) {
      console.error('Failed to create base circle:', error);
    }
  }

  private createInnerCircle() {
    try {
      const innerCircle = new Graphics();
      const { radius } = config.innerCircle;
      innerCircle.name = 'InnerCircle';

      innerCircle.beginFill(0xfffdee, 0.5).drawCircle(0, 0, radius).endFill();

      this.view.addChild(innerCircle);
      this._innerCircle = innerCircle;

      this.changeFillInnerCircle = { position: 0, speed: 0 };
    } catch (error) {
      console.error('Failed to create inner circle:', error);
    }
  }

  private createOuterCircle() {
    try {
      const outerCircle = new Graphics();
      const { radius, width } = config.outerCircle;
      outerCircle.name = 'OuterCircle';

      outerCircle.lineStyle(width, 0xfffdee, 1).drawCircle(0, 0, radius);

      this.view.addChild(outerCircle);
      this._outerCircle = outerCircle;

      this.changeFillOuterCircle = { position: 0, speed: 0 };
    } catch (error) {
      console.error('Failed to create outer circle:', error);
    }
  }

  private createOuterCircleMask() {
    if (!this._outerCircle) return;

    try {
      const mask = new Graphics();
      mask.name = 'OuterCircleMask';
      this.view.addChild(mask);

      this._outerCircleMask = mask;
      this._outerCircle.mask = mask;
    } catch (error) {
      console.error('Failed to create outer circle mask:', error);
    }
  }

  /**
   * Обновляет маску для прогесса
   * @param progress
   * @returns
   */
  private updateMask(progress: number): { endX: number; endY: number } | undefined {
    if (!this._outerCircleMask || this._isDestroyed) return;

    try {
      const { radius, width } = config.outerCircle;
      const angle = (progress / 100) * Math.PI * 2;

      this._outerCircleMask.clear();
      this._outerCircleMask.beginFill(0xffffff);
      this._outerCircleMask.moveTo(0, 0);
      this._outerCircleMask.arc(0, 0, radius + width, -Math.PI / 2, -Math.PI / 2 + angle, false);
      this._outerCircleMask.lineTo(0, 0);
      this._outerCircleMask.endFill();

      const endX = (radius + width) * Math.cos(-Math.PI / 2 + angle);
      const endY = (radius + width) * Math.sin(-Math.PI / 2 + angle);

      return { endX, endY };
    } catch (error) {
      console.error('Failed to update mask:', error);
      return undefined;
    }
  }

  /**
   * Анимирует прогресс
   * @param progress
   */
  animateProgress(progress: number, isStop = false) {
    if (this._isDestroyed) return;

    if (this._currentTween) {
      this._currentTween.kill();
      this._currentTween = null;
    }

    try {
      const progressObj = { progress: this._currentProgress };
      const { duration, ease } = config.outerCircle.mask;

      this._currentTween = gsap.to(progressObj, {
        progress: progress,
        duration,
        ease,
        onUpdate: () => {
          if (this._isDestroyed) return;

          this._currentProgress = progressObj.progress;
          const localPosition = this.updateMask(this._currentProgress);

          if (localPosition && this._view) {
            const { x, y } = calculateGlobalPosition(localPosition.endX, localPosition.endY, this._view);
            const currentProgress = Math.round(progressObj.progress);

            if (!isStop && currentProgress !== this._currentProgressEmitter) {
              this._currentProgressEmitter = currentProgress;
              this.scene.runEmitter(x, y, emitterBubbleStreamConfig);
            }
          }
        },
        onComplete: () => {
          if (this._isDestroyed) return;

          this._currentProgress = progress;
          this._currentProgressEmitter = 0;
          this._currentTween = null;
        },
      });
    } catch (error) {
      console.error('Failed to animate progress:', error);
    }
  }

  adaptive() {
    // Add any adaptive logic here if needed
  }

  destroy() {
    this._isDestroyed = true;

    if (this._currentTween) {
      this._currentTween.kill();
      this._currentTween = null;
    }

    // Kill any ongoing GSAP animations
    if (this._innerCircle) {
      gsap.killTweensOf(this._innerCircle);
    }
    if (this._outerCircle) {
      gsap.killTweensOf(this._outerCircle);
    }

    // Clean up graphics objects
    [this._baseCircle, this._innerCircle, this._outerCircle, this._outerCircleMask].forEach((graphic) => {
      if (graphic) {
        graphic.destroy();
      }
    });

    this._baseCircle = null;
    this._innerCircle = null;
    this._outerCircle = null;
    this._outerCircleMask = null;

    // Clean up view
    if (this._view) {
      this._view.destroy({ children: true });
      this._view = null;
    }
  }
}
