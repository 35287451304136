import { IDailyRewardCheckResult } from '@hf/shared-common';

import { serverAPI } from '@shared/api/api';

export const dailyRewardApi = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    check: build.query<IDailyRewardCheckResult, void>({
      query: () => ({
        url: `/daily-reward/check`,
      }),
    }),
  }),
});
