export type EVibrationFunction = 'impactOccurred' | 'notificationOccurred' | 'selectionChanged';
export type EVibrationImpactOccurred = 'light' | 'medium' | 'heavy' | 'rigid' | 'soft';
export type EVibrationNotificationOccurred = 'error' | 'success' | 'warning';
export enum EGameButtonMode {
  fish = 'fish',
  fishGone = 'fishGone',
  default = 'default',
}

export enum EHeaderColor {
  base = '#222B4A',
  dubai = '#69a0ce',
}
