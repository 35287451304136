import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  isBlackout?: boolean;
}

export const SoonLabel = ({ className, isBlackout = true }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`absolute z-10  full-absolute flex justify-center items-center  ${className ?? ''} ${isBlackout ? 'bg-black/30 ' : ''}`}
    >
      <span className="  text-[16px] font-bold text-white z-10 rotate-[30deg]  pointer-events-none">
        {t('global:soon')}
      </span>
    </div>
  );
};
