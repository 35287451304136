import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Currency } from '@hf/shared-common';

import { EAppRouterUrl } from '@app/AppRouter';
import { GamesConfig } from '@pages/games/configs/Games.config';
import { CoinIcon } from '@shared/assets';
import { useMatchUtils } from '@shared/hooks/match/useMatchUtils';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  EMatchButtons,
  EMatchModals,
  selectDisabledMatchButton,
  selectLoadingMatchButton,
  selectMatchBet,
  selectViewMatchModal,
  setMatchBet,
  setViewMatchModals,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { IMatchBetList } from '@shared/types/games.type';
import { Button, Wrapper } from '@shared/ui';
import { Modal } from '@shared/widgets';

interface IProps {
  confirm: (bet: number, currency: Currency) => void;
}
export const FastMatchChoosingBetModal = ({ confirm }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { getBalanceFormat } = useUtils();
  const { fastMatchBetList } = GamesConfig();
  const { checkPayBet } = useMatchUtils();
  const isLoadingChoosingBet = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.choosingBet));
  const isDisabledChoosingBet = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.choosingBet));
  const bet = useAppSelector(selectMatchBet);
  const isOpen = useAppSelector((state) => selectViewMatchModal(state, EMatchModals.choosingBet));
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isAffordableBetAvailable, setIsAffordableBetAvailable] = useState(false);

  const setBetHandler = (item: IMatchBetList) => {
    const { value, disabled } = item;
    const isPay = checkPayBet(value);

    if (disabled || !isPay || isDisabledChoosingBet || isLoadingChoosingBet) return;

    dispatch(setMatchBet(value));
    setIsConfirmed(false);
  };

  const confirmHandler = () => {
    const isPay = checkPayBet(bet);
    if (!isPay || isDisabledChoosingBet || isLoadingChoosingBet) return;
    confirm(bet, Currency.HYPE);
    setIsConfirmed(true);
  };

  const closeModal = () => {
    dispatch(setViewMatchModals({ modal: EMatchModals.choosingBet, value: false }));
    dispatch(setMatchBet(0));
  };

  const redirectToFarm = () => {
    navigate(EAppRouterUrl.farming);
  };

  // & Если в списке fastMatchBetList только один видимый элемент и он доступен по финансам, то сразу его выбираем
  useEffect(() => {
    if (!isOpen) return;
    const validBets = fastMatchBetList.filter((bet) => !bet.hidden && !bet.disabled && checkPayBet(bet.value));
    setIsAffordableBetAvailable(validBets.length > 0);
    if (validBets.length === 1) {
      setBetHandler(validBets[0]);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t('multiplayer:modal.choosingBet.title')}
        className="flex flex-col gap-[10px]"
        onClose={closeModal}
      >
        <Wrapper className="text-center">{t('multiplayer:modal.choosingBet.description')}</Wrapper>
        <Wrapper className="flex justify-center gap-x-[10px]">
          {fastMatchBetList
            .map((item, index) => {
              if (item.hidden) return;
              return (
                <Button
                  disabled={item.disabled || isDisabledChoosingBet || !checkPayBet(item.value)}
                  key={index}
                  theme={`${checkPayBet(item.value) ? 'purple' : 'gray'}`}
                  onClick={() => setBetHandler(item)}
                  className={`border-2 border-transparent ${bet === item.value && checkPayBet(item.value) ? ' !border-white/80' : ''}`}
                >
                  <div className="flex items-center gap-x-[5px]">
                    <span>{getBalanceFormat(item.value)}</span> <CoinIcon className="w-[18px] h-[20px]" />
                  </div>
                </Button>
              );
            })
            .filter(Boolean)}
        </Wrapper>
        {isAffordableBetAvailable ? (
          <Button
            size="lg"
            loading={isLoadingChoosingBet}
            disabled={!bet || isDisabledChoosingBet || isConfirmed}
            onClick={confirmHandler}
          >
            {t('global:confirm')}
          </Button>
        ) : (
          <Button size="lg" onClick={redirectToFarm}>
            {t('global:startFarming')}
          </Button>
        )}
      </Modal>
    </>
  );
};
