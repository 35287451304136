// https://vis4.net/labs/multihue/#colors=#2B61A5,#2D48A5,#8444B6,953C3C|steps=20|bez=1|coL=0
export const colorBaseHex = [
  '#2b61a5',
  '#2d5da5',
  '#315aa5',
  '#3757a6',
  '#3d55a6',
  '#4452a6',
  '#4c50a5',
  '#544ea4',
  '#5c4ca2',
  '#644a9f',
  '#6c489c',
  '#744797',
  '#7b4591',
  '#82438a',
  '#884181',
  '#8d4077',
  '#913f6b',
  '#943d5d',
  '#953d4e',
  '#953c3c',
];

// https://vis4.net/labs/multihue/#colors=#4984CF,#5E7ADB,#8A67A3,#FF8C8C|steps=20|bez=1|coL=0
export const colorProgressHex = [
  '#4984cf',
  '#4d82d0',
  '#5281d1',
  '#587fd0',
  '#5f7dce',
  '#667ccc',
  '#6d7ac9',
  '#7579c6',
  '#7e78c1',
  '#8677bd',
  '#8f76b8',
  '#9976b3',
  '#a377ae',
  '#ad77a9',
  '#b879a4',
  '#c47b9f',
  '#d17e9a',
  '#df8295',
  '#ee8690',
  '#ff8c8c',
];
