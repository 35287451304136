import gsap from 'gsap';
import { Application, Container, ICanvas, Sprite, Texture } from 'pixi.js';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import UiBubbles from 'src/pixi/ui/UiBubbles';

export default class LoadingScreen extends Container {
  private readonly app: Application<ICanvas>;
  private readonly _id: ScreenId = ScreenId.Loading;
  private _globalTl: gsap.core.Timeline | null = null;
  private _loadingTl: gsap.core.Timeline | null = null;
  private _isShow: boolean = true;
  private _isDestroyed: boolean = false;

  private _bubbles: UiBubbles | null = null;
  private _loading: Sprite | null = null;

  constructor(app: Application<ICanvas>, { skipShowLoading } = { skipShowLoading: false }) {
    super();

    this.app = app;
    this.name = 'LoadingScreen';

    try {
      this.initialize(skipShowLoading);
    } catch (error) {
      console.error('Failed to initialize loading screen:', error);
    }
  }

  private initialize(skipShowLoading: boolean): void {
    // Initialize timelines
    this._globalTl = gsap.timeline();
    this._loadingTl = gsap.timeline();

    // Create bubbles
    if (this.app?.view?.width) {
      this._bubbles = new UiBubbles(this.app.view.width);
    }

    // Create loading sprite
    const loadingTexture = Texture.from('loadingFishHook.png');
    if (loadingTexture) {
      this._loading = new Sprite(loadingTexture);
      this._loading.name = 'loadingFishHook';
    }

    if (skipShowLoading) {
      this.alpha = 0;
    }

    // Add children if they exist
    if (this._bubbles?.view) {
      this.addChild(this._bubbles.view);
    }
    if (this._loading) {
      this.addChild(this._loading);
    }

    this.adaptive();
    this.runLoading();
  }

  runLoading(): void {
    if (this._isDestroyed || !this._loadingTl || !this._loading) return;

    try {
      this._loadingTl.to(this._loading, {
        rotation: -360,
        duration: 60,
        repeat: -1,
        ease: 'linear',
      });
    } catch (error) {
      console.error('Failed to run loading animation:', error);
    }
  }

  show(): void {
    if (this._isDestroyed || !this._globalTl || !this._loading) return;

    try {
      if (this._bubbles) {
        this._bubbles.reset();
      }

      this._globalTl.to(this._loading, { alpha: 1, duration: 0.2 }).to(this, { alpha: 1, duration: 0.2 });

      this._isShow = true;
    } catch (error) {
      console.error('Failed to show loading screen:', error);
    }
  }

  hidden(): void {
    if (this._isDestroyed || !this._globalTl || !this._loading || !this._bubbles) return;

    try {
      this._globalTl
        .add(this._bubbles.animateFullHeight())
        .to(this._loading, { alpha: 0, duration: 0.2 }, '<10%')
        .to(this, { alpha: 0, duration: 0 });

      this._isShow = false;
    } catch (error) {
      console.error('Failed to hide loading screen:', error);
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._loading) return;

    try {
      const { x: sizeX, y: sizeY } = ProducerCore.getSizeApp;

      this._loading.anchor.set(0.5);
      this._loading.scale.set(0.5);
      this._loading.position.set(sizeX / 2, sizeY / 2);
    } catch (error) {
      console.error('Failed to adapt loading screen:', error);
    }
  }

  destroy(): void {
    if (this._isDestroyed) return;

    try {
      this._isDestroyed = true;

      // Kill timelines
      if (this._globalTl) {
        this._globalTl.kill();
        this._globalTl = null;
      }

      if (this._loadingTl) {
        this._loadingTl.kill();
        this._loadingTl = null;
      }

      // Destroy bubbles
      if (this._bubbles) {
        this._bubbles.destroy();
        this._bubbles = null;
      }

      // Destroy loading sprite
      if (this._loading) {
        this._loading.destroy();
        this._loading = null;
      }

      // Call parent destroy
      super.destroy({ children: true });
    } catch (error) {
      console.error('Failed to destroy loading screen:', error);
    }
  }

  // Getters
  get id(): ScreenId {
    return this._id;
  }

  get isShow(): boolean {
    return this._isShow;
  }

  get globalTl(): gsap.core.Timeline | null {
    return this._globalTl;
  }

  get loadingTl(): gsap.core.Timeline | null {
    return this._loadingTl;
  }

  get bubbles(): UiBubbles | null {
    return this._bubbles;
  }

  get loading(): Sprite | null {
    return this._loading;
  }
}
