import { Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { CoinWhiteIcon, ExtraBaitsIcon, RibbonStarsIcon } from '@shared/assets';
import { NBSP } from '@shared/utils/helpers.utils';

interface Props {
  refScroll: Ref<HTMLDivElement>;
  day: number;
  active: boolean;
  classes?: string;
  coins: number;
  baits: number;
  pulse: boolean;
}
export const DailyBonusDetail = ({ refScroll, active, day, coins, baits, pulse, classes }: Props) => {
  const bgClass = active ? 'bg-primary-purpleDark' : 'bg-black/30 opacity-50';
  const { t } = useTranslation();
  return (
    <div
      ref={refScroll}
      className={`w-[30%] h-[60px] rounded-10 p-[4px_6px] flex flex-col gap-y-[2px] relative ${bgClass} ${classes ?? ''} ${pulse && 'animate-[shineButton_2s_linear_infinite] bg-shineClaimButton bg-no-repeat '}`}
    >
      <RibbonStarsIcon className="w-[20px] h-auto absolute right-[5px] top-[5px]" />
      <div className="text-[18px] font-bold">
        {t('global:day')} {day}
      </div>
      <div className="flex gap-x-[6px] items-center">
        {coins > 0 && (
          <div className="text-[12px] font-bold flex items-center">
            <CoinWhiteIcon width="16px" height="16px" />
            {NBSP} {coins}
          </div>
        )}
        {baits > 0 && (
          <div className="text-[12px] font-bold flex items-center">
            <ExtraBaitsIcon width="16px" height="16px" />
            {NBSP} {baits}
          </div>
        )}
      </div>
    </div>
  );
};
