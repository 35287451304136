import gsap from 'gsap';
import { Application, Container, ICanvas } from 'pixi.js';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import UiCounter from 'src/pixi/ui/UiCounter';
import UiCounterBlur from 'src/pixi/ui/UiCounter.blur';
import UiExplosion from 'src/pixi/ui/UiExplosion';
import UiTextComponent from 'src/pixi/ui/UiText';

const intervalShake: Record<number, [number, number]> = {
  1: [1, 1],
  2: [0.8, 1.2],
  3: [0.6, 1.4],
  4: [0.4, 1.6],
  5: [0.2, 1.8],
  6: [0.2, 2],
};

export default class MatchScreen extends Container {
  private readonly _id: ScreenId = ScreenId.Match;
  private readonly app: Application<ICanvas>;
  private _baseContainer: Container | null = null;
  private _counter: UiCounter | null = null;
  private _counterBlur: UiCounterBlur | null = null;
  private _text: UiTextComponent | null = null;
  private _explosion: UiExplosion | null = null;
  private _currentPercent: number = 0;
  private _shakeTl: gsap.core.Timeline | null = null;
  private _showAnimation: boolean = false;
  private _interval: number = 0;
  private _isDestroyed: boolean = false;

  constructor(app: Application<ICanvas>) {
    super();
    this.app = app;
    this.name = 'MatchScreen';

    try {
      this.initialize();
    } catch (error) {
      console.error('Failed to initialize match screen:', error);
    }
  }

  private initialize(): void {
    if (this._isDestroyed) return;

    try {
      this._baseContainer = new Container();
      this._counter = new UiCounter();
      this._text = new UiTextComponent('FISH GONE');
      this._counterBlur = new UiCounterBlur();
      this._explosion = new UiExplosion();
      this._shakeTl = gsap.timeline();

      if (
        this._baseContainer &&
        this._counterBlur?.view &&
        this._explosion?.view &&
        this._counter?.view &&
        this._text?.view
      ) {
        this._baseContainer.addChild(this._counterBlur.view, this._explosion.view, this._counter.view, this._text.view);
        this.addChild(this._baseContainer);
      }

      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize match components:', error);
    }
  }

  updateCounter(text: string, interval: number): void {
    if (this._isDestroyed || !this._counter || !this._counterBlur || !this._baseContainer) return;

    try {
      if (!this._showAnimation) {
        this.run();
        this._showAnimation = true;
      }

      const percent = Math.floor(Number(text));
      this._counter.updateCounter(text);
      this._counter.updateColor(interval);

      if (percent !== this._currentPercent) {
        this._currentPercent = percent;
        this._counterBlur.changeBlur(percent);
      }

      if (interval !== this._interval) {
        this._interval = interval;
        const [duration, intensive] = intervalShake[interval] || [1, 1];
        const { x: screenW } = ProducerCore.getSizeApp;
        const centerX = screenW / 2;

        gsap.to(this._baseContainer, { x: centerX, duration: 0 });
        if (this._shakeTl) {
          this._shakeTl.clear();
        }
        this.shake(duration, intensive);
      }
    } catch (error) {
      console.error('Failed to update counter:', error);
    }
  }

  shake(duration: number, intensity: number): gsap.core.Timeline | null {
    if (this._isDestroyed || !this._baseContainer) return null;

    try {
      this._shakeTl = gsap
        .timeline({
          repeat: -1,
          yoyo: true,
        })
        .to(this._baseContainer, {
          x: `+=${intensity}`,
          duration: duration / 4,
          ease: 'power1.inOut',
        })
        .to(this._baseContainer, {
          x: `-=${intensity * 2}`,
          duration: duration / 2,
          ease: 'power1.inOut',
        })
        .to(this._baseContainer, {
          x: `+=${intensity}`,
          duration: duration / 4,
          ease: 'power1.inOut',
        });

      return this._shakeTl;
    } catch (error) {
      console.error('Failed to create shake animation:', error);
      return null;
    }
  }

  moveToBottom(value: boolean): void {
    if (this._isDestroyed || !this._baseContainer) return;

    try {
      const { y: screenH } = ProducerCore.getSizeApp;
      const centerY = screenH / 2;
      const nextY = centerY + 30;

      gsap.to(this._baseContainer, {
        y: value ? nextY : centerY,
        duration: 0.3,
      });
    } catch (error) {
      console.error('Failed to move to bottom:', error);
    }
  }

  run(): void {
    if (this._isDestroyed || !this._counter || !this._counterBlur || !this._text) return;

    try {
      const tl = gsap.timeline();
      this._showAnimation = true;
      this._interval = 0;

      tl.add(this._text.stop(0), 0).add(this._counter.play(), 0.1).add(this._counterBlur.play(), 0.1);
    } catch (error) {
      console.error('Failed to run animations:', error);
    }
  }

  stop(fishGone: boolean): void {
    if (this._isDestroyed || !this._counter || !this._counterBlur || !this._explosion || !this._text || !this._shakeTl)
      return;

    try {
      const tl = gsap.timeline();
      this._interval = 0;
      this._showAnimation = false;

      this._shakeTl.clear();

      tl.add(this._counter.stop(), 0).add(this._counterBlur.stop(), 0);

      if (fishGone) {
        tl.add(this._explosion.play(), 0).add(this._text.play(), 0.5).add(this._text.stop(), 1.2);
      }

      this._currentPercent = 0;
    } catch (error) {
      console.error('Failed to stop animations:', error);
    }
  }

  show(): void {
    if (this._isDestroyed) return;

    try {
      gsap.to(this, { alpha: 1, duration: 0.2 });
    } catch (error) {
      console.error('Failed to show match screen:', error);
    }
  }

  hidden(): void {
    if (this._isDestroyed) return;

    try {
      gsap.to(this, { alpha: 0, duration: 0.2 });
    } catch (error) {
      console.error('Failed to hide match screen:', error);
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._baseContainer) return;

    try {
      const { x: screenW, y: screenH } = ProducerCore.getSizeApp;
      const centerX = screenW / 2;
      const centerY = screenH / 2;

      this._baseContainer.x = centerX;
      this._baseContainer.y = centerY;
    } catch (error) {
      console.error('Failed to adapt match screen:', error);
    }
  }

  destroy(): void {
    if (this._isDestroyed) return;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      if (this._baseContainer) {
        gsap.killTweensOf(this._baseContainer);
      }
      if (this._shakeTl) {
        this._shakeTl.kill();
        this._shakeTl = null;
      }
      gsap.killTweensOf(this);

      // Destroy components
      if (this._counter) {
        this._counter.destroy();
        this._counter = null;
      }

      if (this._counterBlur) {
        this._counterBlur.destroy();
        this._counterBlur = null;
      }

      if (this._text) {
        this._text.destroy();
        this._text = null;
      }

      if (this._explosion) {
        this._explosion.destroy();
        this._explosion = null;
      }

      if (this._baseContainer) {
        this._baseContainer.destroy({ children: true });
        this._baseContainer = null;
      }

      // Call parent destroy
      super.destroy({ children: true });
    } catch (error) {
      console.error('Failed to destroy match screen:', error);
    }
  }

  // Getters
  get id(): ScreenId {
    return this._id;
  }

  get baseContainer(): Container | null {
    return this._baseContainer;
  }

  get counter(): UiCounter | null {
    return this._counter;
  }

  get counterBlur(): UiCounterBlur | null {
    return this._counterBlur;
  }

  get text(): UiTextComponent | null {
    return this._text;
  }

  get explosion(): UiExplosion | null {
    return this._explosion;
  }

  get currentPercent(): number {
    return this._currentPercent;
  }

  get showAnimation(): boolean {
    return this._showAnimation;
  }

  get interval(): number {
    return this._interval;
  }
}
