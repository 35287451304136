import { useEffect, useState } from 'react';
import { animated, useSpring, useSpringRef } from 'react-spring';

import { LockedIcon } from '@shared/assets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { ESamples, useAudio } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { EGameButtonMode } from '@shared/types/global.types';
import { Loader } from '@shared/ui/Loader/Loader';

interface IProps {
  className?: string;
  view: boolean;
  disabled?: boolean;
  loading?: boolean;
  mode: EGameButtonMode;
  text: string;
  children?: React.ReactNode;
  showExtraContent?: boolean;
  pulse: boolean;
  onClick: (mode: EGameButtonMode) => void;
}
export const GameButton = ({
  className,
  disabled,
  loading,
  view,
  text,
  mode,
  children,
  showExtraContent,
  pulse = false,
  onClick,
}: IProps) => {
  const [scaleAnim, setScaleAnim] = useState(false);
  const { useAnimOpacity } = useAnimateSpring();
  const { vibration } = useUtils();
  const { samplePlay } = useAudio();
  const baseCenterDiv = 'flex justify-center items-center';
  const getTheme = (mode: EGameButtonMode) => {
    const defaultText = 'text-white font-bold text-[26px] uppercase text-center';
    switch (mode) {
      case EGameButtonMode.default:
        return `bg-[#424B50]/80 border-[#192F3C]/50 ${defaultText}`;
      case EGameButtonMode.fish:
        return `bg-[#4A8C48]/80 border-[#316130]/50 ${defaultText}`;
      case EGameButtonMode.fishGone:
        return `bg-[#9A2828]/80 border-[#9D2F2F]/50 ${defaultText}`;
    }
  };
  const styleTheme = getTheme(mode);
  const showButtonRef = useSpringRef();
  const showButton = useAnimOpacity(0, 1, { animRef: showButtonRef, duration: 300 });
  const clickHandler = () => {
    if (disabled || loading) return;

    samplePlay(ESamples.buttonClick);
    vibration('impactOccurred', 'heavy');
    setScaleAnim(true);
    onClick(mode);
    setTimeout(() => {
      setScaleAnim(false);
    }, 250);
  };
  const lockedIconStyle = useAnimOpacity(0, 1);
  useEffect(() => {
    view && showButtonRef.start();
  }, [view]);

  const [stylesContent, api] = useSpring(() => ({
    opacity: 1,
    transform: 'scale(1)',
    config: { duration: 300 },
  }));

  useEffect(() => {
    api.start({ opacity: 0, transform: 'scale(0.9)', immediate: true });
    api.start({ opacity: 1, transform: 'scale(1)', config: { duration: 300 } });
  }, [showExtraContent, api]);

  return (
    <animated.div
      style={showButton}
      className={`w-full max-w-[230px] h-[120px] rounded-40  p-[6px] bg-black/10 transition-transform ${disabled ? 'pointer-events-none' : ''} ${scaleAnim ? 'scale-95' : 'scale-100'} ${baseCenterDiv} ${className ?? ''} ${pulse && 'animate-pulseButton '}`}
      onClick={clickHandler}
    >
      {loading && <Loader full={true} classNameByFull="z-3" />}
      {disabled && (
        <animated.div
          style={lockedIconStyle}
          className="absolute !z-2 full-absolute flex justify-start items-start pl-[20px] pt-[15px]"
        >
          <LockedIcon width={15} height={15} />
        </animated.div>
      )}
      <div
        className={`w-full h-full border-white ${disabled ? 'opacity-80 ' : ''} ${baseCenterDiv}  rounded-40 border-[2px] relative overflow-hidden after:bg-radialGameButtonCircle after:absolute after:w-full after:h-full after:opacity-50`}
      >
        <div className={`w-full h-full  ${styleTheme} rounded-[37px] border-[8px] z-2`}>
          <animated.div style={stylesContent} className={`w-full h-full relative ${baseCenterDiv}`}>
            {showExtraContent ? children : text}
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
};
