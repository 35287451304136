import gsap from 'gsap';
import { Container, Text, TextStyle } from 'pixi.js';

const config = {
  style: new TextStyle({
    fontFamily: 'Inter',
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 26,
    align: 'center',
    fill: '0xffffff',
    dropShadow: true,
    dropShadowAlpha: 0.15,
    dropShadowAngle: 1.5,
    dropShadowBlur: 6,
  }),
};

export interface IButtonTextLang {
  fish: string;
  wait: string;
  tap: string;
  win: string;
  fishGone: string;
  loading: string;
}

export enum EButtonText {
  fish = 'fish',
  wait = 'wait',
  tap = 'tap',
  win = 'win',
  fishGone = 'fishGone',
  loading = 'loading',
}

export default class UiButtonText {
  private _view: Container | null = null;
  private _currentText: Text | null = null;
  private _shakeTl: gsap.core.Timeline | null = null;
  private _isDestroyed: boolean = false;

  private _fish: Text | null = null;
  private _wait: Text | null = null;
  private _tap: Text | null = null;
  private _win: Text | null = null;
  private _fishGone: Text | null = null;
  private _loading: Text | null = null;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'ButtonText';
      this._view.pivot.set(0.5);
    }
    return this._view;
  }

  get getGlobalPosition() {
    return this.view.getGlobalPosition();
  }

  constructor(lang: IButtonTextLang) {
    this.initializeTexts(lang);
  }

  private initializeTexts(lang: IButtonTextLang) {
    const { fish, wait, tap, win, fishGone, loading } = lang;
    const { style } = config;

    const loadingStyle = new TextStyle({
      ...style,
      fontSize: 25,
      lineHeight: 25,
    });

    this._fish = this.createText(fish, style, 'fish');
    this._wait = this.createText(wait, style, 'wait');
    this._tap = this.createText(tap, style, 'tap');
    this._win = this.createText(win, style, 'win');
    this._fishGone = this.createText(fishGone, style, 'fishGone');
    this._loading = this.createText(loading, loadingStyle, 'loading');

    this.view.addChild(this._fish, this._wait, this._tap, this._win, this._fishGone, this._loading);
  }

  private createText(content: string, style: TextStyle, name: string): Text {
    const text = new Text(content.toUpperCase(), style);
    text.alpha = 0;
    text.name = name;
    text.anchor.set(0.5);
    return text;
  }

  changeText(type: EButtonText, hidden?: boolean) {
    if (this._isDestroyed) return gsap.timeline();
    const tl = gsap.timeline();

    const targetText = this.getTextByType(type);
    if (!targetText) return tl;

    if (this._currentText) {
      tl.add(this.hideCurrentText());
    }

    if (!hidden) {
      tl.to(targetText, { alpha: 1, duration: 0.2 });
    }

    this._currentText = targetText;
    if (this._currentText) {
      this._currentText.scale.set(1);
    }

    this.hideOtherTexts();
    return tl;
  }

  private getTextByType(type: EButtonText): Text | null {
    switch (type) {
      case EButtonText.fish:
        return this._fish;
      case EButtonText.wait:
        return this._wait;
      case EButtonText.tap:
        return this._tap;
      case EButtonText.win:
        return this._win;
      case EButtonText.fishGone:
        return this._fishGone;
      case EButtonText.loading:
        return this._loading;
      default:
        return null;
    }
  }

  animateReduction(duration: number) {
    if (!this._currentText || this._isDestroyed) return gsap.timeline();
    const tl = gsap.timeline();

    tl.to(this._currentText.scale, {
      x: 0.3,
      y: 0.3,
      duration: duration,
      ease: 'power1.inOut',
      onComplete: () => {
        if (this._shakeTl) {
          this._shakeTl.kill();
        }
        if (this._wait) {
          this._wait.position.set(0);
        }
      },
    });

    tl.add(this.shake(0.4, 1.5), 0);
    return tl;
  }

  shake(duration: number, intensity: number) {
    if (!this._currentText || this._isDestroyed) return gsap.timeline();

    if (this._shakeTl) {
      this._shakeTl.kill();
    }

    this._shakeTl = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });

    this._shakeTl
      .to(this._currentText, {
        x: `+=${intensity}`,
        duration: duration / 4,
        ease: 'power1.inOut',
      })
      .to(this._currentText, {
        x: `-=${intensity * 2}`,
        duration: duration / 2,
        ease: 'power1.inOut',
      })
      .to(this._currentText, {
        x: `+=${intensity}`,
        duration: duration / 4,
        ease: 'power1.inOut',
      });

    return this._shakeTl;
  }

  hideCurrentText() {
    if (!this._currentText || this._isDestroyed) return gsap.timeline();
    const tl = gsap.timeline();
    tl.to(this._currentText, { alpha: 0, duration: 0.2 });
    return tl;
  }

  hideNewText(newType: EButtonText) {
    if (this._isDestroyed) return;

    if (this._currentText) {
      this._currentText.alpha = 0;
    }

    const newText = this.getTextByType(newType);
    if (newText) {
      this._currentText = newText;
      this._currentText.alpha = 0;
    }
  }

  hideOtherTexts() {
    if (!this._currentText || this._isDestroyed) return;

    const texts = [
      { text: this._fish, name: 'fish' },
      { text: this._wait, name: 'wait' },
      { text: this._tap, name: 'tap' },
      { text: this._win, name: 'win' },
      { text: this._fishGone, name: 'fishGone' },
      { text: this._loading, name: 'loading' },
    ];

    texts.forEach(({ text, name }) => {
      if (text && this._currentText && text.name !== this._currentText.name) {
        text.alpha = 0;
      }
    });
  }

  destroy() {
    this._isDestroyed = true;

    if (this._shakeTl) {
      this._shakeTl.kill();
      this._shakeTl = null;
    }

    gsap.killTweensOf([this._fish, this._wait, this._tap, this._win, this._fishGone, this._loading, this._currentText]);

    if (this._view) {
      this._view.destroy({ children: true });
      this._view = null;
    }

    this._currentText = null;
    this._fish = null;
    this._wait = null;
    this._tap = null;
    this._win = null;
    this._fishGone = null;
    this._loading = null;
  }
}
