import i18n from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EI18Lang } from '@shared/configs/i18n/i18n';
import { useAudio } from '@shared/hooks/integration/useAudio';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { ECommonModals, selectViewCommonModal, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { Button, Wrapper } from '@shared/ui';
import { Select, TSelectItemOption } from '@shared/ui/Select/Select';
import { Switch } from '@shared/ui/Switch/Switch';
import { getValue, openLink } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets';

export const SettingsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getLocale } = useUtils();
  const { setItem, getItem } = useStorage();
  const { soundMute, samplesMute } = useAudio();
  const viewSettings = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.settings));
  const [enabledSound, setEnabledSound] = useState(true);
  const [enabledSamples, setEnabledSamples] = useState(true);
  const [enabledVibration, setEnabledVibration] = useState(true);
  const [selectedLang, setSelectedLang] = useState<TSelectItemOption['value']>();

  // https://simplelocalize.io/data/languages/
  const langsList: TSelectItemOption[] = [
    { key: EI18Lang.en, value: EI18Lang.en, label: 'English' },
    { key: EI18Lang.ru, value: EI18Lang.ru, label: 'Русский' },
    { key: EI18Lang.fr, value: EI18Lang.fr, label: 'French' },
    { key: EI18Lang.esMx, value: EI18Lang.esMx, label: 'Español' },
    { key: EI18Lang.hi, value: EI18Lang.hi, label: 'Hindi' },
    { key: EI18Lang.id, value: EI18Lang.id, label: 'Indonesian' },
  ];

  useEffect(() => {
    const loadSettings = async () => {
      const loadLocale = async () => {
        const lang = await getLocale();
        setSelectedLang(lang);
      };

      const [soundValue, samplesValue, vibrationValue] = await Promise.all([
        getItem(EKeyStorage.enabledSound),
        getItem(EKeyStorage.enabledSamples),
        getItem(EKeyStorage.enabledVibration),
      ]);

      if (soundValue !== null) {
        const enabled = !!soundValue;
        setEnabledSound(enabled);
        soundMute(!enabled);
      }

      if (samplesValue !== null) {
        const enabled = !!samplesValue;
        setEnabledSamples(enabled);
        samplesMute(!enabled);
      }

      if (vibrationValue !== null) {
        setEnabledVibration(!!vibrationValue);
      }

      await loadLocale();
    };

    loadSettings();
  }, []);

  const closeSettingHandler = () => {
    dispatch(setViewCommonModals({ modal: ECommonModals.settings, value: false }));
  };

  const handleChangeSound = useCallback(async (nextChecked: boolean) => {
    setEnabledSound(nextChecked);
    await setItem(EKeyStorage.enabledSound, nextChecked);
    soundMute(!nextChecked);
  }, []);

  const handleChangeSamples = useCallback(async (nextChecked: boolean) => {
    setEnabledSamples(nextChecked);
    await setItem(EKeyStorage.enabledSamples, nextChecked);
    samplesMute(!nextChecked);
  }, []);

  const handleChangeVibration = useCallback((nextChecked: boolean) => {
    setEnabledVibration(nextChecked);
    setItem(EKeyStorage.enabledVibration, nextChecked);
  }, []);
  const handleChangeLanguage = useCallback((value: TSelectItemOption['value']) => {
    setSelectedLang(value);
    setItem(EKeyStorage.currentLang, value);
    i18n.changeLanguage(value as EI18Lang);
  }, []);

  return (
    <>
      <Modal
        isOpen={viewSettings}
        title={t('farming:settings.modal.title')}
        onClose={closeSettingHandler}
        className="z-50"
      >
        <div className="flex flex-col gap-y-[10px]">
          <Wrapper title={t('farming:settings.modal.language')}>
            <Select options={langsList} onChange={handleChangeLanguage} selected={selectedLang} />
          </Wrapper>
          <Wrapper>
            <div className="flex justify-between items-center">
              <span className="font-bold">{t('farming:settings.modal.sounds')}</span>
              <Switch
                className="max-w-[130px]"
                size="sm"
                checked={enabledSound}
                onChecked={handleChangeSound}
                checkedDiv={t('global:on')}
                unCheckedDiv={t('global:off')}
              />
            </div>
          </Wrapper>
          <Wrapper>
            <div className="flex justify-between items-center">
              <span className="font-bold">{t('farming:settings.modal.samples')}</span>
              <Switch
                className="max-w-[130px]"
                size="sm"
                checked={enabledSamples}
                onChecked={handleChangeSamples}
                checkedDiv={t('global:on')}
                unCheckedDiv={t('global:off')}
              />
            </div>
          </Wrapper>
          <Wrapper>
            <div className="flex justify-between items-center">
              <span className="font-bold">{t('farming:settings.modal.vibration')}</span>
              <Switch
                className="max-w-[130px]"
                size="sm"
                checked={enabledVibration}
                onChecked={handleChangeVibration}
                checkedDiv={t('global:on')}
                unCheckedDiv={t('global:off')}
              />
            </div>
          </Wrapper>
          <Button size="lg" onClick={() => openLink(getValue(import.meta.env.VITE_APP_SUPPORT_LINK, ''))}>
            {t('global:support')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
