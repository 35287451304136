import { ILeaderboardMe, ILeaderboardUsers, LeaderboardLeague, LeaderboardType } from '@hf/shared-common';

import { serverAPI } from '@shared/api/api';

export const leaderBoardApi = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    leaderboardUsers: build.query<
      ILeaderboardUsers,
      { offset: number; league: LeaderboardLeague; type: LeaderboardType }
    >({
      query: ({ offset = 0, league = LeaderboardLeague.BRONZE, type = LeaderboardType.GLOBAL }) => ({
        url: `/leaderboard?offset=${offset}&league=${league}&type=${type}`,
      }),
      providesTags: ['leaderBoard'],
    }),

    leaderboardMe: build.query<ILeaderboardMe, { type?: LeaderboardType }>({
      query: ({ type = LeaderboardType.GLOBAL }) => ({
        url: `/leaderboard/me?type=${type}`,
      }),
    }),
  }),
});
