import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EKeyStorage } from '@shared/types/storage.types';
import { getBoolean } from '@shared/utils/helpers.utils';

export interface IApiError {
  error: string;
  message: string;
  statusCode: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_APP_BACKEND_SERVER_URL}/api`,
  prepareHeaders: (headers) => {
    const isNoTelegramMode = getBoolean(import.meta.env.VITE_APP_WEB_TELEGRAM_MODE, false);
    const user = window.Telegram?.WebApp?.initDataUnsafe?.user;
    const userName = import.meta.env.VITE_APP_TELEGRAM_USER_NAME ?? user?.username;
    const dataCheckString = localStorage.getItem(EKeyStorage.dataCheckString);

    if (isNoTelegramMode) {
      headers.set('data-check-string', userName);
      return headers;
    }

    if (dataCheckString) {
      headers.set('data-check-string', dataCheckString);
    }
    return headers;
  },
});

export const serverAPI = createApi({
  reducerPath: 'serverAPI',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    healthInfo: build.query<boolean, void>({
      query: () => ({
        url: `/app/health`,
      }),
    }),
  }),
  tagTypes: ['leaderBoard'],
});
