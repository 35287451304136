import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AD_REWARD_BAITS, AD_REWARD_COINS, AmplitudeEventClient, ITaskAdInfo } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { BaitsIcon, CoinIcon, WatchCoin } from '@shared/assets';
import { useAdsGram } from '@shared/hooks/integration/useAdsGram';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { setRewardsModal } from '@shared/store/slice/TaskSlice';
import { useAppDispatch } from '@shared/store/store';
import { ShowPromiseResult } from '@shared/types/adsGram.types';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { ETaskCustomType } from '@shared/types/taskList.types';
import { Button, Wrapper } from '@shared/ui';

interface Props {
  adsInfo?: ITaskAdInfo;
  blockId: string;
  setAvailableTaskCount: Dispatch<SetStateAction<number>>;
}

export const TaskCardAds = ({ adsInfo, blockId, setAvailableTaskCount }: Props) => {
  const { t } = useTranslation();
  const { ga4Event } = useGa4();
  const { updateProfileCoins, updateFishingBaits } = useUtils();
  const dispatch = useAppDispatch();

  const coins = AD_REWARD_COINS;
  const baits = AD_REWARD_BAITS;

  const onRewardAdsGram = useCallback(async () => {
    ga4Event(EGa4Event.AdsWatched, EGa4Category.Earn, '');
    amplitude.track(AmplitudeEventClient.UserRewarded, {
      task_type: ETaskCustomType.ADS_GRAM,
      added_tries: baits,
      reward_size: coins,
      content: 'View ads gram',
    });
    dispatch(
      setRewardsModal({
        isOpen: true,
        title: t('task:modal.adsGram.reward.title'),
        description: t('task:modal.adsGram.reward.description'),
        btnText: t('global:close'),
        coins: coins,
        baits: baits,
        taskType: ETaskCustomType.ADS_GRAM,
      }),
    );

    updateProfileCoins(coins);
    updateFishingBaits(null, baits);
    setAvailableTaskCount((prev) => prev - 1);
    checkAds();
  }, []);

  const onErrorAdsGram = useCallback((result: ShowPromiseResult) => {
    console.error(`Error watching ads: ${result.description}`);
    if (result) toast.error(result.description);
  }, []);

  const {
    enabledAdsGram,
    disabled: disabledAds,
    loading: loadingAds,
    timeLeft,
    watchAds,
    checkAds,
  } = useAdsGram({
    blockId,
    onReward: onRewardAdsGram,
    onError: onErrorAdsGram,
  });

  useEffect(() => {
    checkAds();
  }, []);

  const handleClick = () => {
    amplitude.track(AmplitudeEventClient.TaskStarted, { task_type: ETaskCustomType.ADS_GRAM });
    watchAds();
  };

  if (!enabledAdsGram) return null;

  return (
    <Wrapper paddingSize="sm">
      <li className="w-full flex items-center justify-center px-[5px] py-[3px] gap-[10px] bg-blue-dark rounded-[10px]">
        <div className="flex-1 flex gap-[6px]">
          <div className="flex items-center text-blue-light w-[1.25em] h-auto justify-center">
            <WatchCoin className="w-5 h-5" />
          </div>
          <div className="flex-1 flex flex-col">
            <p className="font-bold text-[13px]">{t('task:list.adsGram.text')}</p>
            {timeLeft ? (
              <p className="text-[11px]">{timeLeft}</p>
            ) : (
              <p className="text-[13px] flex items-center gap-x-1">
                <span>+ {coins}</span>
                <CoinIcon className="w-[14px] h-[14px]" />
                <span>+ {baits}</span>
                <BaitsIcon className="w-[14px] h-[14px]" />
              </p>
            )}
          </div>
        </div>
        <Button
          className={`w-24 ${disabledAds ? 'disabled:bg-sand-light/50 hover:bg-sand-dark' : ''}`}
          size="sm"
          disabled={disabledAds || !!timeLeft}
          onClick={handleClick}
          loading={loadingAds}
        >
          {disabledAds || !!timeLeft ? t('global:notActive') : t('global:watch')}
        </Button>
      </li>
    </Wrapper>
  );
};
