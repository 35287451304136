import { ReactNode } from 'react';

import { SoonLabel } from '@shared/ui/SoonLabel/SoonLabel';

type WrapperTheme = 'light' | 'dark' | 'darkBlue';
type PaddingSize = 'sm' | 'md' | 'lg';
type WrapperStyle = { themeStyle?: string };
interface Props {
  children?: ReactNode;
  className?: string;
  theme?: WrapperTheme;
  paddingSize?: PaddingSize;
  title?: string;
  soon?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
}
export const Wrapper = ({ children, className, soon, theme, title, paddingSize, onClick }: Props) => {
  const getThemeStyle = (): WrapperStyle => {
    switch (theme) {
      case 'light':
        return {
          themeStyle: 'bg-white/5 border border-white/10',
        };
      case 'darkBlue':
        return {
          themeStyle: 'bg-primary-darkBlue/85 border border-white/10',
        };
      default:
        return {
          themeStyle: 'bg-black/10',
        };
    }
  };
  const getPaddingSize = (): string => {
    switch (paddingSize) {
      case 'sm':
        return 'p-[5px]';
      case 'md':
        return 'p-[10px]';
      case 'lg':
        return 'p-[15px]';
      default:
        return 'p-[10px]';
    }
  };
  const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
    onClick && onClick(event);
  };
  return (
    <div
      className={`rounded-[15px] relative  w-full  ${getPaddingSize()} ${getThemeStyle().themeStyle} ${className ?? ''}`}
      onClick={(event) => onClickHandler(event)}
    >
      {soon && <SoonLabel className="rounded-[15px]" />}
      {title && (
        <div className={`text-[15px] font-bold text-white text-center ${children ? ' mb-[5px]' : ''} `}>{title}</div>
      )}
      {children}
    </div>
  );
};
