import { ILeaderboardMe, ILeaderboardUsers, LeaderboardLeague, LeaderboardType } from '@hf/shared-common';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILeaderBoardList } from '@shared/types/leaderBoard.type';

interface LeaderBoardState {
  type: LeaderboardType;
  league: LeaderboardLeague;
  list: ILeaderBoardList;
  leaderboardMe: ILeaderboardMe | undefined;
  initStart: boolean;
}
const initState: LeaderBoardState = {
  type: LeaderboardType.GLOBAL,
  league: LeaderboardLeague.BRONZE,
  leaderboardMe: undefined,
  initStart: true,
  list: {
    [LeaderboardType.GLOBAL]: {
      [LeaderboardLeague.BRONZE]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.SILVER]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.GOLD]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.PLATINUM]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.DIAMOND]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
    },
    [LeaderboardType.WEEKLY]: {
      [LeaderboardLeague.BRONZE]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.SILVER]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.GOLD]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.PLATINUM]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
      [LeaderboardLeague.DIAMOND]: {
        items: undefined,
        offset: 0,
        hasMore: false,
        lastOffset: undefined,
        scrollTop: 0,
      },
    },
  },
};

export const leaderBoardSlice = createSlice({
  name: 'leaderBoardSlice',
  initialState: initState,
  selectors: {
    selectCurrentType: (state: LeaderBoardState) => state.type,
    selectCurrentLeague: (state: LeaderBoardState) => state.league,
    selectCurrentList: (state: LeaderBoardState) => state.list[state.type][state.league].items?.leaderboard,
    selectCurrentLeaderBoard: (state: LeaderBoardState) => state.list[state.type][state.league].items,
    selectCurrentOffset: (state: LeaderBoardState) => state.list[state.type][state.league].offset,
    selectCurrentLastOffset: (state: LeaderBoardState) => state.list[state.type][state.league].lastOffset,
    selectCurrentScrollTop: (state: LeaderBoardState) => state.list[state.type][state.league].scrollTop,
    selectCurrentHasMore: (state: LeaderBoardState) => state.list[state.type][state.league].hasMore,
    selectLeaderboardMe: (state: LeaderBoardState) => state.leaderboardMe,
    selectInitStart: (state: LeaderBoardState) => state.initStart,
  },
  reducers: {
    setCurrentType(state, action: PayloadAction<LeaderboardType>) {
      state.type = action.payload;
    },
    setCurrentLeague(state, action: PayloadAction<LeaderboardLeague>) {
      state.league = action.payload;
    },
    setCurrentOffset(state, action: PayloadAction<number>) {
      state.list[state.type][state.league].offset = action.payload;
    },
    setCurrentLastOffset(state, action: PayloadAction<number>) {
      state.list[state.type][state.league].lastOffset = action.payload;
    },
    setCurrentScrollTop(state, action: PayloadAction<number>) {
      state.list[state.type][state.league].scrollTop = action.payload;
    },
    setCurrentHasMore(state, action: PayloadAction<boolean>) {
      state.list[state.type][state.league].hasMore = action.payload;
    },
    setLeaderboardMe(state, action: PayloadAction<ILeaderboardMe>) {
      state.leaderboardMe = action.payload;
    },
    resetStateLeaderboard() {
      return initState;
    },
    setLeaderBoardList(state, action: PayloadAction<ILeaderboardUsers>) {
      const { userData, leaderboard, leaderboardLeague, leaderboardType } = action.payload;
      const oldLeaderboard = state.list[state.type][state.league].items?.leaderboard ?? [];

      state.list[state.type][state.league].items = {
        userData,
        leaderboard: [...oldLeaderboard, ...leaderboard],
        leaderboardLeague,
        leaderboardType,
      };
    },
    setLeaderboardInitStart(state, action: PayloadAction<boolean>) {
      state.initStart = action.payload;
    },
  },
});
export const {
  setCurrentType,
  setCurrentLeague,
  setLeaderBoardList,
  setCurrentOffset,
  setLeaderboardMe,
  setCurrentScrollTop,
  setCurrentLastOffset,
  setCurrentHasMore,
  resetStateLeaderboard,
  setLeaderboardInitStart,
} = leaderBoardSlice.actions;
export const {
  selectLeaderboardMe,
  selectCurrentType,
  selectCurrentLeague,
  selectCurrentList,
  selectCurrentOffset,
  selectCurrentScrollTop,
  selectCurrentLastOffset,
  selectCurrentHasMore,
  selectCurrentLeaderBoard,
  selectInitStart,
} = leaderBoardSlice.selectors;
