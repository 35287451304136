import { useTranslation } from 'react-i18next';

import { MAX_CATCHES_PER_PLAYER } from '@hf/shared-common';

import {
  EMatchButtons,
  EMatchModals,
  selectCurrentRound,
  selectDisabledMatchButton,
  selectLoadingMatchButton,
  selectRemainingBaits,
  selectViewMatchModal,
} from '@shared/store/slice/MatchSlice';
import { useAppSelector } from '@shared/store/store';
import { Button, ProgressBar } from '@shared/ui';
import { Modal } from '@shared/widgets';

interface IProps {
  progress: number;
  showProgress: boolean;
  confirmNextRound: (value: boolean) => void;
}
export const ConfirmationRoundModal = ({ progress, showProgress, confirmNextRound }: IProps) => {
  const { t } = useTranslation();
  const isOpen = useAppSelector((state) => selectViewMatchModal(state, EMatchModals.confirmationRound));
  const currentRound = useAppSelector(selectCurrentRound);
  const remainingBaits = useAppSelector(selectRemainingBaits);
  const isLoadingUseBaits = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.useBaits));
  const isDisabledUseBaits = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.useBaits));
  const confirmHandler = (value: boolean) => {
    if (isLoadingUseBaits || isDisabledUseBaits) return;
    confirmNextRound(value);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t('multiplayer:modal.confirmationRound.title')}
        heightAuto={true}
        isButtonClose={false}
      >
        <span className="text-center font-bold text-[16px]">
          {t('global:baits')} {remainingBaits}/{MAX_CATCHES_PER_PLAYER}
        </span>
        <ProgressBar
          progress={progress}
          className={`h-[10px] transition-opacity ${!showProgress ? 'opacity-0' : ''}`}
          classNameContent="bg-primary-blueExtraDark"
        />
        <div className="flex justify-center gap-x-[10px]">
          <Button
            className="h-[50px]"
            size="lg"
            loading={isLoadingUseBaits}
            disabled={isDisabledUseBaits}
            theme="red"
            onClick={() => confirmHandler(false)}
          >
            {t('global:skip')}
          </Button>
          <Button
            className="h-[50px]"
            size="lg"
            loading={isLoadingUseBaits}
            disabled={isDisabledUseBaits}
            theme="green"
            onClick={() => confirmHandler(true)}
          >
            {t('global:fish')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
