import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { Container } from 'pixi.js';

interface IComponentCounterConfig {
  gapX: number; // Расстояние между цифрами
  styleText: PIXI.TextStyle; // Стиль текста
  dropShadowColor: { [key: number]: string }; // Цвет тени
  strokeColor: { [key: number]: string }; // Цвет обводки
  defaultScale: { x: number; y: number };
  defaultShow: number;
}

const defaultConfig: IComponentCounterConfig = {
  gapX: 70,
  styleText: new PIXI.TextStyle({
    fontFamily: 'Inter',
    fontSize: 90,
    fontWeight: 'bold',
    fill: 0xffffff,
    // dropShadow: true,
    // dropShadowAlpha: 0.6,
    // dropShadowAngle: 0.4,
    // dropShadowBlur: 6,
    // dropShadowDistance: 6,
    // dropShadowColor: '0x000000',
    // stroke: '0x000000',
    // strokeThickness: 2,
  }),
  dropShadowColor: {
    1: '0x000000',
    2: '0x397DE3',
    3: '0x2C61E8',
    4: '0x3700FF',
    5: '0x5F00FF',
    6: '0x5F00FF',
  },
  strokeColor: {
    1: '0x000000',
    2: '0x2154D9',
    3: '0x2154D9',
    4: '0x2500A1',
    5: '0x5F00FF',
    6: '0x5F00FF',
  },
  defaultScale: { x: 0, y: 0 },
  defaultShow: 0,
};

class DigitPool {
  private static instance: DigitPool;
  private pool: Map<string, PIXI.Text[]> = new Map();
  private readonly poolSize = 10;

  static getInstance(): DigitPool {
    if (!DigitPool.instance) {
      DigitPool.instance = new DigitPool();
    }
    return DigitPool.instance;
  }

  acquireDigit(digit: string, style: PIXI.TextStyle): PIXI.Text {
    const digits = this.pool.get(digit) || [];
    const reusableDigit = digits.pop();

    if (reusableDigit) {
      return reusableDigit;
    }

    const newDigit = new PIXI.Text(digit, style);
    newDigit.name = `digit-${digit}`;
    return newDigit;
  }

  releaseDigit(digit: PIXI.Text): void {
    const key = digit.text;
    const digits = this.pool.get(key) || [];

    if (digits.length < this.poolSize) {
      digits.push(digit);
      this.pool.set(key, digits);
    } else {
      digit.destroy();
    }
  }

  clear(): void {
    this.pool.forEach((digits) => {
      digits.forEach((digit) => digit.destroy());
    });
    this.pool.clear();
  }
}

export default class UiCounter {
  private _view: Container;
  private _digitContainers: Container[] = [];
  private _currentDigits: string[] = ['0', '0', '.', '0', '0'];
  private readonly _config: IComponentCounterConfig;
  private _isDestroyed: boolean = false;
  private readonly _digitPool: DigitPool;
  private _lastUpdateTime: number = 0;
  private readonly _updateThreshold: number = 16;

  get view(): Container {
    return this._view;
  }

  constructor() {
    this._config = { ...defaultConfig };
    this._digitPool = DigitPool.getInstance();
    this._view = new Container();
    this._view.name = 'Counter';
    this.initialize();
  }

  private initialize(): void {
    if (this._isDestroyed) return;

    try {
      this.initCounter();
      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize counter:', error);
    }
  }

  private initCounter(): void {
    if (this._isDestroyed) return;

    try {
      let accWeight = 0;

      for (let i = 0; i < 5; i++) {
        const container = new Container();
        container.name = `digit-container-${i}`;

        if (i > 0) {
          const prevContainerWidth = this._digitContainers[i - 1]?.width ?? 0;
          accWeight += i === 3 ? Math.ceil(prevContainerWidth / 10) * 10 : this._config.gapX;
        }

        container.x = accWeight;

        const initialDigit = this._digitPool.acquireDigit(this._currentDigits[i], this._config.styleText);
        if (initialDigit && container) {
          container.addChild(initialDigit);
        }

        this._digitContainers[i] = container;
        if (this._view) {
          this._view.addChild(container);
        }
      }

      this._view.alpha = this._config.defaultShow;
    } catch (error) {
      console.error('Failed to initialize counter:', error);
    }
  }

  updateCounter(text: string): void {
    if (this._isDestroyed || text.length < 5) return;

    const now = Date.now();
    if (now - this._lastUpdateTime < this._updateThreshold) return;
    this._lastUpdateTime = now;

    try {
      const digits = [text[0], text[1], '.', text[3], text[4]];

      for (let i = 0; i < 5; i++) {
        if (digits[i] !== this._currentDigits[i]) {
          const container = this._digitContainers[i];
          if (!container) continue;

          const oldDigit = container.children[0] as PIXI.Text;
          if (oldDigit) {
            container.removeChild(oldDigit);
            this._digitPool.releaseDigit(oldDigit);
          }

          const newDigit = this._digitPool.acquireDigit(digits[i], this._config.styleText);
          container.addChild(newDigit);
          this._currentDigits[i] = digits[i];
        }
      }
    } catch (error) {
      console.error('Failed to update counter:', error);
    }
  }

  updateColor(interval: number): void {
    if (this._isDestroyed || !this._config) return;

    try {
      const dropShadowColor = this._config.dropShadowColor[interval];
      const strokeColor = this._config.strokeColor[interval];

      this._digitContainers.forEach((container) => {
        if (!container) return;

        const text = container.children[0];
        if (text instanceof PIXI.Text) {
          // Commented out as per original code
          // text.style.dropShadowColor = dropShadowColor;
          // text.style.stroke = strokeColor;
        }
      });
    } catch (error) {
      console.error('Failed to update color:', error);
    }
  }

  play(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      this.view.scale.set(this._config.defaultScale.x, this._config.defaultScale.y);
      const tl = gsap.timeline();

      tl.to(this.view, { alpha: 1, duration: 0.3 }, 0).to(this.view.scale, { x: 1, y: 1, duration: 2 }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to play animation:', error);
      return gsap.timeline();
    }
  }

  stop(duration = 0.4): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      tl.to(this.view.scale, { x: 0, y: 0, duration }, 0).to(this.view, { alpha: 0, duration }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to stop animation:', error);
      return gsap.timeline();
    }
  }

  animateScale(): void {
    if (this._isDestroyed) return;

    try {
      const lt = gsap.timeline();

      lt.addLabel('start', 0)
        .to(this.view.scale, { x: 1, y: 1, duration: 0.2, ease: 'power1.inOut' }, 'start')
        .to(this.view.scale, { x: '+=0.25', y: '+=0.25', duration: 0.2, ease: 'power1.inOut' }, 'start')
        .addLabel('down', 0.2)
        .to(this.view.scale, { x: '-=0.4', y: '-=0.4', duration: 0.2, ease: 'power1.inOut' }, 'down')
        .to(this.view, { y: '-=5', duration: 0.2, ease: 'power1.inOut' }, 'down')
        .addLabel('up', 0.4)
        .to(this.view.scale, { x: 1, y: 1, duration: 0.2, ease: 'power1.inOut' }, 'up')
        .to(this.view, { y: '+=5', duration: 0.2, ease: 'power1.inOut' }, 'up');
    } catch (error) {
      console.error('Failed to animate scale:', error);
    }
  }

  destroy(): boolean {
    if (this._isDestroyed) return true;

    try {
      this._isDestroyed = true;

      // Kill any ongoing GSAP animations
      if (this._view) {
        gsap.killTweensOf(this._view);
        gsap.killTweensOf(this._view.scale);
      }

      // Clean up digit containers and their contents
      this._digitContainers.forEach((container) => {
        if (container) {
          const digit = container.children[0] as PIXI.Text;
          if (digit) {
            this._digitPool.releaseDigit(digit);
          }
          container.destroy({ children: false });
        }
      });
      this._digitContainers = [];

      // Clean up view
      if (this._view) {
        this._view.destroy({ children: true });
        this._view = new Container();
      }

      return true;
    } catch (error) {
      console.error('Failed to destroy counter:', error);
      return false;
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._view) return;

    try {
      this._view.pivot.set(this._view.width / 2, this._view.height / 2);
    } catch (error) {
      console.error('Failed to adapt counter:', error);
    }
  }
}
