import { IReferralEarningInfo, IReferralLink } from '@hf/shared-common';

import { serverAPI } from '@shared/api/api';

export const referralAPI = serverAPI.injectEndpoints({
  endpoints: (build) => ({
    referralInfo: build.query<IReferralEarningInfo, void>({
      query: () => ({
        url: `/referral/earnings`,
      }),
    }),
    referralLink: build.query<IReferralLink, void>({
      query: () => ({
        url: `/referral/link`,
      }),
    }),
  }),
});
