import { useEffect, useState } from 'react';

import { Loader } from '@shared/ui/Loader/Loader';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  isLoader?: boolean;
  className?: string;
  src?: string;
  onLoading?: (loading: boolean) => void;
}

export const Image = ({ isLoader = true, className, src, onLoading, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSrc, setCurrentSrc] = useState(src);
  useEffect(() => {
    onLoading && onLoading(true);
    setIsLoading(true);
    setCurrentSrc(src);
  }, [src]);
  return (
    <>
      <div className={`w-full h-auto relative overflow-hidden ${className ?? ''}`}>
        {isLoader && isLoading && <Loader full={true} classNameByFull="backdrop-blur-sm" />}
        <img
          src={currentSrc}
          className="w-full h-auto"
          onLoad={() => {
            onLoading && onLoading(false);
            setIsLoading(false);
          }}
        />
      </div>
    </>
  );
};
