import { useTranslation } from 'react-i18next';

import { CoinIcon, WeightFishIcon } from '@shared/assets';
import { NBSP } from '@shared/utils/helpers.utils';

interface IProps {
  className?: string;
  yourCatch: string;
  coins: string;
  fishes: string;
}

export const GameButtonResult = ({ className, yourCatch, coins, fishes }: IProps) => {
  const { t } = useTranslation();
  const classItem = 'bg-black/10 border border-white/10 items-center justify-evenly';
  return (
    <div
      className={`w-full h-full p-[4px] flex flex-col justify-center gap-y-[4px] !text-[12px] font-bold  ${className ?? ''}`}
    >
      <div
        className={`flex w-full h-[60%]  gap-x-[5px] rounded-tl-30 rounded-tr-30 rounded-b-10 text-[14px] ${classItem}`}
      >
        <span>{t('global:yourCatch')}</span>
        <span> {yourCatch}</span>
      </div>
      <div className={`flex w-full h-[40%] justify-center gap-x-[4px]  `}>
        <div className={`w-full flex rounded-t-10 rounded-bl-30 rounded-br-10  ${classItem} !justify-center`}>
          {coins}
          {NBSP}
          <CoinIcon width={18} height={18} />
        </div>
        <div className={`w-full flex rounded-t-10 rounded-bl-10 rounded-br-30  ${classItem} !justify-center`}>
          {fishes}
          {NBSP}
          <WeightFishIcon width={28} height={28} />
        </div>
      </div>
    </div>
  );
};
