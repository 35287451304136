import { UseSpringProps, easings, to, useSpring } from 'react-spring';

interface IAnimateSpringParams {
  animRef?: UseSpringProps['ref'];
  duration?: number;
  loop?: boolean;
  reverse?: boolean;
  delay?: number;
  easing?: (t: number) => number;
}

interface IAnimateSpringTranslate extends IAnimateSpringParams {
  yStart?: string;
  yEnd?: string;
  xStart?: string;
  xEnd?: string;
}
export const useAnimateSpring = () => {
  const useAnimTranslateX = (
    value: boolean,
    params: IAnimateSpringTranslate = {
      duration: 200,
      easing: easings.easeInCubic,
      loop: false,
      xStart: '0',
      xEnd: '-50%',
    },
  ) => {
    const { duration, animRef, loop, xStart, xEnd, easing } = params;
    const springProps = useSpring({
      ref: animRef,
      opacity: value ? 1 : 0,
      transform: value ? `translateX(${xStart})` : `translateX(${xEnd})`,
      config: { duration, easing },
      loop: loop,
    });

    return {
      ...springProps,
      pointerEvents: to([springProps.opacity], (opacity) => (opacity > 0 ? 'auto' : 'none')),
    };
  };

  const useAnimTranslateY = (
    value: boolean,
    params: IAnimateSpringTranslate = {
      duration: 200,
      easing: easings.easeInCubic,
      loop: false,
      yStart: '0',
      yEnd: '-50%',
    },
  ) => {
    const { duration, animRef, loop, yStart, yEnd, easing } = params;
    const springProps = useSpring({
      ref: animRef,
      opacity: value ? 1 : 0,
      transform: value ? `translateY(${yStart})` : `translateY(${yEnd})`,
      config: { duration, easing },
      loop: loop,
    });

    return {
      ...springProps,
      pointerEvents: to([springProps.opacity], (opacity) => (opacity > 0 ? 'auto' : 'none')),
    };
  };

  const useAnimOpacity = (
    from: number = 1,
    to: number = 0,
    params: IAnimateSpringParams = { duration: 200, easing: easings.easeInCubic, loop: false },
  ) => {
    const { duration, animRef, loop, reverse, delay, easing } = params;
    return useSpring({
      ref: animRef,
      from: {
        zIndex: '-1',
        opacity: from,
      },
      to: {
        zIndex: 'auto',
        opacity: to,
      },
      config: { duration, easing },
      loop: loop,
      delay,
      reverse,
    });
  };

  const useAnimMarginTop = (
    from: number = 0,
    to: number = 0,
    params: IAnimateSpringParams = { duration: 200, easing: easings.easeInCubic, loop: false },
  ) => {
    const { duration, animRef, loop, easing } = params;
    return useSpring({
      ref: animRef,
      from: { marginTop: from },
      to: { marginTop: to },
      config: { duration, easing },
      loop,
    });
  };

  const useAnimRotate = (
    from: number = 0,
    to: number = 0,
    params: IAnimateSpringParams = { duration: 200, easing: easings.easeInCubic, loop: false },
  ) => {
    const { duration, animRef, loop, easing } = params;
    return useSpring({
      ref: animRef,
      from: { transform: `rotate(${from}deg)` },
      to: { transform: `rotate(${to}deg)}` },
      config: { duration, easing },
      loop,
    });
  };

  const useAnimScale = (
    value: boolean,
    from: number = 0.1,
    toAnim: number = 1,
    params: IAnimateSpringParams = { duration: 200, easing: easings.easeInCubic, loop: false },
  ) => {
    const { duration, animRef, loop, easing } = params;
    const springProps = useSpring({
      ref: animRef,
      from: {
        opacity: 0,
        transform: `scale(${from})`,
      },
      to: {
        opacity: value ? 1 : 0,
        transform: value ? `scale(${toAnim})` : `scale(${from})`,
      },
      config: { duration, easing },
      loop,
    });
    return {
      ...springProps,
      pointerEvents: to([springProps.opacity], (opacity) => (opacity > 0 ? 'auto' : 'none')),
    };
  };

  return { useAnimTranslateX, useAnimTranslateY, useAnimOpacity, useAnimMarginTop, useAnimRotate, useAnimScale };
};
