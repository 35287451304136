import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AmplitudeEventClient, TaskExtLinkType, TaskExtUserStatus } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { IApiError } from '@shared/api/api';
import { IExternalTask, taskAPI } from '@shared/api/task';
import { BaitsIcon, CoinIcon, TasksPageIcon, TelegramIcon, XIcon } from '@shared/assets';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { setRewardsModal } from '@shared/store/slice/TaskSlice';
import { useAppDispatch } from '@shared/store/store';
import { EGa4Category, EGa4Event } from '@shared/types/ga4.types';
import { Button, Wrapper } from '@shared/ui';
import { openLink, openTelegramLink } from '@shared/utils/helpers.utils';
import { Modal } from '@shared/widgets/Modal/Modal';

interface Props {
  task: IExternalTask;
  setAvailableTaskCount: Dispatch<SetStateAction<number>>;
}

interface TaskTranslation {
  languageCode: string;
  title: string;
  description: string | null;
  instructions: string[];
}

const getTaskIcon = (link: string | null) => {
  if (!link) return <TasksPageIcon className="w-5 h-5" />;

  if (link.includes('t.me') || link.includes('telegram')) {
    return <TelegramIcon className="w-5 h-5" />;
  }

  if (link.includes('x.com')) {
    return <XIcon className="w-5 h-5" />;
  }

  return <TasksPageIcon className="w-5 h-5" />;
};

export const TaskCard = ({ task, setAvailableTaskCount }: Props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { ga4Event } = useGa4();
  const { updateProfileCoins, updateFishingBaits } = useUtils();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [taskStatus, setTaskStatus] = useState<TaskExtUserStatus | null>(task.userStatus ?? null);

  const [externalTaskComplete] = taskAPI.useExternalTaskCompleteMutation();
  const [externalTaskClaim] = taskAPI.useExternalTaskClaimMutation();

  const enTranslation = task.translations.find((t: TaskTranslation) => t.languageCode === 'en');
  const translation = task.translations.find((t: TaskTranslation) => t.languageCode === i18n.language) || enTranslation;

  const handleStartModal = useCallback(async () => {
    if (!task.isActive) return;

    setIsStarting(true);
    try {
      amplitude.track(AmplitudeEventClient.TaskStarted, { task_type: enTranslation?.title });
      ga4Event(EGa4Event.TaskStarted, EGa4Category.Earn, '', { task_type: enTranslation?.title });

      // Open modal with instructions
      setIsModalOpen(true);
    } catch (error) {
      console.error('Failed to start task:', error);
    } finally {
      setIsStarting(false);
    }
  }, [task, translation]);

  const handleClaim = useCallback(async () => {
    if (!task.isActive) return;

    setIsClaiming(true);
    try {
      const result = await externalTaskClaim({ taskId: task.id }).unwrap();
      dispatch(
        setRewardsModal({
          isOpen: true,
          title: translation?.title,
          btnText: t('global:close'),
          coins: result.rewardCoins,
          baits: result.rewardBaits,
          taskType: task.id.toString(),
        }),
      );

      updateProfileCoins(result.rewardCoins);
      if (result.rewardBaits) {
        updateFishingBaits(null, result.rewardBaits);
      }

      amplitude.track(AmplitudeEventClient.UserRewarded, {
        task_type: translation?.title,
        added_tries: result.rewardBaits,
        reward_size: result.rewardCoins,
      });

      setTaskStatus(TaskExtUserStatus.CLAIMED);
      setAvailableTaskCount((prev) => prev - 1);
    } catch (error) {
      console.error('Failed to claim task:', error);
    } finally {
      setIsClaiming(false);
    }
  }, [task, translation]);

  const handleStartClick = useCallback(() => {
    setIsModalOpen(false);
    setIsInProgress(true);

    externalTaskComplete({ taskId: task.id })
      .unwrap()
      .then(() => {
        amplitude.track(AmplitudeEventClient.TaskStarted, { task_type: enTranslation?.title });
        if (task.link) {
          task.link.includes('t.me') || task.linkType === TaskExtLinkType.TELEGRAM
            ? openTelegramLink(task.link)
            : openLink(task.link);
        }

        setTimeout(() => {
          setIsInProgress(false);
          setTaskStatus(TaskExtUserStatus.COMPLETED);
        }, 5000);
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error completing task: ${data?.message}`);
        if (data) toast.error(data.message);
      })
      .finally(() => ga4Event(EGa4Event.TaskStarted, EGa4Category.Earn, '', { task_type: translation?.title }));
  }, [task.link]);

  const getButtonProps = () => {
    if (!task.isActive) {
      return {
        disabled: true,
        className: 'w-24 disabled:bg-sand-light/50 hover:bg-sand-dark',
        children: t('global:notActive'),
      };
    }

    if (isInProgress) {
      return {
        disabled: true,
        className: 'w-24',
        children: t('global:progress'),
      };
    }

    if (taskStatus === TaskExtUserStatus.CLAIMED) {
      return {
        disabled: true,
        className: 'w-24 disabled:bg-sand-light/50 hover:bg-sand-dark',
        children: t('global:completed'),
      };
    }

    if (taskStatus === TaskExtUserStatus.COMPLETED) {
      return {
        onClick: handleClaim,
        loading: isClaiming,
        disabled: isClaiming,
        className: 'w-24',
        children: t('global:claim'),
      };
    }

    return {
      onClick: translation?.description || translation?.instructions?.length ? handleStartModal : handleStartClick,
      loading: isStarting,
      className: 'w-24',
      children: t('global:start'),
    };
  };

  return (
    <>
      <Wrapper paddingSize="sm">
        <li className="w-full flex items-center justify-center px-[5px] py-[3px] gap-[10px] bg-blue-dark rounded-[10px]">
          <div className="flex-1 flex gap-[6px]">
            <div className="flex items-center text-blue-light w-[1.25em] h-auto justify-center">
              {getTaskIcon(task.link)}
            </div>
            <div className="flex-1 flex flex-col">
              <p className="font-bold text-[13px]">{translation?.title}</p>
              <p className="text-[13px] flex items-center gap-x-1">
                {task.rewardCoins > 0 && (
                  <>
                    <span>+ {task.rewardCoins}</span> <CoinIcon className="w-[14px] h-[14px]" />
                  </>
                )}
                {task.rewardBaits > 0 && (
                  <>
                    <span>+ {task.rewardBaits}</span> <BaitsIcon className="w-[14px] h-[14px]" />
                  </>
                )}
              </p>
            </div>
          </div>
          <Button size="sm" {...getButtonProps()} />
        </li>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={translation?.title}>
          <div className="w-full flex flex-col gap-[10px]">
            <Wrapper>
              <div className="flex flex-col pt-2 pb-2 gap-[10px]">
                {translation?.description && <p>{translation.description}</p>}
                {translation?.instructions?.map((step, index) => (
                  <div key={index} className="flex gap-[10px] items-start">
                    <div className="font-bold text-blue-light">{index + 1}.</div>
                    <div className="text-blue-light">{step}</div>
                  </div>
                ))}
              </div>
            </Wrapper>
            <Button size="lg" onClick={handleStartClick}>
              {t('global:start')}
            </Button>
          </div>
        </Modal>
      </Wrapper>
    </>
  );
};
