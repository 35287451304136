import { createContext, useContext, useState } from 'react';

interface UserInteractionContextType {
  hasInteracted: boolean;
  setHasInteracted: (value: boolean) => void;
}

const UserInteractionContext = createContext<UserInteractionContextType | undefined>(undefined);

export const UserInteractionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasInteracted, setHasInteracted] = useState(false);

  return (
    <UserInteractionContext.Provider value={{ hasInteracted, setHasInteracted }}>
      {children}
    </UserInteractionContext.Provider>
  );
};

export const useUserInteraction = (): UserInteractionContextType => {
  const context = useContext(UserInteractionContext);
  if (!context) {
    throw new Error('useUserInteraction must be used within a UserInteractionProvider');
  }
  return context;
};
