import { useEffect } from 'react';
import { animated } from 'react-spring';

import { AmplitudeEventClient } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { FishingModals, ResultModals, TriesSummary } from '@pages/fishing/widgets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { EStageGame, useCoreAnimation } from '@shared/hooks/useCoreAnimation';
import { ECommonModals, setShowGameHeader, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import {
  EFishingResultModals,
  selectFishingWin,
  selectIsShowTriesSummary,
  selectViewFishingResultModal,
  setIsShowTriesSummary,
} from '@shared/store/slice/FishingSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event, EGa4EventTiming } from '@shared/types/ga4.types';

import { useStorage } from '../../shared/hooks/integration/useStorage';
import { EKeyStorage } from '../../shared/types/storage.types';

export const FishingPage = () => {
  const dispatch = useAppDispatch();
  const { getItem } = useStorage();

  const { ga4StartTiming, ga4EndTiming, ga4Event } = useGa4();
  const { destroyScene, initGame, setStatusGame } = useCoreAnimation();
  const { useAnimTranslateY } = useAnimateSpring();
  const viewFishGone = useAppSelector((state) => selectViewFishingResultModal(state, EFishingResultModals.fisGone));
  const viewYourCatch = useAppSelector((state) => selectViewFishingResultModal(state, EFishingResultModals.yourCatch));
  const fishingWin = useAppSelector(selectFishingWin);
  const isShowTriesSummary = useAppSelector(selectIsShowTriesSummary);

  useEffect(() => {
    ga4StartTiming(EGa4Event.FarmingOpened);
    amplitude.track(AmplitudeEventClient.FarmingOpened);

    initGame();
    setStatusGame(EStageGame.ready);
    getItem(EKeyStorage.viewModalOnboardingTap, true).then(async (res: any) => {
      if (res === null) {
        dispatch(setViewCommonModals({ modal: ECommonModals.onboardingTap, value: true }));
      }
    });

    return () => {
      const time = ga4EndTiming(EGa4Event.FarmingOpened);
      time && ga4Event(EGa4EventTiming.ViewFishing, EGa4Category.Fishing, '', { view_time: time });
      dispatch(setShowGameHeader(false));
      dispatch(setIsShowTriesSummary(false));
      destroyScene();
    };
  }, []);

  return (
    <>
      <div className="w-full flex flex-col gap-y-4">
        <animated.div style={useAnimTranslateY(isShowTriesSummary)}>
          <TriesSummary isStop={!isShowTriesSummary} />
        </animated.div>
        <FishingModals />
        <ResultModals
          viewFishGone={viewFishGone}
          viewYourCatch={viewYourCatch}
          rewardAmount={fishingWin?.rewardAmount || 0}
        />
      </div>
    </>
  );
};
