import gsap from 'gsap';
import { AnimatedSprite, Assets, Container } from 'pixi.js';

import { gameScreenAssets } from '@shared/configs/assets.config';

export enum EPersonages {
  pepe = 'pepe',
}
export enum EBoats {
  default = 'default',
}
const fisherManSpeed: { [key: number]: number } = {
  10: 0.092,
  20: 0.1,
  30: 0.108,
  40: 0.116,
  50: 0.124,
  60: 0.132,
  70: 0.14,
  80: 0.148,
  90: 0.156,
  100: 0.164,
};
enum EBoatAnimations {
  test = 'floatIs-twitching/default/boat-floatIsTwitching-default',
  default = 'pulls-the-rod/default/boat-pullsTheRod-default',
}

enum EPersonageAnimations {
  idle = 'idle/pepe/chibik-idle',
  cast = 'cast/pepe/chibik-cast',
  waiting = 'waiting/pepe/chibik-waiting',
  fishing = 'fishing/pepe/chibik-fishing',
  hooking = 'hooking/pepe/chibik-hooking',
  win = 'win/pepe/chibik-win',
  fishGone = 'fishGone/pepe/chibik-fish-gone',
}

export enum EFishermanActions {
  idle = 'idle', // Ожидание
  cast = 'cast', // Бросок удочки
  waiting = 'waiting', // Ожидание поклевки
  fishing = 'fishing', // Ловля
  hooking = 'hooking', // Подсекание
  win = 'win', // Поймана рыба
  fishGone = 'fishGone', // Рыба ушла
}

interface AnimationParams {
  loop: boolean;
  nextActions?: EFishermanActions;
  onCallBack: boolean;
  percent: number;
}

export default class UiFisherman {
  private _view: Container | null = null;
  private _isDestroyed: boolean = false;

  private readonly personage: EPersonages = EPersonages.pepe;
  private readonly boat: EBoats = EBoats.default;
  private action: EFishermanActions = EFishermanActions.idle;

  private readonly animationSpeeds = {
    boat: 0.15,
    personage: 0.092,
    personagePercent: 10,
  };

  private readonly boatAnimations: Map<EBoatAnimations, AnimatedSprite> = new Map();
  private readonly personageAnimations: Map<EPersonageAnimations, AnimatedSprite> = new Map();

  private currentBoat: AnimatedSprite | null = null;
  private currentPersonage: AnimatedSprite | null = null;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'Fisherman';
      this._view.alpha = 0;
    }
    return this._view;
  }

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    try {
      this.createBoat();
      this.createPersonage();

      const boat = this.boatAnimations.get(EBoatAnimations.test);
      if (boat) {
        boat.visible = true;
        this.view.addChild(boat);
        this.currentBoat = boat;
      }

      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize fisherman:', error);
    }
  }

  show(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      this.switchAnimation(EFishermanActions.idle);
      if (this.currentBoat) {
        this.currentBoat.play();
      }

      this.view.x = -200;
      tl.to(this.view, { alpha: 1 }, 0).to(this.view, { x: 0, duration: 0.5, ease: 'power2.inOut' }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to show fisherman:', error);
      return gsap.timeline();
    }
  }

  hide(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();
      tl.to(this.view, { x: -200, duration: 0.5, ease: 'power2.inOut' }).to(this.view, { alpha: 0 });

      return tl;
    } catch (error) {
      console.error('Failed to hide fisherman:', error);
      return gsap.timeline();
    }
  }

  nextSpeed(clear: boolean = false): void {
    if (this._isDestroyed || !this.currentPersonage) return;

    try {
      if (clear) {
        this.animationSpeeds.personagePercent = 10;
        return;
      }

      const currentPercent = this.animationSpeeds.personagePercent;
      const percent = currentPercent === 100 ? 10 : currentPercent + 10;

      this.animationSpeeds.personagePercent = percent;
      this.currentPersonage.animationSpeed = fisherManSpeed[percent];
    } catch (error) {
      console.error('Failed to update animation speed:', error);
    }
  }

  switchAnimation(action: EFishermanActions, callback?: () => void): void {
    if (this._isDestroyed) return;

    try {
      let personage: AnimatedSprite | undefined;
      const params: AnimationParams = {
        loop: true,
        nextActions: undefined,
        onCallBack: false,
        percent: 0,
      };

      switch (action) {
        case EFishermanActions.idle:
          personage = this.personageAnimations.get(EPersonageAnimations.idle);
          break;
        case EFishermanActions.cast:
          personage = this.personageAnimations.get(EPersonageAnimations.cast);
          params.loop = false;
          params.nextActions = EFishermanActions.waiting;
          params.onCallBack = true;
          params.percent = 56;
          break;
        case EFishermanActions.waiting:
          personage = this.personageAnimations.get(EPersonageAnimations.waiting);
          break;
        case EFishermanActions.fishing:
          personage = this.personageAnimations.get(EPersonageAnimations.fishing);
          break;
        case EFishermanActions.hooking:
          personage = this.personageAnimations.get(EPersonageAnimations.hooking);
          params.loop = false;
          params.nextActions = EFishermanActions.fishing;
          break;
        case EFishermanActions.win:
          personage = this.personageAnimations.get(EPersonageAnimations.win);
          params.loop = false;
          params.onCallBack = true;
          params.percent = 50;
          break;
        case EFishermanActions.fishGone:
          personage = this.personageAnimations.get(EPersonageAnimations.fishGone);
          params.loop = false;
          params.onCallBack = true;
          params.percent = 25;
          break;
      }

      if (!personage) return;

      personage.currentFrame = 0;
      personage.animationSpeed = this.animationSpeeds.personage;
      personage.visible = true;
      personage.loop = params.loop;

      personage.onComplete = () => {
        if (this._isDestroyed) return;
        const { nextActions } = params;
        if (nextActions) {
          this.switchAnimation(nextActions);
        }
      };

      personage.onFrameChange = (frame: number) => {
        if (this._isDestroyed) return;
        const { onCallBack, percent } = params;
        const progress = Math.round((frame / personage.totalFrames) * 100);
        if (callback && onCallBack && progress === percent) {
          callback();
        }
      };

      if (this.currentPersonage) {
        this.currentPersonage.onComplete = undefined;
        this.currentPersonage.onFrameChange = undefined;
        this.view.removeChild(this.currentPersonage);
      }

      this.currentPersonage = personage;
      this.view.addChild(personage);
      this.adaptive();
      this.currentPersonage.play();
    } catch (error) {
      console.error('Failed to switch animation:', error);
    }
  }

  private createBoat(): void {
    if (this._isDestroyed) return;

    try {
      const boatAssets = Assets.cache.get(gameScreenAssets.urls.boat);
      if (!boatAssets?.data?.animations) {
        console.error('Boat assets not found in cache');
        return;
      }

      const animations = boatAssets.data.animations;
      Object.values(EBoatAnimations).forEach((animationKey: EBoatAnimations) => {
        if (animationKey in animations) {
          const animate = AnimatedSprite.fromFrames(animations[animationKey]);
          animate.scale.set(0.3);
          animate.visible = false;
          animate.animationSpeed = this.animationSpeeds.boat;
          animate.loop = true;
          animate.name = 'Boat';

          this.boatAnimations.set(animationKey, animate);
        }
      });
    } catch (error) {
      console.error('Failed to create boat animations:', error);
    }
  }

  private createPersonage(): void {
    if (this._isDestroyed) return;

    try {
      const personageAssets = Assets.cache.get(gameScreenAssets.urls.personage);
      if (!personageAssets?.data?.animations) {
        console.error('Personage assets not found in cache');
        return;
      }

      const animations = personageAssets.data.animations;
      Object.values(EPersonageAnimations).forEach((animationKey: EPersonageAnimations) => {
        if (animationKey in animations) {
          const animate = AnimatedSprite.fromFrames(animations[animationKey]);
          animate.scale.set(0.3);
          animate.visible = false;
          animate.animationSpeed = this.animationSpeeds.personage;
          animate.loop = true;
          animate.name = 'Personage';

          this.personageAnimations.set(animationKey, animate);
        }
      });
    } catch (error) {
      console.error('Failed to create personage animations:', error);
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this.currentPersonage) return;

    try {
      this.currentPersonage.position.set(10, -50);
    } catch (error) {
      console.error('Failed to adapt fisherman:', error);
    }
  }

  destroy(): void {
    if (this._isDestroyed) return;

    try {
      if (this.currentPersonage) {
        this.currentPersonage.onComplete = undefined;
        this.currentPersonage.onFrameChange = undefined;
        this.currentPersonage.stop();
        this.currentPersonage.destroy();
        this.currentPersonage = null;
      }

      if (this.currentBoat) {
        this.currentBoat.stop();
        this.currentBoat.destroy();
        this.currentBoat = null;
      }

      // this.personageAnimations.forEach((animation) => {
      //   if (animation) animation.destroy();
      // });
      this.personageAnimations.clear();

      // this.boatAnimations.forEach((animation) => {
      //   if (animation) animation.destroy();
      // });
      this.boatAnimations.clear();

      // Clean up container
      if (this._view) {
        gsap.killTweensOf(this._view);
        this._view.destroy({ children: true });
        this._view = null;
      }

      this._isDestroyed = true;
    } catch (error) {
      console.error('Failed to destroy fisherman:', error);
    }
  }
}
