import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EAppRouterUrl } from '@app/AppRouter';
import { VIDEO } from '@shared/assets';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { ECommonModals, selectViewCommonModal, setViewCommonModals } from '@shared/store/slice/CommonSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { EKeyStorage } from '@shared/types/storage.types';
import { Button, Video, Wrapper } from '@shared/ui';
import { Modal } from '@shared/widgets';

export const GreatStartModal = () => {
  const { t } = useTranslation();
  const { setItem } = useStorage();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isOpen = useAppSelector((state) => selectViewCommonModal(state, ECommonModals.greatStart));

  const closeModal = () => {
    dispatch(setViewCommonModals({ modal: ECommonModals.greatStart, value: false }));
    setItem(EKeyStorage.viewModalGreatStart, 'true', true);
    navigate(EAppRouterUrl.fishing);
  };
  return (
    <Modal
      isOpen={isOpen}
      isButtonClose={false}
      title={t('common_modals:greatStart.title')}
      classNameOverlay="bg-primary-darkBlue"
      className="z-30"
    >
      <div className="w-full h-full flex flex-col justify-center items-center gap-y-[10px]">
        <Wrapper className="flex flex-col items-center gap-y-[10px]">
          <Video className="rounded-10 max-w-[50%]" src={VIDEO.COMMON_MODALS.GREAT_START} />
          <p className="text-center ">{t('common_modals:greatStart.description')}</p>
        </Wrapper>

        <Button size="lg" onClick={closeModal} shine={true}>
          {t('global:letsGo')}
        </Button>
      </div>
    </Modal>
  );
};
