const ua = window.navigator.userAgent;

export function getUserAgent(): string {
  return ua;
}
/**
 * val typeof array
 */
export function isArray<T = any>(val: any): val is T[] {
  return Object.prototype.toString.call(val) === '[object Array]';
}

/**
 * val typeof object
 */
export function isObject(val: any) {
  return Object.prototype.toString.call(val) === '[object Object]';
}

/**
 * val typeof boolean
 */
export function isBoolean(val: any) {
  return Object.prototype.toString.call(val) === '[object Boolean]';
}

/**
 * val is defined string
 */
export function isString(val: any) {
  return Object.prototype.toString.call(val) === '[object String]';
}

/**
 * Проверяет на число
 * @param {any} value
 * @returns
 */
export function isNumber(value: any): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function isMobile(): boolean {
  return !!(
    ua.match(/Android/i) ||
    ua.match(/webOS/i) ||
    ua.match(/iPhone/i) ||
    ua.match(/iPad/i) ||
    ua.match(/iPod/i) ||
    ua.match(/BlackBerry/i) ||
    ua.match(/Windows Phone/i)
  );
}

export function isDesktop(): boolean {
  return !isMobile();
}

export function isIEMobile(): boolean {
  return ua.indexOf('IEMobile') >= 0;
}

export function isTablet(): boolean {
  return !!ua.match(/iPad/i) && !isIEMobile();
}

export function isCloudStorageTgSupported(): boolean {
  return !!window.Telegram?.WebApp?.CloudStorage;
}
