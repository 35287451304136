import { useTranslation } from 'react-i18next';

import { Stepper, Wrapper } from '@shared/ui';

export const Guide = () => {
  const { t } = useTranslation();
  const clauses: { title: string; description: string }[] = [
    {
      title: t('friends:guide.clauses.1.title'),
      description: t('friends:guide.clauses.1.description'),
    },
    {
      title: t('friends:guide.clauses.2.title'),
      description: t('friends:guide.clauses.2.description'),
    },
    {
      title: t('friends:guide.clauses.3.title'),
      description: t('friends:guide.clauses.3.description'),
    },
  ];

  return (
    <Wrapper className=" text-base p-[8px] text-center">
      <div className="p-[8px]">
        <h3 className="text-blue-light font-bold  text-left">{t('friends:guide.works')}</h3>
      </div>
      <div className="flex items-center">
        <Stepper numberOfSteps={3} currentStep={2} />
        <ul className="flex flex-col gap-[8px]">
          {clauses.map(({ title, description }) => (
            <li className="flex items-start" key={`clause_${title.toLowerCase()}`}>
              <span className="text-left outline-[15px]">
                <p className="font-bold text-[14px]">{title}</p>
                <p className="font-normal text-[12px]">{description}</p>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
};
