import { IClaimLoseBonusFishing, IFishingInfo, IStartFishing, IWinFishing } from '@hf/shared-common';

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

export enum EFishingModals {
  comeTomorrow = 'comeTomorrow',
  catchToDay = 'catchToDay',
}
export enum EFishingResultModals {
  fisGone = 'fisGone',
  yourCatch = 'yourCatch',
}
export enum EAnimateTriesSummary {
  baits = 'baits',
  bonus = 'bonus',
}

export interface FishingState {
  modals: Record<EFishingModals, boolean>;
  resultModals: Record<EFishingResultModals, boolean>;
  isCloseResultModals: boolean;
  fishingInfo: IFishingInfo | undefined;
  currentGame: IStartFishing | undefined;
  win: IWinFishing | IClaimLoseBonusFishing | undefined;
  isShowTriesSummary: boolean;
}

const initState: FishingState = {
  modals: {
    [EFishingModals.comeTomorrow]: false,
    [EFishingModals.catchToDay]: false,
  },
  resultModals: {
    [EFishingResultModals.fisGone]: false,
    [EFishingResultModals.yourCatch]: false,
  },
  isCloseResultModals: false,
  fishingInfo: undefined,
  currentGame: undefined,
  win: undefined,
  isShowTriesSummary: false,
};

export const fishingSlice = createSlice({
  name: 'fishingSlice',
  initialState: initState,
  selectors: {
    selectViewFishingModal: createSelector(
      (state: FishingState) => state.modals,
      (_: FishingState, modal: EFishingModals) => modal,
      (modals, modal) => modals[modal],
    ),
    selectViewFishingResultModal: createSelector(
      (state: FishingState) => state.resultModals,
      (_: FishingState, modal: EFishingResultModals) => modal,
      (resultModals, modal) => resultModals[modal],
    ),
    selectFishingInfo: (state: FishingState) => state.fishingInfo,
    selectCurrentGame: (state: FishingState) => state.currentGame,
    selectFishingWin: (state: FishingState) => state.win,
    selectIsCloseResultModals: (state: FishingState) => state.isCloseResultModals,
    selectIsShowTriesSummary: (state: FishingState) => state.isShowTriesSummary,
  },
  reducers: {
    setViewFishingModals(state, action: PayloadAction<{ modal: EFishingModals; value: boolean }>) {
      const { modal, value } = action.payload;
      state.modals[modal] = value;
    },
    setViewFishingResultModals(state, action: PayloadAction<{ modal: EFishingResultModals; value: boolean }>) {
      const { modal, value } = action.payload;
      state.resultModals[modal] = value;
    },
    setFishingInfo(state, action: PayloadAction<IFishingInfo>) {
      state.fishingInfo = action.payload;
    },
    setCurrentGame(state, action: PayloadAction<IStartFishing>) {
      state.currentGame = action.payload;
    },
    setFishingWin(state, action: PayloadAction<IWinFishing | IClaimLoseBonusFishing>) {
      state.win = action.payload;
    },
    setIsCloseResultModals(state, action: PayloadAction<boolean>) {
      state.isCloseResultModals = action.payload;
    },
    setIsShowTriesSummary(state, action: PayloadAction<boolean>) {
      state.isShowTriesSummary = action.payload;
    },
  },
});

export const {
  setViewFishingModals,
  setViewFishingResultModals,
  setFishingInfo,
  setCurrentGame,
  setFishingWin,
  setIsCloseResultModals,
  setIsShowTriesSummary,
} = fishingSlice.actions;
export const {
  selectViewFishingModal,
  selectViewFishingResultModal,
  selectCurrentGame,
  selectFishingInfo,
  selectFishingWin,
  selectIsCloseResultModals,
  selectIsShowTriesSummary,
} = fishingSlice.selectors;
